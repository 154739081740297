import { Box } from "@material-ui/core";
import { ICreateMatchFormState } from "./form-state.interface";
import {
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
  WidthWrapper,
} from "../shared";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router";
import React, { Fragment } from "react";

export const Summary: React.FC = () => {
  const { t } = useTranslation(["matchAdmin", "common"]);
  const { values } = useFormikContext<ICreateMatchFormState>();
  const navigate = useNavigate();

  const handleBack = () =>
    navigate(
      t(
        "matchAdmin:createMatch.playerDistribution.step.path",
        "/vorbereitung/spiel-erstellen/rollenverteilung"
      )
    );

  return (
    <Fragment>
      <PagePadding>
        <WidthWrapper>
          <PageTitle>{t("createMatch.summary.title", "Fast fertig")}</PageTitle>

          <Paragraph>
            <Trans ns="matchAdmin" i18nKey="createMatch.summary.copy">
              Du spielst mit {{ numberOfPlayrs: values.numberOfPlayers }} Personen (dich mit
              eingeschlossen). Wir erstellen für alle Spiele jeweils geheime Zugangslinks, die du
              anschließend verteilen kannst.
            </Trans>
          </Paragraph>

          <Paragraph>
            <Trans ns="matchAdmin" i18nKey="createMatch.summary.copy2">
              Diese Links sind nur für die Augen des jeweiligen Spielers bestimmt. Jeder Spieler sollte
              deshalb nur einen einzigen Link erhalten und du solltest dir die Spielmaterialien deiner
              Mitspieler nicht ansehen.
            </Trans>
          </Paragraph>

          <Box sx={{ mt: 5 }}>
            <PrimaryButton type="submit">
              {t("createMatch.summary.submitButton.label", "Spiel erstellen")}
            </PrimaryButton>
          </Box>

          <Box sx={{ mt: 1 }}>
            <SecondaryButton onClick={handleBack}>{t("previousButton.label", "Zurück")}</SecondaryButton>
          </Box>
        </WidthWrapper>
      </PagePadding>
    </Fragment>
  );
};
