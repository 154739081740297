import { Box, useTheme } from "@material-ui/core";
import {
  CharacterCard,
  PagePadding,
  PageTitle,
  Title,
  useHasRenderedClass,
  UnstyledList,
  WidthWrapper,
} from "../shared";
import { ECharacterGender, suspectsSelector, victimSelector } from "../game-data";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React from "react";

export const CircleOfSuspects: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["yourRole", "common"]);
  const theme = useTheme();
  const suspects = useRecoilValue(suspectsSelector);
  const victim = useRecoilValue(victimSelector);

  return (
    <PagePadding>
      <PageTitle>{t("common:circleOfSuspects", "Die Verdächtigen")}</PageTitle>

      <WidthWrapper>
        <UnstyledList>
          {suspects.map((c, ix) => (
            <CharacterCard character={c} key={`char-${ix}`} noPlayer={!c.playerName} />
          ))}
        </UnstyledList>

        {victim && (
          <Box sx={{ mt: 7 }}>
            <Title style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
              {t("circleOfSuspects.victim", "Das Opfer")}
            </Title>

            <UnstyledList>
              <CharacterCard
                character={{
                  age: victim?.age ?? 0,
                  description: victim?.description ?? "",
                  gender: victim?.gender ?? ECharacterGender.FEMALE,
                  name: victim?.name ?? "",
                }}
                noPlayer
                isVictim
              />
            </UnstyledList>
          </Box>
        )}
      </WidthWrapper>
    </PagePadding>
  );
};
