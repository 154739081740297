import { canUseAnimation } from "../theme";
import { Theme, useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const StepperContainer = styled.div<{ theme: Theme }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${(props) => `${props.theme.spacing(2)} ${props.theme.spacing(2)} 0`};
`;

const IndicatorContainer = styled.div`
  display: inline-flex;
`;

type IndicatorProps = { isActive?: boolean; theme: Theme };

const Indicator = styled.div<IndicatorProps>`
  background-color: ${(props) =>
    props.isActive ? props.theme.palette.text.primary : props.theme.palette.grey[300]};
  border-radius: 50%;
  height: ${(props) => props.theme.spacing(0.7)};
  margin: 0 4px;
  transition-delay: ${() => (canUseAnimation ? "0.5s" : "0s")};
  transition: ${(props) => props.theme.transitions.create("background-color", { duration: "shortest" })};
  width: ${(props) => props.theme.spacing(0.7)};
`;

type Props = { activeStep: number; steps: number };

export const StepIndicator: React.FC<Props> = ({ activeStep = 0, steps = 0 }) => {
  const theme = useTheme();

  const getIndicators = () => {
    const indicators = [];

    for (let index = 1; index <= steps; index++) {
      const isActive = index === activeStep;
      indicators.push(<Indicator key={`wizard-indicator-${index}`} isActive={isActive} theme={theme} />);
    }

    return indicators;
  };

  return (
    <StepperContainer theme={theme}>
      <IndicatorContainer>{getIndicators()}</IndicatorContainer>
    </StepperContainer>
  );
};
