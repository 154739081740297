import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

export const LoadingSpinner: React.FC = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
