import { Box } from "@material-ui/core";
import { EMatchStatus, matchData } from "../game-data";
import {
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { WS } from "../websocket";
import React from "react";

type Props = {};

export const UnlockAccusation: React.FC<Props> = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["rounds"]);
  const setMatchData = useSetRecoilState(matchData);

  const unlock = () => {
    setMatchData((currVal) => ({ ...currVal, status: EMatchStatus.ACCUSATION_OPENED }));
    WS.send(JSON.stringify({ action: "startAccusation" }));
  };

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle>{t("openAccusation.title", "Verdacht und Verteidigung öffnen")}</PageTitle>

        <Paragraph>
          {t(
            "openAccusation.copy",
            "Du kannst für alle Spieler die Verdachtsrunde öffnen. Bitte stelle zuvor sicher, dass ihr den Tatabend ausreichend diskutiert habt."
          )}
        </Paragraph>

        <Box sx={{ mt: 2 }}>
          <PrimaryButton data-test-id="host-unlock-accusation-btn" onClick={unlock}>
            {t("openAccusation.button.label", "Verdachtsrunde starten")}
          </PrimaryButton>
        </Box>
      </WidthWrapper>
    </PagePadding>
  );
};
