import {
  accusationRoute,
  firstRoundRoute,
  secondRoundRoute,
  solutionRoute,
  thirdRoundRoute,
} from "../rounds";
import { authStatusState, EAuthStatus } from "../authentication";
import { EMatchResponseTopic, EMatchStatus, ERound } from "./game-data.interface";
import { gameBookletData, gameData, isHostSelector, matchData, matchStatusSelector } from "./state";
import { getRoundNumber, LoadingSpinner, useConfirm } from "../shared";
import { MatchEndedNotice } from "./match-ended-notice";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { WS } from "../websocket";
import React, { Fragment, useEffect } from "react";

export const GameDataWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const authStatus = useRecoilValue(authStatusState);
  const isHost = useRecoilValue(isHostSelector);
  const matchStatus = useRecoilValue(matchStatusSelector);
  const setGameBookletData = useSetRecoilState(gameBookletData);
  const [game, setGameData] = useRecoilState(gameData);
  const setMatchData = useSetRecoilState(matchData);

  useEffect(() => {
    const onMessage = async (msg: any) => {
      let parsed: { id?: string; topic: EMatchResponseTopic; data: any };

      try {
        parsed = JSON.parse(msg.data);
      } catch (ex) {
        return;
      }

      if (parsed.hasOwnProperty("id") && parsed.id) {
        WS.send(JSON.stringify({ action: "ackMessage", data: { id: parsed.id } }));
      }

      if (parsed.topic === EMatchResponseTopic.GAME_DATA) {
        setGameData(parsed.data);
      }

      if (parsed.topic === EMatchResponseTopic.MATCH_DATA) {
        setMatchData(parsed.data);
      }

      if (parsed.topic === EMatchResponseTopic.GAME_BOOKLET_DATA) {
        setGameBookletData(parsed.data);
      }

      if (parsed.topic === EMatchResponseTopic.ROUND_OPENED) {
        WS.send(JSON.stringify({ action: "getPlayerState" }));

        if (!isHost) {
          const round = parsed.data.round;
          const roundNumber = getRoundNumber(round);

          let routePath: string = "";

          // eslint-disable-next-line eqeqeq
          if (round == ERound.FIRST) {
            routePath = firstRoundRoute(t).fullPath;
          }

          // eslint-disable-next-line eqeqeq
          if (round == ERound.SECOND) {
            routePath = secondRoundRoute(t).fullPath;
          }

          // eslint-disable-next-line eqeqeq
          if (round == ERound.THIRD) {
            routePath = thirdRoundRoute(t).fullPath;
          }

          const confirmed = await confirm({
            confirmationText: t("common:openButton.label", "Öffnen"),
            cancellationText: t("common:closeButton.label", "Schließen"),
            title: t("common:round", "Runde") + " " + roundNumber,
            description: t(
              "rounds:openAlert.copy",
              "Der Gastgeber hat Runde {{roundNumber}} freigegeben.",
              { roundNumber: roundNumber }
            ),
          });

          if (confirmed && routePath) {
            navigate(routePath);
          }
        }
      }

      if (parsed.topic === EMatchResponseTopic.ACCUSATION_OPENED) {
        WS.send(JSON.stringify({ action: "getPlayerState" }));

        if (!isHost) {
          const confirmed = await confirm({
            confirmationText: t("common:openButton.label", "Öffnen"),
            cancellationText: t("common:closeButton.label", "Schließen"),
            title: t("common:accusationAndDefence", "Verdacht & Verteidigung"),
            description: t(
              "rounds:accusationAlert.copy",
              "Der Gastgeber hat die Verdachtsrunde gestartet."
            ),
          });

          if (confirmed) {
            navigate(accusationRoute(t).fullPath);
          }
        }
      }

      if (parsed.topic === EMatchResponseTopic.SOLUTION_SHARED) {
        WS.send(JSON.stringify({ action: "getPlayerState" }));

        if (!isHost) {
          const confirmed = await confirm({
            confirmationText: t("common:openButton.label", "Öffnen"),
            cancellationText: t("common:closeButton.label", "Schließen"),
            title: t("common:solution", "Aufklärung"),
            description: t(
              "rounds:solutionSharedAlert.copy",
              "Der Gastgeber hat die Aufklärung zum Nachlesen geteilt."
            ),
          });

          if (confirmed) {
            navigate(solutionRoute(t).fullPath);
          }
        }
      }
    };

    const onOpen = () => {
      WS.send(JSON.stringify({ action: "getPlayerState" }));
      WS.send(JSON.stringify({ action: "getMessages" }));
    };

    onOpen();

    WS.addEventListener("message", onMessage);
    WS.addEventListener("open", onOpen);

    return function cleanUp() {
      WS.removeEventListener("message", onMessage);
      WS.removeEventListener("open", onOpen);
    };
  }, [authStatus, confirm, isHost, navigate, setGameBookletData, setGameData, setMatchData, t]);

  if (authStatus === EAuthStatus.SIGNED_IN && Object.keys(game).length === 0) {
    return <LoadingSpinner />;
  }

  if (matchStatus === EMatchStatus.ENDED) {
    return <MatchEndedNotice />;
  }

  return <Fragment>{children}</Fragment>;
};
