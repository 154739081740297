import React from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";

export const PagePadding: React.FC = ({ children }) => {
  const theme = useTheme();
  const isAtLeastTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const padding = isAtLeastTablet ? { pt: 6, pb: 8 } : { pt: 2, pb: 4, pl: 2, pr: 2 };

  return <Box sx={padding}>{children}</Box>;
};
