import { Tab as MuiTab, Tabs as MuiTabs, Theme } from "@material-ui/core";
import styled from "styled-components";

export const Tabs = styled(MuiTabs)<{ theme: Theme }>`
  && {
    background: white;
    margin-left: auto;
    margin-right: auto;
    min-height: 40px;
    padding-bottom: ${(props) => props.theme.spacing(1)};
    top: 0;
    z-index: 20;

    & .scrollable {
      height: 40px;
    }

    & .MuiTabs-flexContainer  {
      justify-content: flex-start;
      margin-left: auto;
      margin-right: auto;
      max-width: ${(props) => props.theme.breakpoints.values.lg}px;

      ${(props) => props.theme.breakpoints.up("sm")} {
        padding: 0 ${(props) => props.theme.spacing(1)};
      }

      ${(props) => props.theme.breakpoints.up("md")} {
        padding: 0 ${(props) => props.theme.spacing(4)};
      }

      ${(props) => props.theme.breakpoints.up("lg")} {
        justify-content: flex-end;
        padding: 0 ${(props) => props.theme.spacing(1)};
      }
    }

    & .MuiTabs-indicator {
      display: none;
      // background-color: transparent;
      // display: flex;
      // height: 6px;
      // justify-content: center;
      // transition: ${(props) => props.theme.transitions.create("all")} !important;

      // & > div {
      //   background-color: ${(props) => props.theme.palette.primary.main};
      //   border-radius: 50%;
      //   height: 6px;
      //   max-width: 6px;
      //   width: 100%;
      // }
    }
  }
`;

export const Tab = styled(MuiTab).attrs({
  disableRipple: true,
})<{ theme: Theme }>`
  && {
    color: var(--ion-color-step-700);
    font-family: var(--font-sans);
    font-weight: 500;
    letter-spacing: 0.2px;
    min-height: 40px;
    min-width: unset;
    padding: 12px ${(props) => props.theme.spacing(2)} 6px;
    text-transform: none;
    transition: ${(props) => props.theme.transitions.create("color", { duration: "short" })};

    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
    }

    &.Mui-selected {
      color: ${(props) => props.theme.palette.primary.main};
    }

    &.Mui-focusVisible {
      background: ${(props) => props.theme.palette.primary.light};
    }

    &.Mui-fo &.Mui-disabled {
      color: var(--ion-color-step-300) !important;
    }
  }
`;
