import { Box } from "@material-ui/core";
import {
  FormikRadioGroup,
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
  WidthWrapper,
} from "../shared";
import { playerRangeSelector } from "../game-data";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

export const NumberOfPlayers: React.FC = () => {
  const { errors } = useFormikContext();
  const { maxPlayers, minPlayers } = useRecoilValue(playerRangeSelector);
  const { t } = useTranslation(["matchAdmin", "common"]);
  const isInvalid = "numberOfPlayers" in errors;
  const navigate = useNavigate();

  const handleBack = () =>
    navigate(
      t("matchAdmin:createMatch.hostData.step.path", "/vorbereitung/spiel-erstellen/deine-daten")
    );

  const handleNext = () =>
    navigate(t("matchAdmin:createMatch.case.step.path", "/vorbereitung/spiel-erstellen/der-fall"));

  const getOptions = () => {
    const options: any = [];

    for (let index = minPlayers; index <= maxPlayers; index++) {
      options.push({ value: String(index), label: `${index} ${t("common:player", "Spieler")}` });
    }

    return options;
  };

  return (
    <Fragment>
      <PagePadding>
        <WidthWrapper>
          <PageTitle>
            {t("createMatch.playerNumber.title", "Mit wieviel Personen möchtest du spielen?")}
          </PageTitle>

          <Paragraph>
            {t(
              "createMatch.playerNumber.copy",
              "Du musst entscheiden, mit wieviel Personen (dich mit eingeschlossen) du spielen möchtest, ehe du die Rollen an deine Gäste verteilen kannst."
            )}
          </Paragraph>

          <Box sx={{ mt: 3, mb: 3 }}>
            <FormikRadioGroup name="numberOfPlayers" options={getOptions()} />
          </Box>

          <Paragraph>
            {t(
              "createMatch.playerNumber.copy2",
              "Abwesende Figuren kommen nicht als Täter in Betracht. Für sie gilt die Unschuldsvermutung. Deshalb kannst du diese Figuren nicht verteilen."
            )}
          </Paragraph>

          <Box sx={{ mt: 5 }}>
            <PrimaryButton disabled={isInvalid} onClick={handleNext}>
              {t("common:nextButton.label", "Weiter")}
            </PrimaryButton>
          </Box>

          <Box sx={{ mt: 1 }}>
            <SecondaryButton onClick={handleBack}>
              {t("common:previousButton.label", "Zurück")}
            </SecondaryButton>
          </Box>
        </WidthWrapper>
      </PagePadding>
    </Fragment>
  );
};
