import {
  accusationRoute,
  firstRoundRoute,
  prologueRoute,
  secondRoundRoute,
  solutionRoute,
  thirdRoundRoute,
} from "../rounds";
import { backgroundRoute, circleOfSuspectsRoute, yourRoleRoute } from "../your-role";
import {
  Box,
  Drawer,
  List,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListSubheader as MuiListSubheader,
  Theme,
  useTheme,
} from "@material-ui/core";
import { characterNameSelector, isHostSelector, matchData } from "../game-data";
import { createMatchRoute, viewMatchRoute } from "../match-admin";
import { drawerOpen } from "./navigation.state";
import { gameRulesRoute, structureOfTheGameRoute, welcomeRoute } from "../rules";
import { imprintRoute, privacyPolicyRoute, termsAndConditionsRoute } from "../legal";
import { incidentARoute, incidentBRoute } from "../incidents";
import { IRoute } from "./route.interface";
import { ReactComponent as SignOutIcon } from "../assets/icon-sign-out.svg";
import { SvgIcon } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router";
import { useLogout } from "../authentication";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import Logo from "../assets/logo-horizontal-full.svg";
import React, { Fragment } from "react";
import styled from "styled-components";

const ListSubheader = styled(MuiListSubheader).attrs({ component: "div" })`
  font-style: normal;
  background-color: white;
`;

type ListItemProps = { selected?: boolean; theme: Theme };

const ListItem = styled(MuiListItem).attrs({
  button: true as any,
})<ListItemProps>`
  &.Mui-selected {
    background-color: white;
    color: ${(props) => props.theme.palette.primary.main};
  }

  span.MuiTypography-root {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 1rem;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span.MuiTypography-root {
    font-size: 16px;
  }
`;

export const DrawerNav: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation(["common", "routes"]);
  const [isOpen, setIsOpen] = useRecoilState(drawerOpen);
  const characterName = useRecoilValue(characterNameSelector);
  const isHost = useRecoilValue(isHostSelector);
  const logOut = useLogout();
  const match = useRecoilValue(matchData);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClose = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && "key" in event && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setIsOpen(false);
  };

  const handleClick = (event: React.KeyboardEvent | React.MouseEvent, path: string) => {
    navigate(path);
    handleClose(event);
  };

  const legalRoutes = [privacyPolicyRoute(t), termsAndConditionsRoute(t), imprintRoute(t)];
  const incidentRoutes = [incidentARoute(t), incidentBRoute(t)];
  const gameRoutes = [
    welcomeRoute(t),
    gameRulesRoute(t),
    structureOfTheGameRoute(t),
    createMatchRoute(t),
    viewMatchRoute(t),
  ];
  const roleRoutes = [yourRoleRoute(t), circleOfSuspectsRoute(t), backgroundRoute(t)];
  const roundsRoutes = [
    prologueRoute(t),
    firstRoundRoute(t),
    secondRoundRoute(t),
    thirdRoundRoute(t),
    accusationRoute(t),
    solutionRoute(t),
  ];

  const visible = ({ isVisible }: IRoute) =>
    isVisible ? isVisible(isHost, match, characterName) : true;
  const filter = (r: IRoute) => !r.hideInMainNav && visible(r);
  const isDisabled = ({ canAccess }: IRoute) =>
    canAccess ? !canAccess(isHost, match, characterName) : false;

  return (
    <Drawer anchor="left" data-test-id="mobile-menu" open={isOpen} onClose={handleClose}>
      <Box sx={{ pt: 4 }}>
        <div id="cm-menu-logo">
          <div style={{ padding: "0px 24px 0 12px", maxWidth: "280px" }}>
            <img src={Logo} alt="Culinario Mortale® Logo" style={{ width: "100%" }} />
          </div>
        </div>
      </Box>

      <Box sx={{ mt: 4 }}>
        <List subheader={<ListSubheader>{t("menu.categories.game", "Das Spiel")}</ListSubheader>}>
          {gameRoutes.filter(filter).map((route) => (
            <ListItem
              data-test-id={`mobile-menu-${route.id}`}
              disabled={isDisabled(route)}
              key={`menu-drawer-${route.fullPath}`}
              onClick={(event) => handleClick(event, route.fullPath)}
              selected={pathname === route.fullPath}
              theme={theme}
            >
              {route.icon}
              <Box sx={{ ml: 2 }}>
                <ListItemText primary={route.name} />
              </Box>
            </ListItem>
          ))}
        </List>

        <List subheader={<ListSubheader>{t("menu.categories.role", "Über deine Rolle")}</ListSubheader>}>
          {roleRoutes.filter(filter).map((route) => (
            <ListItem
              data-test-id={`mobile-menu-${route.id}`}
              disabled={isDisabled(route)}
              key={`menu-drawer-${route.fullPath}`}
              onClick={(event) => handleClick(event, route.fullPath)}
              selected={pathname === route.fullPath}
              theme={theme}
            >
              {route.icon}
              <Box sx={{ ml: 2 }}>
                <ListItemText primary={route.name} />
              </Box>
            </ListItem>
          ))}
        </List>

        <List subheader={<ListSubheader>{t("menu.categories.rounds", "Hinweisrunden")}</ListSubheader>}>
          {roundsRoutes.filter(filter).map((route) => (
            <ListItem
              data-test-id={`mobile-menu-${route.id}`}
              disabled={isDisabled(route)}
              key={`menu-drawer-${route.fullPath}`}
              onClick={(event) => handleClick(event, route.fullPath)}
              selected={pathname === route.fullPath}
              theme={theme}
            >
              {route.icon}
              <Box sx={{ ml: 2 }}>
                <ListItemText primary={route.name} />
              </Box>
            </ListItem>
          ))}
        </List>

        {isHost && (
          <Fragment>
            <List
              subheader={<ListSubheader>{t("menu.categories.incidents", "Ereignisse")}</ListSubheader>}
            >
              {incidentRoutes.filter(filter).map((route) => (
                <ListItem
                  data-test-id={`mobile-menu-${route.id}`}
                  disabled={isDisabled(route)}
                  key={`menu-drawer-${route.fullPath}`}
                  onClick={(event) => handleClick(event, route.fullPath)}
                  selected={pathname === route.fullPath}
                  theme={theme}
                >
                  {route.icon}
                  <Box sx={{ ml: 2 }}>
                    <ListItemText primary={route.name} />
                  </Box>
                </ListItem>
              ))}
            </List>
          </Fragment>
        )}

        <List
          subheader={<ListSubheader>{t("menu.categories.informations", "Informationen")}</ListSubheader>}
        >
          {legalRoutes.filter(filter).map((route) => (
            <ListItem
              data-test-id={`mobile-menu-${route.id}`}
              disabled={isDisabled(route)}
              key={`menu-drawer-${route.fullPath}`}
              onClick={(event) => handleClick(event, route.fullPath)}
              selected={pathname === route.fullPath}
              theme={theme}
            >
              {route.icon}
              <Box sx={{ ml: 2 }}>
                <ListItemText primary={route.name} />
              </Box>
            </ListItem>
          ))}

          <ListItem data-test-id={`mobile-menu-sign-out`} onClick={logOut} theme={theme}>
            <SvgIcon component={SignOutIcon} viewBox="0 0 512 512" />
            <Box sx={{ ml: 2 }}>
              <ListItemText primary={t("signOut.label", "Abmelden")} />
            </Box>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};
