import { Paragraph } from "./typography/paragraph";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export const shortcodeConfig = {
  endBlock: "]",
  inlineMode: true,
  startBlock: "[",
};

type Props = { identifier?: string };

export const ShortCodeRenderer: React.FC<Props> = () => null;

const TitleMarkdown = styled(ReactMarkdown).attrs({ components: { p: React.Fragment } })<any>``;

const TextMarkdown = styled(ReactMarkdown).attrs({
  components: { p: Paragraph, shortcode: ShortCodeRenderer },
})<any>``;

export { TitleMarkdown, TextMarkdown };
