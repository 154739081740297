import { Box, CircularProgress, Dialog, Fab, useTheme, Zoom } from "@material-ui/core";
import { canUseAnimation } from "../theme";
import { Close } from "@material-ui/icons";
import { credentialsState } from "./auth.state";
import { ECredentialsType } from "./credentials-type.enum";
import { EnterSupportCodeValidationSchema } from "./enter-support-code.validation";
import { Formik, Field, Form, FormikHelpers } from "formik";
import {
  FormikTextInput,
  InlineButton,
  Logo,
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
  TechnicalHint,
  useFabOffset,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { PrivacyPolicy, TermsAndConditions } from "../legal";
import { useRecoilState } from "recoil";
import { useTranslation, Trans } from "react-i18next";
import React from "react";

interface FormState {
  code: string;
}

export enum EModalContent {
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
  PRIVACY_POLICY = "PRIVACY_POLICY",
}

export const EnterSupportCode: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["access", "common"]);
  const theme = useTheme();
  const [showGuestHint, setShowGuestHint] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(EModalContent.PRIVACY_POLICY);
  const [credentials, setCredentials] = useRecoilState(credentialsState);
  const fabOffset = useFabOffset();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const closeModal = () => setIsModalOpen(false);
  const openModal = (type: EModalContent) => {
    setModalContent(type);
    setIsModalOpen(true);
  };

  const openTermsModal = () => openModal(EModalContent.TERMS_AND_CONDITIONS);
  const openPrivacyModal = () => openModal(EModalContent.PRIVACY_POLICY);
  const toggleGuestHint = () => setShowGuestHint(!showGuestHint);

  const handleSubmit = ({ code }: FormState, { setSubmitting }: FormikHelpers<FormState>) => {
    setCredentials({ type: ECredentialsType.ACCESS_CODE, value: code.trim() });
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      <PagePadding>
        <WidthWrapper>
          <Box sx={{ mb: 6, justifyContent: "center", display: "flex" }}>
            <Logo />
          </Box>

          <div>
            {showGuestHint && (
              <React.Fragment>
                <PageTitle>{t("enterCode.guest.title", "Du bist Gast")}</PageTitle>

                <Trans ns="access" i18nKey="enterCode.guest.copy">
                  <Paragraph>
                    Bitte verwende den Link, den du von deinem Gastgeber erhalten hast. Klicke diesen
                    Link an oder kopiere ihn direkt in die Adresszeile deines Browsers.
                  </Paragraph>

                  <Paragraph>
                    Achte darauf, den Link vollständig und ohne Zeilenumbrüche in die Adresszeile
                    einzugeben.
                  </Paragraph>
                </Trans>

                <Box sx={{ mt: 1 }}>
                  <SecondaryButton onClick={toggleGuestHint}>
                    {t("common:backButton.label", "Zurück")}
                  </SecondaryButton>
                </Box>
              </React.Fragment>
            )}

            {!showGuestHint && (
              <React.Fragment>
                <PageTitle subtitle={t("enterCode.host.subtitle", "Du bist Gastgeber")}>
                  {t("enterCode.host.title", "Bitte gib deinen Supportcode ein")}
                </PageTitle>

                <Trans ns="access" i18nKey="enterCode.host.copy">
                  <Paragraph>
                    Bitte gib deinen Zugangscode ein, um Zugriff auf dein Culinario Mortale® Spiel zu
                    erhalten. Diesen Code hast du mit deinem Spiel erhalten.
                  </Paragraph>

                  <Paragraph>
                    Du bist Gast und wurdest zu einem Spiel eingeladen? Dann{" "}
                    <InlineButton
                      onClick={toggleGuestHint}
                      style={{ display: "inline", textAlign: "left" }}
                    >
                      klicke hier.
                    </InlineButton>
                  </Paragraph>
                </Trans>

                <Formik
                  initialValues={{ code: "" }}
                  onSubmit={handleSubmit}
                  validationSchema={EnterSupportCodeValidationSchema}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Field
                        component={FormikTextInput}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label="Code"
                        name="code"
                        placeholder={t("enterCode.host.placeholder", "Dein Zugangscode")}
                        required
                        theme={theme}
                        variant="outlined"
                      />

                      <Box sx={{ mt: 1 }}>
                        <PrimaryButton disabled={isSubmitting || !!credentials.value} type="submit">
                          {!!credentials.value && (
                            <CircularProgress
                              color="inherit"
                              size={16}
                              style={{ marginRight: theme.spacing(2) }}
                            />
                          )}

                          {t("common:signIn.label", "Anmelden")}
                        </PrimaryButton>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </React.Fragment>
            )}

            <Box sx={{ mt: 2 }}>
              <TechnicalHint>
                <Trans ns="access" i18nKey="enterCode.legalNotice">
                  Die Nutzung dieses Spiels unterliegt unseren{" "}
                  <InlineButton color="inherit" onClick={openTermsModal}>
                    Geschäftsbedingungen
                  </InlineButton>{" "}
                  und unserer{" "}
                  <InlineButton color="inherit" onClick={openPrivacyModal}>
                    Datenschutzerklärung
                  </InlineButton>
                  .
                </Trans>
              </TechnicalHint>
            </Box>
          </div>
        </WidthWrapper>
      </PagePadding>

      <Dialog fullScreen keepMounted={false} onClose={closeModal} open={isModalOpen}>
        {modalContent === EModalContent.PRIVACY_POLICY ? <PrivacyPolicy /> : <TermsAndConditions />}

        <Zoom
          in={true}
          style={{ transitionDelay: canUseAnimation ? `300ms` : "0ms" }}
          timeout={transitionDuration}
          unmountOnExit
        >
          <Fab
            aria-label="close"
            data-test-id="modal-close-btn"
            onClick={closeModal}
            style={{
              background: theme.palette.common.black,
              bottom: theme.spacing(4),
              color: "white",
              position: "fixed",
              right: fabOffset,
              textTransform: "none",
            }}
          >
            <Close />
          </Fab>
        </Zoom>
      </Dialog>
    </React.Fragment>
  );
};
