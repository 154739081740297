import React from "react";

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if ("msHidden" in document) {
    return "msvisibilitychange";
  } else if ("webkitHidden" in document) {
    return "webkitvisibilitychange";
  }

  return "visibilitychange";
}

export function getBrowserDocumentHiddenProp(): any {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if ("msHidden" in document) {
    return "msHidden";
  } else if ("webkitHidden" in document) {
    return "webkitHidden";
  }

  return "hidden";
}

export function getIsDocumentHidden(): boolean {
  const prop = getBrowserDocumentHiddenProp();
  const doc: any = document;

  return !doc[prop];
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    window.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      window.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}
