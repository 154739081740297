import { FieldProps, getIn } from "formik";
import { Theme } from "@material-ui/core";
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";
import styled from "styled-components";

export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, "name" | "value" | "error"> {}

export function fieldToTextField({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting, touched, errors },
  onBlur,
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled ?? isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

const TextField = ({ children, ...props }: TextFieldProps) => (
  <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>
);

export const FormikTextInput = styled(TextField)<{ theme: Theme }>`
  & label {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 1rem;
  }

  & input {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 1rem;
    padding: 16.5px 14px;
  }

  & label.Mui-focused {
    color: ${(props) => props.theme.palette.text.primary};
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: var(--ion-text-color);
    }
  }

  & .MuiOutlinedInput-root fieldset {
    border-color: var(--ion-color-step-300);
  }
`;
