import { Box } from "@material-ui/core";
import { Logo, PagePadding, PageTitle, Paragraph, useHasRenderedClass, WidthWrapper } from "../shared";
import { useTranslation, Trans } from "react-i18next";
import React from "react";

export const OfflineNotice: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["access"]);

  return (
    <PagePadding>
      <WidthWrapper>
        <Box sx={{ mb: 6, justifyContent: "center", display: "flex" }}>
          <Logo />
        </Box>

        <PageTitle>{t("offline.title", "Keine Verbindung möglich")}</PageTitle>

        <Trans ns="access" i18nKey="offline.copy">
          <Paragraph>
            Du bist leider offline. Zum Spielen benötigst du eine aktive Internetverbindung.
          </Paragraph>

          <Paragraph>
            Diese Seite aktualisiert sich automatisch, sobald du wieder mit dem Internet verbunden bist.
          </Paragraph>
        </Trans>
      </WidthWrapper>
    </PagePadding>
  );
};
