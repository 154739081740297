import { CharacterCard } from "./character-card";
import { charactersSelector } from "../game-data";
import {
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  SecondaryButton,
  TechnicalHint,
  UnstyledList,
  WidthWrapper,
} from "../shared";
import { Box, useTheme } from "@material-ui/core";
import { ICreateMatchFormState } from "./form-state.interface";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import camelCase from "camelcase";
import React, { Fragment } from "react";

export const RoleDistribution: React.FC = () => {
  const { t } = useTranslation(["matchAdmin", "common"]);
  const { values } = useFormikContext<ICreateMatchFormState>();
  const theme = useTheme();
  const characters = useRecoilValue(charactersSelector);
  const navigate = useNavigate();

  const assignableCharacters = characters.filter(
    (c) => c.includeForMaxPlayers <= parseInt(values.numberOfPlayers) ?? 0
  );

  const charactersUnassigned = assignableCharacters.reduce((status, c) => {
    if (status === true) {
      return status;
    }

    const cCase = camelCase(c.name);

    if (
      !!!values.assignedCharacters[cCase].playerName &&
      !values.assignedCharacters[cCase].playedByHost
    ) {
      status = true;
    }

    return status;
  }, false);

  const hostUnassigned = !Object.keys(values.assignedCharacters).find(
    (key) => values.assignedCharacters[key].playedByHost
  );

  const isInvalid = charactersUnassigned || hostUnassigned;

  const handleBack = () =>
    navigate(t("matchAdmin:createMatch.case.step.path", "/vorbereitung/spiel-erstellen/der-fall"));

  const handleNext = () =>
    navigate(
      t("matchAdmin:createMatch.summary.step.path", "/vorbereitung/spiel-erstellen/zusammenfassung")
    );

  return (
    <Fragment>
      <PagePadding>
        <WidthWrapper>
          <PageTitle>{t("createMatch.playerDistribution.title", "Die Rollenverteilung")}</PageTitle>

          <Trans ns="matchAdmin" i18nKey="createMatch.playerDistribution.copy">
            <Paragraph>
              Bitte verteile die nachfolgenden Rollen, indem du auf „Zuweisen“ klickst und den Vornamen
              des Spielers bzw. der Spielerin angibst.
            </Paragraph>

            <Paragraph>
              Du selbst kannst dir eine der zu verteilenden Rollen aussuchen. Für dich als Gastgeber
              gelten während des Spiels die gleichen Regeln wie für alle anderen Mitspieler.
            </Paragraph>
          </Trans>

          <UnstyledList
            style={{
              borderBottom: `1px dashed ${theme.palette.grey[400]}`,
              borderTop: `1px dashed ${theme.palette.grey[400]}`,
              marginBottom: theme.spacing(2),
              marginTop: theme.spacing(1),
            }}
          >
            {characters.map((c, ix) => {
              const isOptional = c.includeForMaxPlayers > parseInt(values?.numberOfPlayers) ?? 0;
              return <CharacterCard character={c} key={`char-${ix}`} optional={isOptional} />;
            })}
          </UnstyledList>

          <Box sx={{ mt: 4 }}>
            <PrimaryButton disabled={isInvalid} onClick={handleNext}>
              {t("common:nextButton.label", "Weiter")}
            </PrimaryButton>
          </Box>

          <Box sx={{ mt: 1 }}>
            <SecondaryButton onClick={handleBack}>
              {t("common:previousButton.label", "Zurück")}
            </SecondaryButton>
          </Box>

          <Box sx={{ mt: 2 }}>
            <TechnicalHint>
              {t(
                "createMatch.playerDistribution.legalNotice",
                "Du kannst das Spiel vor Spielbeginn noch löschen, solltest du dich umentscheiden oder z. B. ein Gast kurzfristig absagen."
              )}
            </TechnicalHint>
          </Box>
        </WidthWrapper>
      </PagePadding>
    </Fragment>
  );
};
