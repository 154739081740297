import { Box } from "@material-ui/core";
import { ReactComponent as LogoImage } from "../assets/logo-horizontal.svg";
import React from "react";

export const Logo: React.FC = () => (
  <Box
    className="cm-header-logo"
    sx={{
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      margin: "0 auto",
      maxHeight: {
        xs: "14px",
        sm: "16px",
      },
      maxWidth: "480px",
      paddingBottom: "1px",
      width: "100%",
    }}
  >
    <LogoImage fill="white" title="Culinario Mortale Logo" />
  </Box>
);
