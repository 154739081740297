import { useMediaQuery, useTheme } from "@material-ui/core";
import LogoImage from "../assets/logo-vertical.svg";
import React from "react";

export const Logo: React.FC = () => {
  const theme = useTheme();
  const isAtLeastTablet = useMediaQuery(theme.breakpoints.up("sm"));

  let classes = ` ${isAtLeastTablet ? "" : ""}`;

  return (
    <div className={classes} style={{ display: "flex" }}>
      <img src={LogoImage} alt="Culinario Mortale®" style={{ width: "5.5rem" }} />
    </div>
  );
};
