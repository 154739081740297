import React, { useState, useCallback, Fragment } from "react";
import { ConfirmationContext } from "./confirmation-context";
import { ConfirmationDialogue } from "./confirmation-dialogue";

type Options = {
  title?: string;
  description?: string;
  confirmationText?: string;
  cancellationText?: string;
  dialogProps?: Record<string, any>;
  confirmationButtonProps?: Record<string, any>;
  cancellationButtonProps?: Record<string, any>;
};

const DEFAULT_OPTIONS = {
  title: "Bist du dir sicher?",
  description: "",
  confirmationText: "Okay",
  cancellationText: "Abbrechen",
  dialogProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
};

const buildOptions = (defaultOptions: Options, options: Options) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {}),
  };
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps || DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {}),
  };
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps || DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {}),
  };

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
  };
};

type Props = {
  defaultOptions?: Options;
};

export const ConfirmationProvider: React.FC<Props> = ({ children, defaultOptions = {} }) => {
  const [options, setOptions] = useState({ ...DEFAULT_OPTIONS, ...defaultOptions });
  const [resolveReject, setResolveReject] = useState<((value: unknown) => void)[]>([]);
  const [resolve] = resolveReject;

  const confirm = useCallback(
    (options: Options = {}) => {
      return new Promise((resolve, reject) => {
        setOptions(buildOptions(defaultOptions, options));
        setResolveReject([resolve, reject]);
      });
    },
    [defaultOptions, resolve] // eslint-disable-line
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    resolve(false);
    handleClose();
  }, [resolve, handleClose]); // eslint-disable-line

  const handleConfirm = useCallback(() => {
    resolve(true);
    handleClose();
  }, [resolve, handleClose]);

  return (
    <Fragment>
      <ConfirmationContext.Provider value={confirm}>{children}</ConfirmationContext.Provider>
      <ConfirmationDialogue
        open={resolveReject.length === 2}
        options={options}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  );
};
