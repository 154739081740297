import { Box } from "@material-ui/core";
import React from "react";

type Props = { narrow?: boolean };

export const WidthWrapper: React.FC<Props> = ({ children, narrow = false, ...passThrough }) => {
  return (
    <Box
      sx={{
        maxWidth: narrow ? "var(--max-round-text-width)" : "var(--max-text-width)",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      {...passThrough}
    >
      {children}
    </Box>
  );
};
