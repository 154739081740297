import { Background } from "./background";
import { CircleOfSuspects } from "./circle-of-suspects";
import { EMatchStatus } from "../game-data";
import { getRelativeRoutePath } from "../shared";
import { IRoute } from "../navigation";
import { ReactComponent as BackgroundIcon } from "../assets/icon-background.svg";
import { ReactComponent as CircleOfSuspectsIcon } from "../assets/icon-circle-of-suspects.svg";
import { ReactComponent as YourRoleIcon } from "../assets/icon-your-role.svg";
import { SvgIcon } from "@material-ui/core";
import { YourRole } from "./your-role";
import React from "react";

export const backgroundRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => !!status && status >= EMatchStatus.CREATED,
  component: <Background />,
  icon: <SvgIcon component={BackgroundIcon} viewBox="0 0 512 512" />,
  id: "background",
  name: t("routes:background.label", "Die Vorgeschichte"),
  fullPath: t("routes:background.path", "/spiel/vorgeschichte"),
  relativePath: getRelativeRoutePath(t("routes:background.path", "/spiel/vorgeschichte")),
});

export const yourRoleRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => !!status && status >= EMatchStatus.CREATED,
  component: <YourRole />,
  fullPath: t("routes:yourRole.path", "/hintergrund/deine-rolle"),
  icon: <SvgIcon component={YourRoleIcon} viewBox="0 0 512 512" />,
  id: "your-role",
  name: t("routes:yourRole.label", "Deine Rolle"),
  relativePath: getRelativeRoutePath(t("routes:yourRole.path", "/hintergrund/deine-rolle")),
});

export const circleOfSuspectsRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => !!status && status >= EMatchStatus.CREATED,
  component: <CircleOfSuspects />,
  fullPath: t("routes:circleOfSuspects.path", "/hintergrund/die-verdaechtigen"),
  icon: <SvgIcon component={CircleOfSuspectsIcon} viewBox="0 0 512 512" />,
  id: "circle-of-suspects",
  name: t("routes:circleOfSuspects.label", "Die Verdächtigen"),
  relativePath: getRelativeRoutePath(
    t("routes:circleOfSuspects.path", "/hintergrund/die-verdaechtigen")
  ),
});
