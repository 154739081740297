import {
  EMatchStatus,
  ERound,
  gameRoundsSelector,
  isHostSelector,
  matchStatusSelector,
} from "../game-data";
import { hintsState } from "./state";
import { MarginalOffsetWrapper } from "./marginal-offset-wrapper";
import { MarginalsContainer } from "./marginals-container";
import { PagePadding, PageTitle, useHasRenderedClass, usePageBottom, WidthWrapper } from "../shared";
import { RoundClosedNotice } from "./round-closed-notice";
import { RoundCover } from "./round-cover";
import { RoundHint } from "./round-hint";
import { RoundTextMarkdown, RoundTitleMarkdown } from "./markdown";
import { UnlockRound } from "./unlock-round";
import { useRecoilState, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React from "react";

type Props = { round: ERound };
export const Round: React.FC<Props> = ({ round }) => {
  useHasRenderedClass();
  const { t } = useTranslation(["rounds", "common"]);
  const [hints, setHints] = useRecoilState(hintsState);
  const isHost = useRecoilValue(isHostSelector);
  const matchStatus = useRecoilValue(matchStatusSelector);
  const rounds = useRecoilValue(gameRoundsSelector);
  const isPageBottom = usePageBottom(180);

  const isHintRead = hints[round]?.isHintRead;
  const data = rounds && rounds[round];

  const getStatus = () => {
    let status: EMatchStatus;

    switch (round) {
      case ERound.FIRST:
        status = EMatchStatus.FIRST_ROUND_OPENED;
        break;

      case ERound.SECOND:
        status = EMatchStatus.SECOND_ROUND_OPENED;
        break;

      case ERound.THIRD:
        status = EMatchStatus.THIRD_ROUND_OPENED;
        break;
    }

    return status;
  };

  const status = getStatus();
  const showCover = !isHintRead && matchStatus >= status;

  const hideHint = () => setHints((currVal) => ({ ...currVal, [round]: { isHintRead: true } }));

  if (isHost && matchStatus < status) {
    return <UnlockRound round={round} />;
  }

  if (!isHost && matchStatus < status) {
    return <RoundClosedNotice round={round} />;
  }

  return (
    <React.Fragment>
      {isHintRead && (
        <MarginalsContainer hideFab={showCover} isPageBottom={isPageBottom}>
          <PagePadding>
            <WidthWrapper>
              <MarginalOffsetWrapper>
                <WidthWrapper narrow>
                  <PageTitle narrow subtitle={t("common:readInSilence", "Bitte still für dich lesen")}>
                    <RoundTitleMarkdown>{data?.title ?? ""}</RoundTitleMarkdown>
                  </PageTitle>
                </WidthWrapper>

                <RoundTextMarkdown>{data?.content ?? ""}</RoundTextMarkdown>
              </MarginalOffsetWrapper>

              <MarginalOffsetWrapper>
                <WidthWrapper narrow>
                  <RoundHint round={round} />
                </WidthWrapper>
              </MarginalOffsetWrapper>
            </WidthWrapper>
          </PagePadding>
        </MarginalsContainer>
      )}

      <RoundCover isOpen={showCover} round={round} onDismiss={hideHint} />
    </React.Fragment>
  );
};
