export enum ERound {
  FIRST = "FIRST",
  SECOND = "SECOND",
  THIRD = "THIRD",
}

export interface IRoundsState {
  [ERound.FIRST]: IRoundState;
  [ERound.SECOND]: IRoundState;
  [ERound.THIRD]: IRoundState;
  hostSolutionWarningRead: boolean;
}

export interface IRoundState {
  openedAt?: Date;
  isHintRead: boolean;
}

export type RoundPayload = {
  round: ERound;
};
