import { Route, Routes } from "react-router-dom";
import { Wizard } from "./wizard";
import { WizardIntroduction } from "./wizard-introduction";
import React from "react";

export const CreateMatch: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<WizardIntroduction />} />
      <Route path="*" element={<Wizard />} />
    </Routes>
  );
};
