import { EMatchStatus, hostSelector, isHostSelector, matchStatusSelector } from "../game-data";
import {
  ConditionalInlineLink,
  GameHint,
  InlineButton,
  PagePadding,
  PageTitle,
  Paragraph,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { solutionRoute } from "./routes";
import { Trans, useTranslation } from "react-i18next";
import { UnlockAccusation } from "./unlock-accusation";
import { useRecoilValue } from "recoil";
import React from "react";

export const Accusation: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["rounds"]);
  const host = useRecoilValue(hostSelector);
  const isHost = useRecoilValue(isHostSelector);
  const matchStatus = useRecoilValue(matchStatusSelector);

  if (isHost && matchStatus < EMatchStatus.ACCUSATION_OPENED) {
    return <UnlockAccusation />;
  }

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle>{t("accusation.title", "Anklage und Verteidigung")}</PageTitle>

        <Trans ns="rounds" i18nKey="accusation.copy">
          <Paragraph>
            Alle Spieler sollen nun der Reihe nach ihren Verdacht äußern, wer der Täter oder die Täterin
            sein könnte und welches Mordmotiv infrage kommt. Alle Verdächtigten haben direkt die
            Möglichkeit, sich zu verteidigen.
          </Paragraph>

          <Paragraph>
            Am Ende wird darüber abgestimmt, wen die Gruppe für den Täter oder die Täterin hält. Jeder
            Spieler hat dabei genau eine Stimme. Bei Gleichstand kommt es zu einer Stichwahl zwischen den
            Verdächtigen mit den meisten Stimmen.
          </Paragraph>

          <Paragraph>
            Bitte beachtet, dass ihr nur als Gruppe gewinnen könnt, wenn ihr euch mehrheitlich für den
            richtigen Verdächtigen entscheidet. Gelingt euch das nicht, gewinnt allein der Täter oder die
            Täterin.
          </Paragraph>
        </Trans>

        <GameHint>
          <Trans ns="rounds" i18nKey="accusation.copy2">
            Dieser Abschnitt sollte insgesamt nicht länger als 30 Minuten dauern.
          </Trans>{" "}
          {isHost ? (
            <Trans ns="rounds" i18nKey="accusation.copy3">
              Nachdem ihr abgestimmt habt, wen ihr als Täter bzw. Täterin der Polizei übergeben wollt,
              liest du allen Spielern die{" "}
              <InlineButton to={solutionRoute(t).fullPath ?? "/"}>Aufklärung</InlineButton> vor.
            </Trans>
          ) : (
            <Trans ns="rounds" i18nKey="accusation.copy4">
              Nachdem ihr abgestimmt habt, wen ihr als Täter bzw. Täterin der Polizei übergeben wollt,
              liest der Gastgeber ({{ hostName: host?.playerName }}) euch die{" "}
              <ConditionalInlineLink
                path={solutionRoute(t).fullPath}
                show={matchStatus >= EMatchStatus.SOLUTION_SHARED}
              >
                Aufklärung
              </ConditionalInlineLink>{" "}
              vor.
            </Trans>
          )}
        </GameHint>
      </WidthWrapper>
    </PagePadding>
  );
};
