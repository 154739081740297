import { backgroundSelector } from "../game-data";
import { CommonRulesText } from "../rules";
import { PagePadding, PageTitle, TextMarkdown, useHasRenderedClass } from "../shared";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React from "react";

export const Background: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["rules"]);
  const background = useRecoilValue(backgroundSelector);

  return (
    <PagePadding>
      <PageTitle>{t("background.title", "Die Vorgeschichte")}</PageTitle>
      <TextMarkdown>{background}</TextMarkdown>
      <CommonRulesText />
    </PagePadding>
  );
};
