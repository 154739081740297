import { Box } from "@material-ui/core";
import { descriptionSelector, gameTitleSelector } from "../game-data";
import {
  PagePadding,
  PageTitle,
  PrimaryButton,
  SecondaryButton,
  TextMarkdown,
  WidthWrapper,
} from "../shared";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

export const Story: React.FC = () => {
  const { t } = useTranslation(["matchAdmin", "common"]);
  const background = useRecoilValue(descriptionSelector);
  const navigate = useNavigate();
  const title = useRecoilValue(gameTitleSelector);

  const handleBack = () =>
    navigate(
      t("matchAdmin:createMatch.playerNumber.step.path", "/vorbereitung/spiel-erstellen/spieleranzahl")
    );

  const handleNext = () =>
    navigate(
      t(
        "matchAdmin:createMatch.playerDistribution.step.path",
        "/vorbereitung/spiel-erstellen/rollenverteilung"
      )
    );

  return (
    <Fragment>
      <PagePadding>
        <WidthWrapper>
          <PageTitle>
            {t("createMatch.case.title", "Der Fall:")}
            <br />
            {title}
          </PageTitle>

          <TextMarkdown>{background}</TextMarkdown>

          <Box sx={{ mt: 5 }}>
            <PrimaryButton onClick={handleNext}>{t("common:nextButton.label", "Weiter")}</PrimaryButton>
          </Box>

          <Box sx={{ mt: 1 }}>
            <SecondaryButton onClick={handleBack}>
              {t("common:previousButton.label", "Zurück")}
            </SecondaryButton>
          </Box>
        </WidthWrapper>
      </PagePadding>
    </Fragment>
  );
};
