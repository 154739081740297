import { useRef, useEffect } from "react";

export const usePortal = (containerId: string = "") => {
  const rootElemRef = useRef(document.createElement("div"));

  useEffect(() => {
    const target = rootElemRef.current;
    const container = document.getElementById(containerId) ?? document.body;
    container.appendChild(target);
    return () => {
      target.remove();
    };
  }, [containerId]);

  return rootElemRef.current;
};
