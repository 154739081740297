import {
  accusationRoute,
  firstRoundRoute,
  prologueRoute,
  secondRoundRoute,
  solutionRoute,
  thirdRoundRoute,
} from "./routes";
import { Redirect, SubNav } from "../navigation";
import { Route, Routes } from "react-router";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

export const RoundsEntry: React.FC = () => {
  const { t } = useTranslation(["routes"]);
  const routes = [
    prologueRoute(t),
    firstRoundRoute(t),
    secondRoundRoute(t),
    thirdRoundRoute(t),
    accusationRoute(t),
    solutionRoute(t),
  ];

  return (
    <Fragment>
      <SubNav routes={routes} />

      <Routes>
        <Route path="/" element={<Redirect to={firstRoundRoute(t).fullPath} />} />
        {routes.map((route) => (
          <Route key={route.fullPath} path={route.relativePath} element={route.component} />
        ))}
      </Routes>
    </Fragment>
  );
};
