import { AuthWrapper, ConnectionWrapper } from "./authentication";
import { BackgroundEntry, backgroundRoute } from "./your-role";
import { BrowserRouter } from "react-router-dom";
import { ConfigWrapper } from "./config";
import { ConfirmationProvider, getRelativeRouteRoot } from "./shared";
import { CssBaseline } from "@material-ui/core";
import { firstRoundRoute, RoundsEntry } from "./rounds";
import { GameDataWrapper } from "./game-data";
import { imprintRoute, LegalEntry } from "./legal";
import { incidentARoute, IncidentsEntry } from "./incidents";
import { LanguageWrapper, NotificationsWrapper, StylesWrapper } from "./app-wrapper";
import { Main, Redirect, ScrollToTop } from "./navigation";
import { RecoilRoot } from "recoil";
import { Route, Routes } from "react-router";
import { RulesEntry, welcomeRoute } from "./rules";
import { UnsupportedBrowserWrapper } from "./browser-support";
import { useTranslation } from "react-i18next";
import React, { Suspense } from "react";

import "./theme/variables.css";
import "./theme/global.css";

export const App: React.FC = () => {
  const { t } = useTranslation(["routes"]);

  return (
    <RecoilRoot>
      <StylesWrapper>
        <UnsupportedBrowserWrapper>
          <Suspense fallback="Loading">
            <BrowserRouter>
              <CssBaseline />
              <LanguageWrapper>
                <ConfigWrapper>
                  <NotificationsWrapper>
                    <ConnectionWrapper>
                      <AuthWrapper>
                        <ConfirmationProvider>
                          <GameDataWrapper>
                            <ScrollToTop />
                            <Main>
                              <Routes>
                                <Route path="/" element={<Redirect to={welcomeRoute(t).fullPath} />} />
                                <Route
                                  path={`${getRelativeRouteRoot(incidentARoute(t).fullPath)}/*`}
                                  element={<IncidentsEntry />}
                                />
                                <Route
                                  path={`${getRelativeRouteRoot(backgroundRoute(t).fullPath)}/*`}
                                  element={<BackgroundEntry />}
                                />
                                <Route
                                  path={`${getRelativeRouteRoot(imprintRoute(t).fullPath)}/*`}
                                  element={<LegalEntry />}
                                />
                                <Route
                                  path={`${getRelativeRouteRoot(firstRoundRoute(t).fullPath)}/*`}
                                  element={<RoundsEntry />}
                                />
                                <Route
                                  path={`${getRelativeRouteRoot(welcomeRoute(t).fullPath)}/*`}
                                  element={<RulesEntry />}
                                />

                                <Route path="*" element={<Redirect to={welcomeRoute(t).fullPath} />} />
                              </Routes>
                            </Main>
                          </GameDataWrapper>
                        </ConfirmationProvider>
                      </AuthWrapper>
                    </ConnectionWrapper>
                  </NotificationsWrapper>
                </ConfigWrapper>
              </LanguageWrapper>
            </BrowserRouter>
          </Suspense>
        </UnsupportedBrowserWrapper>
      </StylesWrapper>
    </RecoilRoot>
  );
};
