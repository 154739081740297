import { getRelativeRoutePath } from "../shared";
import { Imprint } from "./imprint";
import { IRoute } from "../navigation";
import { PrivacyPolicy } from "./privacy-policy";
import { ReactComponent as ImprintIcon } from "../assets/icon-imprint.svg";
import { ReactComponent as PrivacyIcon } from "../assets/icon-privacy.svg";
import { ReactComponent as TosIcon } from "../assets/icon-tos.svg";
import { SvgIcon } from "@material-ui/core";
import { TermsAndConditions } from "./terms-and-conditions";
import React from "react";

export const imprintRoute = (t: Function): IRoute => ({
  canAccess: () => true,
  component: <Imprint />,
  icon: <SvgIcon component={ImprintIcon} viewBox="0 0 512 512" />,
  id: "imprint",
  name: t("routes:imprint.label", "Impressum"),
  fullPath: t("routes:imprint.path", "/rechtliche-informationen/impressum"),
  relativePath: getRelativeRoutePath(t("routes:imprint.path", "/rechtliche-informationen/impressum")),
});

export const privacyPolicyRoute = (t: Function): IRoute => ({
  canAccess: () => true,
  component: <PrivacyPolicy />,
  icon: <SvgIcon component={PrivacyIcon} viewBox="0 0 512 512" />,
  id: "privacy-policy",
  name: t("routes:privacyPolicy.label", "Datenschutzerklärung"),
  fullPath: t("routes:privacyPolicy.path", "/rechtliche-informationen/datenschutzerklaerung"),
  relativePath: getRelativeRoutePath(
    t("routes:privacyPolicy.path", "/rechtliche-informationen/datenschutzerklaerung")
  ),
});

export const termsAndConditionsRoute = (t: Function): IRoute => ({
  canAccess: () => true,
  component: <TermsAndConditions />,
  icon: <SvgIcon component={TosIcon} viewBox="0 0 512 512" />,
  id: "terms-and-conditions",
  name: t("routes:tos.label", "AGB"),
  fullPath: t("routes:tos.path", "/rechtliche-informationen/agb"),
  relativePath: getRelativeRoutePath(t("routes:tos.path", "/rechtliche-informationen/agb")),
});
