import { EIncident, incidentsSelector } from "../game-data";
import { PageTitle, TextMarkdown } from "../shared";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

type Props = { incident: EIncident };

export const IncidentContent: React.FC<Props> = ({ incident }) => {
  const { t } = useTranslation(["common", "incidents"]);
  const incidents = useRecoilValue(incidentsSelector);
  const current = incidents[incident];

  return (
    <Fragment>
      <PageTitle subtitle={t("pleaseReadAloud", "Bitte vorlesen")}>
        {t("incident", "Ereignis")} {incident}
      </PageTitle>
      <TextMarkdown>{current}</TextMarkdown>
    </Fragment>
  );
};
