import { H2, PagePadding, PageTitle, Paragraph, useHasRenderedClass, WidthWrapper } from "../shared";
import { Trans, useTranslation } from "react-i18next";
import React from "react";

export const TermsAndConditions: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["legal", "common"]);

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle>{t("tos.title", "Allgemeine Geschäftsbedingungen")}</PageTitle>

        <H2>
          <Trans ns="legal" i18nKey="tos.scope.title">
            1. Geltungsbereich
          </Trans>
        </H2>
        <Paragraph>
          <Trans ns="legal" i18nKey="tos.scope.copy">
            Diese Allgemeinen Geschäftsbedingungen gelten für alle Bestellungen durch einen Verbraucher
            in unsere Webshop auf der Internetseite „www.culinario-mortale.de“ (im Folgenden „Webshop“
            genannt). Maßgeblich ist die jeweils zum Zeitpunkt der Bestellung gültige Fassung.
            DieVertragssprache ist ausschließlich deutsch. Verbraucher im Sinne des § 13 BGB ist eine
            natürliche Person, die einen Kauf zu Zwecken tätigt, die überwiegend weder ihrer gewerblichen
            noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.{" "}
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="tos.provider.title">
            2. Anbieter
          </Trans>
        </H2>
        <Paragraph>
          <Trans ns="legal" i18nKey="tos.provider.copy">
            Im Falle des Vertragsschlusses kommt der Vertrag mit <br />
            <br />
            Bischoff, Kauffmann & Otani GbR
            <br />
            Württembergallee 29
            <br />
            14052 Berlin
            <br />
            Deutschland
            <br />
            <br />
            vertreten durch die Geschäftsführer Florian Bischoff, Jens Kauffmann, Takeshi Otani
            <br />
            <br />
            Tel.: +49 (0) 30 25 29 21 51
            <br />
            E-Mail: mail@culinario-mortale.de
            <br />
            Website: www.culinario-mortale.de
            <br />
            Umsatzsteuer-Identifikationsnummer: DE288168520 (nachfolgend Anbieter) genannt zustande.
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="tos.dispute.title">
            3. Streitschlichtung
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="tos.dispute.copy">
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Du
            findest dieser unter https://ec.europa.eu/consumers/odr.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="tos.dispute.copy2">
            Unsere E-Mail-Adresse lautet mail@culinario-mortale.de.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="tos.dispute.copy3">
            Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind
            wir nicht verpflichtet und nicht bereit.
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="tos.severability.title">
            4. Salvatorische Klausel
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="tos.severability.copy">
            Die Unwirksamkeit einer Bestimmung dieser Allgemeinen Geschäftsbedingungen hat keine
            Auswirkungen auf die Wirksamkeit der sonstigen Bestimmungen.
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="tos.changes.title">
            5. Änderung der Allgemeinen Geschäftsbedingungen
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="tos.changes.copy">
            Wir als Verantwortliche behalten es uns vor, unsere Allgemeinen Geschäftsbedingungen
            jederzeit im Hinblick auf geltende Gesetze und Vorschriften zu verändern. Derzeitiger Stand
            ist Mai 2020.
          </Trans>
        </Paragraph>
      </WidthWrapper>
    </PagePadding>
  );
};
