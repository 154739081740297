import { Theme, Typography, useTheme } from "@material-ui/core";
import styled from "styled-components";
import React from "react";

type Props = { narrow?: boolean };

export const Text = styled(Typography).attrs({
  component: "div",
  variant: "caption",
})<Props & { theme: Theme }>`
  color: ${(props) => props.theme.palette.grey[600]};
  max-width: ${(props: Props) =>
    props.narrow ? "var(--max-round-text-width)" : "var(--max-text-width)"};
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 1em;

  & > a {
    color: ${(props) => props.theme.palette.grey[600]};

    &:hover {
      color: ${(props) => props.theme.palette.grey[800]};
    }
  }
`;

export const TechnicalHint: React.FC<Props> = ({ children, ...passThrough }) => {
  const theme = useTheme();
  return (
    <Text {...passThrough} theme={theme}>
      {children}
    </Text>
  );
};
