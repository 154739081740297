import { Avatar } from "./avatar";
import { SubHeadline } from "../typography/sub-headline";
import { Theme, Typography, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";

export const Header = styled.div<{ theme: Theme }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing(0.5)};
  padding-top: ${(props) => props.theme.spacing(2)};
`;

type Props = {
  age: number;
  headline?: string;
  illustration?: string;
  name: string;
  subHeadline?: string;
};

export const CharacterCardHeader: React.FC<Props> = ({
  age,
  headline,
  illustration,
  name,
  subHeadline,
}) => {
  const theme = useTheme();
  const { i18n } = useTranslation(["yourRole", "common"]);

  return (
    <Header theme={theme}>
      <div>
        {subHeadline && <Typography variant="subtitle2">{subHeadline}</Typography>}
        <SubHeadline style={{ marginTop: "0.35rem" }}>
          {headline ?? `${name} (${age}${["fr", "FR", "fr-FR"].includes(i18n.language) ? " ans" : ""})`}
        </SubHeadline>
      </div>
      {illustration && <Avatar src={illustration} alt={`Illustration ${name}`} theme={theme} />}
    </Header>
  );
};
