import { atom, selector } from "recoil";
import { credentialsType, ECredentialsType } from "../authentication";
import { DefaultValue } from "recoil";
import { EIncident } from "../incidents/incident.declarations";
import {
  ECharacterGender,
  EMatchStatus,
  ERound,
  IAssignedCharacter,
  IGame,
  IGameBooklet,
  IGameCharacter,
  IMatch,
} from "./game-data.interface";

export const matchIdEffect =
  () =>
  ({ setSelf, onSet }: any) => {
    const key = "cm-online-match-id";

    onSet((newValue: any) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else if (newValue["id"]) {
        localStorage.setItem(key, newValue.id);
      } else {
        // do nothing
      }
    });
  };

export const gameData = atom<Partial<IGame>>({
  key: "gameData",
  default: {},
});

export const matchData = atom<Partial<IMatch>>({
  key: "matchData",
  default: {},
  effects_UNSTABLE: [matchIdEffect()],
});

export const gameBookletData = atom<Partial<IGameBooklet>>({
  key: "gameBookletData",
  default: {},
});

export const gameTitleSelector = selector<string>({
  key: "gameTitleSelector",
  get: ({ get }): string => {
    const { title = "" } = get(gameData);
    return title;
  },
});

export const hostSelector = selector<IAssignedCharacter | undefined>({
  key: "hostSelector",
  get: ({ get }) => {
    const { assignedCharacters = [] } = get(matchData);
    return assignedCharacters?.find((ac) => ac.playedByHost);
  },
});

export const prologueSelector = selector({
  key: "prologueSelector",
  get: ({ get }) => {
    const { readsPrologue } = get(gameBookletData);
    const { prologue } = get(gameData);
    return readsPrologue ? prologue ?? "" : "";
  },
});

export const prologueReadBySelector = selector<IAssignedCharacter | undefined>({
  key: "prologueReadBySelector",
  get: ({ get }) => {
    const { assignedCharacters = [] } = get(matchData);
    return assignedCharacters?.find((ac) => ac.readsPrologue);
  },
});

export const prologueReadByCharacterSelector = selector<IGameCharacter | undefined>({
  key: "prologueReadByCharacterSelector",
  get: ({ get }) => {
    const { characters = [] } = get(gameData);
    return characters?.find((c) => c.readsPrologue);
  },
});

export const matchStatusSelector = selector<EMatchStatus>({
  key: "matchStatusSelector",
  get: ({ get }) => {
    const { status = EMatchStatus.NONE } = get(matchData);
    return status;
  },
});

export const hasMatchEndedSelector = selector<boolean>({
  key: "hasMatchEndedSelector",
  get: ({ get }) => {
    const status = get(matchStatusSelector);
    return status === EMatchStatus.ENDED;
  },
});

export const charactersSelector = selector({
  key: "charactersSelector",
  get: ({ get }) => {
    const { characters = [] } = get(gameData);
    return characters;
  },
});

export const matchCharactersSelector = selector({
  key: "matchCharactersSelector",
  get: ({ get }) => {
    const { assignedCharacters } = get(matchData);
    const { characters } = get(gameData);

    return (
      assignedCharacters?.map((ac) => {
        const character = characters?.find((c) => c.name === ac.name);

        return {
          illustration: "",
          ...character,
          ...ac,
        };
      }) ?? []
    );
  },
});

export const suspectsSelector = selector({
  key: "suspectsSelector",
  get: ({ get }) => {
    const { assignedCharacters } = get(matchData);
    const { characters } = get(gameData);
    const { circleOfSuspects = [] } = get(gameBookletData);

    return circleOfSuspects.map((s) => {
      const assigned = assignedCharacters?.find((ac) => ac.name === s.name);
      const gameCharacter = characters?.find((gc) => gc.name === s.name);

      return {
        ...s,
        illustration: gameCharacter?.illustration ?? "",
        playerName: assigned?.playerName ?? "",
      };
    });
  },
});

export const gameRoundsSelector = selector({
  key: "roundsSelector",
  get: ({ get }) => {
    const { rounds } = get(gameBookletData);
    return rounds;
  },
});

export const victimSelector = selector({
  key: "victimSelector",
  get: ({ get }) => {
    const { victim } = get(gameBookletData);
    return victim;
  },
});

export const characterNameSelector = selector({
  key: "characterNameSelector",
  get: ({ get }) => {
    const { name } = get(gameBookletData);
    return name ?? "";
  },
});

export const isPerpetratorSelector = selector({
  key: "isPerpetratorSelector",
  get: ({ get }) => {
    const { isPerpetrator } = get(gameBookletData);
    return isPerpetrator;
  },
});

export const genderSelector = selector({
  key: "genderSelector",
  get: ({ get }) => {
    const { gender = ECharacterGender.MALE } = get(gameBookletData);
    return gender;
  },
});

export const yourRoleSelector = selector({
  key: "yourRoleSelector",
  get: ({ get }) => {
    const { characters = [] } = get(gameData);
    const {
      age = 0,
      description = "",
      isPerpetrator = false,
      gender = ECharacterGender.MALE,
      name = "",
      task = "",
    } = get(gameBookletData);

    const gameCharacter = characters.find((gc) => gc.name === name);

    return {
      age,
      description,
      gender,
      illustration: gameCharacter?.illustration ?? "",
      isPerpetrator,
      name,
      task,
    };
  },
});

export const characterReadsPrologueSelector = selector({
  key: "characterReadsPrologueSelector",
  get: ({ get }) => {
    const { readsPrologue = false } = get(gameBookletData);
    return readsPrologue;
  },
});

export const roundsTimeSelector = selector({
  key: "roundsTimeSelector",
  get: ({ get }) => {
    const {
      rounds = {
        [ERound.FIRST]: { start: "", end: "" },
        [ERound.SECOND]: { start: "", end: "" },
        [ERound.THIRD]: { start: "", end: "" },
      },
    } = get(gameData);
    return rounds;
  },
});

export const backgroundSelector = selector({
  key: "backgroundSelector",
  get: ({ get }) => {
    const { background } = get(gameData);
    return background;
  },
});

export const descriptionSelector = selector({
  key: "descriptionSelector",
  get: ({ get }) => {
    const { description } = get(gameData);
    return description;
  },
});

export const playerRangeSelector = selector({
  key: "playerRangeSelector",
  get: ({ get }) => {
    const { minPlayers = 0, maxPlayers = 0 } = get(gameData);
    return { minPlayers, maxPlayers };
  },
});

export const isHostSelector = selector({
  key: "isHostSelector",
  get: ({ get }) => {
    const type = get(credentialsType);
    return type === ECredentialsType.ACCESS_CODE;
  },
});

export const floorPlanSelector = selector({
  key: "floorPlanSelector",
  get: ({ get }) => {
    const { floorplan = "" } = get(gameData);
    return floorplan;
  },
});

export const solutionSelector = selector({
  key: "solutionSelector",
  get: ({ get }) => {
    const { solution = "" } = get(gameData);
    return solution;
  },
});

export const incidentsSelector = selector({
  key: "incidentsSelector",
  get: ({ get }) => {
    const isHost = get(isHostSelector);
    const { incidentA = "", incidentB = "" } = get(gameData);

    return {
      [EIncident.A]: isHost ? incidentA : "",
      [EIncident.B]: isHost ? incidentB : "",
    };
  },
});
