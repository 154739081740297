import { backgroundRoute, yourRoleRoute } from "../your-role";
import { CommonRulesText } from "./common";
import {
  ConditionalInlineLink,
  InlineButton,
  PagePadding,
  PageTitle,
  Paragraph,
  useHasRenderedClass,
} from "../shared";
import { EMatchStatus, matchStatusSelector } from "../game-data";
import { structureOfTheGameRoute } from "./routes";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import React from "react";

export const GameRules: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["rules"]);
  const matchStatus = useRecoilValue(matchStatusSelector);

  return (
    <PagePadding>
      <PageTitle>{t("rules.title", "Die Spielregeln")}</PageTitle>
      <Paragraph>
        {t(
          "rules.copy",
          "Gemeinsam versucht ihr nach der Tat, eure Erinnerungen zusammen zu tragen und dem Täter auf die Schliche zu kommen. Dabei ruft ihr euch mit jeder Spielrunde weitere Erinnerungen an den Tathergang ins Gedächtnis, denn jede Runde behandelt einen anderen Zeitabschnitt des Tatabends."
        )}
      </Paragraph>

      <Paragraph>
        {t(
          "rules.copy2",
          "Das bedeutet, dass die Tat erst in Runde 3 vollständig rekonstruiert werden kann. Natürlich könnt ihr jederzeit auch zu einer der früheren Hinweisrunden zurückkehren. Ihr entscheidet als Gruppe, ab wann sich eure Diskussionen im Kreis drehen und ihr zur nächsten Runde übergehen wollt. Ein grober Richtwert sind 30 – 60 Minuten pro Runde. Dazwischen könnt ihr kurze Pausen einlegen, um z. B. gemeinsam zu essen."
        )}
      </Paragraph>

      <Paragraph>
        <Trans ns="rules" i18nKey="rules.copy3">
          Eure Spielmaterialien sind jeweils nur für eure eigenen Augen bestimmt. Lediglich die
          Abschnitte über Spielregeln (dieser Text),{" "}
          <InlineButton to={structureOfTheGameRoute(t).fullPath ?? "/"}>
            {{ structureOfTheGameRoute: structureOfTheGameRoute(t)?.name }}
          </InlineButton>{" "}
          und
          <ConditionalInlineLink
            show={matchStatus >= EMatchStatus.CREATED}
            path={backgroundRoute(t).fullPath}
          >
            ­Vorgeschichte
          </ConditionalInlineLink>{" "}
          sind für alle Spieler gleich. Hinweise zu eurer eigenen Person dürft ihr verschweigen, aber ihr
          dürft nicht lügen! Werdet ihr konkret nach einer Sache gefragt, müsst ihr die Wahrheit sagen.
          Das Recht zu lügen und andere Spieler auf eine falsche Fährte zu locken, hat allein der Täter
          oder die Täterin.
        </Trans>
      </Paragraph>

      <Paragraph>
        {t(
          "rules.copy4",
          "Ihr könnt jedoch versuchen, die Wahrheit möglichst vorteilhaft für euch auszulegen. Dabei entscheidet ihr selbst, welche Informationen ihr teilen wollt. Doch Vorsicht! Teilt ihr zu wenig Informationen mit der Gruppe, könnt ihr den Fall nicht lösen. Nur gemeinsam habt ihr Erfolg! Teilt ihr zu viel und zu früh, geratet ihr möglicherweise selbst unter Verdacht. Solltet ihr unabsichtlich gelogen haben, obwohl ihr nicht der Täter seid, dann versucht, euren Fehler schnellstmöglich zu korrigieren."
        )}
      </Paragraph>

      <Paragraph>
        <Trans ns="rules" i18nKey="rules.copy5">
          Ob ihr der Täter seid, erfahrt ihr in eurer{" "}
          <ConditionalInlineLink
            path={yourRoleRoute(t).fullPath}
            show={matchStatus >= EMatchStatus.CREATED}
          >
            Rollenbeschreibung
          </ConditionalInlineLink>
          . Abwesende Figuren kommen nicht als Täter in Betracht. Für sie gilt die Unschuldsvermutung.
        </Trans>
      </Paragraph>

      <CommonRulesText />
    </PagePadding>
  );
};
