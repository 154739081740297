import { animated, useTransition } from "react-spring";
import { canUseAnimation } from "../theme";
import { createPortal } from "react-dom";
import { usePortal } from "./use-portal";
import React, { useEffect } from "react";
import styled from "styled-components";

const ModalContainer = styled(animated.div)`
  bottom: 0;
  box-shadow: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`;

type Props = { isOpen: boolean; onDismiss: () => void };

export const Modal: React.FC<Props> = ({ children, isOpen, onDismiss }) => {
  const target = usePortal("cm-modal-container");
  const transitions = useTransition(isOpen, {
    native: true,
    from: { opacity: 1, transform: "translate3d(0,100%,0)" },
    enter: { opacity: 1, transform: "translate3d(0,0%,0)" },
    leave: { opacity: 1, transform: "translate3d(0,100%,0)" },
    immediate: !canUseAnimation,
  });

  useEffect(() => {
    const root = document.getElementById("root")!;

    if (isOpen) {
      document.body.classList.add("no-scroll");
      root.setAttribute("aria-hidden", "true");
    } else {
      document.body.classList.remove("no-scroll");
      root.removeAttribute("aria-hidden");
    }

    return function cleanUp() {
      document.body.classList.remove("no-scroll");
      root.removeAttribute("aria-hidden");
    };
  }, [isOpen]);

  const comp = transitions((props: any, item) =>
    item ? (
      <ModalContainer role="dialog" style={props}>
        {children}
      </ModalContainer>
    ) : null
  );

  return createPortal(comp, target);
};
