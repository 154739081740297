import { canUseAnimation } from "../theme";
import React from "react";

export const useHasRenderedClass = (className = "rendered") => {
  React.useEffect(() => {
    if (canUseAnimation) {
      return;
    }

    document.body.classList.add(className);

    return function cleanUp() {
      document.body.classList.remove(className);
    };
  }, [className]);
};
