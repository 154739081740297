import { Paragraph } from "../shared";
import React from "react";

export const RoundParagraph: React.FC = ({ children }) => {
  return (
    <Paragraph $$narrow style={{ position: "relative" }}>
      {children}
    </Paragraph>
  );
};
