import {
  characterReadsPrologueSelector,
  ERound,
  hostSelector,
  prologueReadBySelector,
} from "../game-data";
import { getRoundNumber, InlineButton, PagePadding, PageTitle, Paragraph } from "../shared";
import { prologueRoute } from "./routes";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import React, { Fragment } from "react";

type Props = { round: ERound };

export const RoundClosedNotice: React.FC<Props> = ({ round }) => {
  const { t } = useTranslation(["rounds"]);
  const host = useRecoilValue(hostSelector);
  const playerReadsPrologue = useRecoilValue(characterReadsPrologueSelector);
  const prologueReadBy = useRecoilValue(prologueReadBySelector);
  const roundNumber = getRoundNumber(round);

  return (
    <Fragment>
      <PagePadding>
        <PageTitle subtitle={t("closedRound.subtitle", "Am Spielabend")}>
          <Trans ns="rounds" i18nKey="closedRound.guest.title">
            Runde {{ roundNumber }} ist noch geschlossen
          </Trans>
        </PageTitle>

        <Paragraph>
          <Trans ns="rounds" i18nKey="closedRound.guest.copy">
            Damit du und die anderen Spieler Runde {{ roundNumber }} lesen könnt, musst der Gastgeber (
            {{ hostName: host?.playerName ?? "" }}) diese zunächst freischalten. Dies sollte erst am
            Spielabend geschehen. Du erhälst eine Nachricht, sobald die Runde freigegeben wurde.
          </Trans>
        </Paragraph>

        {round === ERound.FIRST && (
          <Paragraph>
            {playerReadsPrologue && (
              <React.Fragment>
                <Trans ns="rounds" i18nKey="closedRound.guest.copy2">
                  Bevor ihr Runde 1 lest, solltest du den{" "}
                  <InlineButton to={prologueRoute(t).fullPath ?? "/"}>Prolog</InlineButton> vorlesen.
                </Trans>
              </React.Fragment>
            )}

            {!playerReadsPrologue && (
              <Trans ns="rounds" i18nKey="closedRound.guest.copy3">
                Bevor ihr Runde 1 lest, sollte {{ prologueCharacterName: prologueReadBy?.name }} (
                {{ prologuePlayerName: prologueReadBy?.playerName }}) den Prolog vorlesen. Der Spieler
                findet den Prolog in seinem Menü.
              </Trans>
            )}
          </Paragraph>
        )}
      </PagePadding>
    </Fragment>
  );
};
