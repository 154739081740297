import { authStatusState, credentialsState } from "./auth.state";
import { configState } from "../config";
import { EAuthStatus } from "./auth-status.enum";
import { ECredentialsType } from "./credentials-type.enum";
import { EMatchStatus, matchStatusSelector } from "../game-data";
import { gameBookletData, gameData, matchData } from "../game-data/state";
import { hintsState } from "../rounds/state";
import { useCallback } from "react";
import { useConfirm } from "../shared";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

export const useLogout = (showConfirmDialogue = true) => {
  const confirm = useConfirm();
  const { t } = useTranslation(["common"]);
  const { localStorageKey } = useRecoilValue(configState);
  const [{ type }, setCredentials] = useRecoilState(credentialsState);
  const key = type === ECredentialsType.ACCESS_CODE ? "accessCode" : "token";
  const setAuthStatus = useSetRecoilState(authStatusState);
  const matchStatus = useRecoilValue(matchStatusSelector);
  const navigate = useNavigate();
  const resetGameData = useResetRecoilState(gameData);
  const resetMatchData = useResetRecoilState(matchData);
  const resetGameBookletData = useResetRecoilState(gameBookletData);
  const resetHints = useResetRecoilState(hintsState);

  const logOut = useCallback(async () => {
    const description =
      matchStatus >= EMatchStatus.CREATED && matchStatus < EMatchStatus.THIRD_ROUND_OPENED
        ? t(
            "common:signOut.alert.copy",
            "Möchtest du dich abmelden? Wir speichern dein aktuelles Spiel für maximal 30 Tage, sodass du dich wieder anmelden und weiterspielen kannst."
          )
        : t("common:signOut.alert.copy", "Möchtest du dich abmelden?");

    if (showConfirmDialogue) {
      const confirmed = await confirm({
        title: t("common:signOut.alert.title", "Abmelden"),
        description,
        confirmationText: t("common:confirmButton.label", "Okay"),
        cancellationText: t("common:cancelButton.label", "Abbrechen"),
      });

      if (!confirmed) {
        return;
      }
    }

    setAuthStatus(EAuthStatus.SIGNED_OUT);
    setCredentials({
      value: "",
      type: ECredentialsType.TOKEN,
    });

    localStorage.removeItem(`${localStorageKey}-${key}`);

    resetGameData();
    resetMatchData();
    resetGameBookletData();
    resetHints();

    navigate("/");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return logOut;
};
