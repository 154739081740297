import { incidentARoute, incidentBRoute } from "./routes";
import { Redirect, SubNav } from "../navigation";
import { Route, Routes } from "react-router";
import { useTranslation } from "react-i18next";
import React from "react";

export const IncidentsEntry: React.FC = () => {
  const { t } = useTranslation(["routes"]);
  const routes = [incidentARoute(t), incidentBRoute(t)];

  return (
    <React.Fragment>
      <SubNav routes={routes} />

      <Routes>
        <Route path="/" element={<Redirect to={incidentARoute(t).fullPath} />} />
        {routes.map((route) => (
          <Route key={route.fullPath} path={route.relativePath} element={route.component} />
        ))}
      </Routes>
    </React.Fragment>
  );
};
