import { EMatchStatus } from "../game-data";
import { getRelativeRoutePath } from "../shared";
import { IncidentA } from "./incident-a";
import { IncidentB } from "./incident-b";
import { IRoute } from "../navigation";
import { ReactComponent as IncidentAIcon } from "../assets/icon-incident-a.svg";
import { ReactComponent as IncidentBIcon } from "../assets/icon-incident-b.svg";
import { SvgIcon } from "@material-ui/core";
import React from "react";

export const incidentARoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => isHost && !!status && status >= EMatchStatus.SECOND_ROUND_OPENED,
  component: <IncidentA />,
  fullPath: t("routes:incidentA.path", "/ereignisse/ereignis-a"),
  hostOnly: true,
  icon: <SvgIcon component={IncidentAIcon} viewBox="0 0 512 512" />,
  id: "incident-a",
  isVisible: (isHost) => isHost,
  name: t("routes:incidentA.label", "Ereignis A"),
  relativePath: getRelativeRoutePath(t("routes:incidentA.path", "/ereignisse/ereignis-a")),
});

export const incidentBRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => isHost && !!status && status >= EMatchStatus.THIRD_ROUND_OPENED,
  component: <IncidentB />,
  fullPath: t("routes:incidentB.path", "/ereignisse/ereignis-b"),
  hostOnly: true,
  icon: <SvgIcon component={IncidentBIcon} viewBox="0 0 512 512" />,
  id: "incident-b",
  isVisible: (isHost) => isHost,
  name: t("routes:incidentB.label", "Ereignis B"),
  relativePath: getRelativeRoutePath(t("routes:incidentB.path", "/ereignisse/ereignis-b")),
});
