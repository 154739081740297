import { Box, Typography, useTheme } from "@material-ui/core";
import React, { CSSProperties } from "react";

type Props = {
  style?: CSSProperties;
  subtitle?: string;
};

export const Title: React.FC<Props> = ({ children, style, subtitle }) => {
  const theme = useTheme();

  return (
    <Typography variant="h1" component="h1" color="primary" style={style}>
      {subtitle && (
        <Box sx={{ mb: 0.5 }}>
          <Typography style={{ color: theme.palette.text.primary }} variant="subtitle2">
            {subtitle}
          </Typography>
        </Box>
      )}
      {children}
    </Typography>
  );
};
