import { Box } from "@material-ui/core";
import {
  EMatchStatus,
  isHostSelector,
  matchData,
  matchStatusSelector,
  solutionSelector,
} from "../game-data";
import { hintsState } from "./state";
import {
  PagePadding,
  PageTitle,
  PrimaryButton,
  TechnicalHint,
  TextMarkdown,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { UnlockSolution } from "./unlock-solution";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { WS } from "../websocket";
import React from "react";
export const Solution: React.FC = () => {
  useHasRenderedClass();
  const { hostSolutionWarningRead } = useRecoilValue(hintsState);
  const { t } = useTranslation(["rounds", "common"]);
  const isHost = useRecoilValue(isHostSelector);
  const matchStatus = useRecoilValue(matchStatusSelector);
  const solution = useRecoilValue(solutionSelector);
  const setMatchData = useSetRecoilState(matchData);

  const shareSolution = () => {
    WS.send(JSON.stringify({ action: "shareSolution" }));
    setMatchData((currVal) => ({ ...currVal, status: EMatchStatus.SOLUTION_SHARED }));
  };

  if (isHost && !hostSolutionWarningRead) {
    return <UnlockSolution />;
  }

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle subtitle={t("common:pleaseReadAloud", "Bitte vorlesen")}>
          {t("solution.title", "Aufklärung")}
        </PageTitle>

        <TextMarkdown>{solution}</TextMarkdown>

        {isHost && matchStatus < EMatchStatus.SOLUTION_SHARED && (
          <React.Fragment>
            <Box sx={{ mt: 5 }}>
              <PrimaryButton data-test-id="host-share-solution-btn" onClick={shareSolution}>
                {t("solution.button.label", "Aufklärung für alle freischalten")}
              </PrimaryButton>
            </Box>

            <Box sx={{ mt: 2 }}>
              <TechnicalHint>
                {t(
                  "solution.hint",
                  "Wenn du die Aufklärung freischaltest, können alle Spieler diese nochmal in Ruhe nachlesen."
                )}
              </TechnicalHint>
            </Box>
          </React.Fragment>
        )}
      </WidthWrapper>
    </PagePadding>
  );
};
