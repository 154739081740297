import { useMediaQuery, useTheme } from "@material-ui/core";
import { useViewportSize } from ".";

export const useFabOffset = () => {
  const { width } = useViewportSize();
  const theme = useTheme();
  const isAtLeastDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  if (isAtLeastDesktop) {
    const min = parseInt(theme.spacing(2).replace("px", ""));
    const bp = theme.breakpoints.values.lg;
    const diff = (width - bp) / 2;
    return Math.max(min, diff);
  }

  return theme.spacing(2);
};
