import { backgroundRoute, circleOfSuspectsRoute, yourRoleRoute } from "./routes";
import { Redirect, SubNav } from "../navigation";
import { Route, Routes } from "react-router";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

export const BackgroundEntry: React.FC = () => {
  const { t } = useTranslation(["routes"]);
  const routes = [yourRoleRoute(t), circleOfSuspectsRoute(t), backgroundRoute(t)];

  return (
    <Fragment>
      <SubNav routes={routes} />

      <Routes>
        <Route path="/" element={<Redirect to={yourRoleRoute(t).fullPath} />} />
        {routes.map((route) => (
          <Route key={route.fullPath} path={route.relativePath} element={route.component} />
        ))}
      </Routes>
    </Fragment>
  );
};
