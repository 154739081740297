import { IMarginalContext, MarginalContext } from "./marginal-context";
import { Theme, useTheme } from "@material-ui/core";
import React, { Fragment, useContext } from "react";
import styled from "styled-components";

const TimeBtn = styled.a<{ theme: Theme }>`
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  padding: 0;
  text-decoration-skip-ink: auto;
  text-decoration-skip: ink;
  text-decoration: underline;
  text-underline-position: from-font;
  transition: ${(props) => props.theme.transitions.create("color", { duration: "short" })};

  &:focus {
    border: none;
    outline: none;
    text-decoration: none;
  }
`;

TimeBtn.displayName = "TimeBtn";

export const TimeLink: React.FC = ({ children }) => {
  const theme = useTheme();
  const { sliding, toggle } = useContext<IMarginalContext>(MarginalContext);

  if (sliding) {
    return (
      <TimeBtn onClick={toggle} theme={theme}>
        {children}
      </TimeBtn>
    );
  }

  return <Fragment>{children}</Fragment>;
};
