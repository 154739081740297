import { animated, useTransition } from "react-spring";
import { canUseAnimation } from "../theme";
import React from "react";
import styled from "styled-components";

const OverlayBase = styled.div`
  background: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`;

const AnimatedOverlayBase = animated(OverlayBase);

type Props = { isVisible: boolean; onClick?: () => void };

export const Overlay: React.FC<Props> = ({ isVisible, onClick }) => {
  const overlayTransitions = useTransition(isVisible, {
    native: true,
    from: { opacity: 0 },
    enter: { opacity: 0.45 },
    leave: { opacity: 0 },
    immediate: !canUseAnimation,
  });

  return (
    <React.Fragment>
      {overlayTransitions(
        (props, item) => item && <AnimatedOverlayBase onClick={onClick} style={props} />
      )}
    </React.Fragment>
  );
};
