import {
  authStatusState,
  connectionStatusState,
  connectionUrl,
  credentialsState,
  EConnectionStatus,
} from "./auth.state";
import { EAuthStatus } from "./auth-status.enum";
import { LoadingSpinner, useIsOnline, usePageVisibility } from "../shared";
import { OfflineNotice } from "./offline-notice";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { WS } from "../websocket";
import React, { Fragment, useEffect } from "react";

export const ConnectionWrapper: React.FC = ({ children }) => {
  const { isOffline } = useIsOnline();
  const isVisible = usePageVisibility();
  const [authStatus, setAuthStatus] = useRecoilState(authStatusState);
  const [connectionStatus, setConnectionStatus] = useRecoilState(connectionStatusState);
  const socketUrl = useRecoilValue(connectionUrl);
  const resetCredentials = useResetRecoilState(credentialsState);

  useEffect(() => {
    if (socketUrl && isVisible && authStatus === EAuthStatus.SIGNED_OUT) {
      /** initial connect, we set the url here in case of previously credentials */
      WS.connect(socketUrl);
    }

    const onOpen = () => {
      setAuthStatus(EAuthStatus.SIGNED_IN);
      setConnectionStatus(EConnectionStatus.CONNECTED);
    };

    const onError = () => {
      setConnectionStatus(EConnectionStatus.CLOSED);
      resetCredentials();
      WS.disconnect();
    };

    if (socketUrl) {
      WS.addEventListener("open", onOpen);
      WS.addEventListener("error", onError);
    }

    if (socketUrl && isVisible && authStatus === EAuthStatus.SIGNED_IN) {
      WS.reconnect();
      setConnectionStatus(EConnectionStatus.CONNECTING);
    }

    if (socketUrl && !isVisible) {
      WS.disconnect();
    }

    if (!socketUrl) {
      WS.disconnect();
    }

    return function cleanUp() {
      WS.removeEventListener("open", onOpen);
      WS.removeEventListener("error", onError);
    };
  }, [
    authStatus,
    isOffline,
    isVisible,
    resetCredentials,
    setAuthStatus,
    setConnectionStatus,
    socketUrl,
  ]);

  if (isOffline) {
    return <OfflineNotice />;
  }

  if (connectionStatus === EConnectionStatus.CONNECTING && authStatus === EAuthStatus.SIGNED_OUT) {
    return <LoadingSpinner />;
  }

  return <Fragment>{children}</Fragment>;
};
