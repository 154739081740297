import { characterNameSelector, isHostSelector, matchData } from "../game-data";
import { IRoute } from "./route.interface";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Theme, useTheme } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import React from "react";
import styled from "styled-components";

const ActiveLink = styled.span<{ theme: Theme }>`
  color: white;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  position: relative;
`;

const DisabledLink = styled.span<{ theme: Theme }>`
  color: white;
  cursor: disabled;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  opacity: 0.3;
`;

const Link = styled(RouterLink)<{ theme: Theme }>`
  color: var(--ion-color-tertiary);
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  opacity: 0.75;
  text-decoration: none;
  transition: ${(props) => props.theme.transitions.create()};

  &:hover {
    opacity: 1;
  }
`;

const ListItem = styled.li<{ theme: Theme }>`
  font-size: 18px;
  padding: 0 ${(props) => props.theme.spacing(2)};

  &:last-child {
    padding-right: 0;
  }
`;

type ConditionalNavLinkProps = { id?: string; label: string; routes: IRoute[] };
export const ConditionalNavLink: React.FC<ConditionalNavLinkProps> = ({ id, label, routes }) => {
  const { pathname } = useLocation();
  const characterName = useRecoilValue(characterNameSelector);
  const isHost = useRecoilValue(isHostSelector);
  const match = useRecoilValue(matchData);
  const theme = useTheme();

  const isEnabled = (route: IRoute) => {
    const { canAccess } = route;
    if (canAccess) {
      return canAccess(isHost, match, characterName);
    }

    return true;
  };

  const isRouteVisible = ({ isVisible }: IRoute) => isVisible?.(isHost, match, characterName) ?? true;
  const filter = (r: IRoute) => !r.hideInMainNav && isRouteVisible(r);

  const isActive = routes.find((r) => r.fullPath === pathname) !== undefined;
  const isOneVisible = routes.filter((r) => isRouteVisible(r)).length > 0;

  if (!isOneVisible) {
    return null;
  }

  const route = routes.find((r) => isEnabled(r) && filter(r));

  if (!route) {
    return (
      <ListItem theme={theme}>
        <DisabledLink data-test-id={id} theme={theme}>
          {label}
        </DisabledLink>
      </ListItem>
    );
  }

  return (
    <ListItem theme={theme}>
      {isActive ? (
        <ActiveLink data-test-id={id} theme={theme}>
          {label}
        </ActiveLink>
      ) : (
        <Link data-test-id={id} theme={theme} to={route.fullPath}>
          {label}
        </Link>
      )}
    </ListItem>
  );
};
