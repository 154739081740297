import { Box } from "@material-ui/core";
import { FullWidthAvatar } from "./full-width-avatar";
import {
  PagePadding,
  PageTitle,
  Paragraph,
  SubHeadline,
  TextMarkdown,
  useHasRenderedClass,
} from "../shared";
import { RoleHint } from "./role-hint";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { yourRoleSelector } from "../game-data";
import React from "react";

export const YourRole: React.FC = () => {
  useHasRenderedClass();
  const { t, i18n } = useTranslation(["yourRole", "common"]);

  const { age, description, gender, illustration, isPerpetrator, name, task } =
    useRecoilValue(yourRoleSelector);

  return (
    <React.Fragment>
      <FullWidthAvatar illustration={illustration} name={name} />
      <PagePadding>
        <PageTitle>
          {t("yourRole.title", "Deine Rolle:")}
          <br />
          {name} ({age}
          {["fr", "FR", "fr-FR"].includes(i18n.language) ? " ans" : ""})
        </PageTitle>

        <TextMarkdown>{description}</TextMarkdown>

        <Box sx={{ mt: 1 }}>
          <SubHeadline>{t("yourRole.task", "Deine Aufgabe")}</SubHeadline>
        </Box>

        <Paragraph>{task}</Paragraph>

        <RoleHint gender={gender} isPerpetrator={isPerpetrator} />
      </PagePadding>
    </React.Fragment>
  );
};
