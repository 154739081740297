import { Box } from "@material-ui/core";
import { characterNameSelector, hostSelector, isHostSelector, prologueSelector } from "../game-data";
import { firstRoundRoute } from "./routes";
import {
  GameHint,
  InlineButton,
  PagePadding,
  PageTitle,
  TextMarkdown,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import React from "react";

export const Prologue: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["rounds", "common"]);
  const characterName = useRecoilValue(characterNameSelector);
  const host = useRecoilValue(hostSelector);
  const isHost = useRecoilValue(isHostSelector);
  const prologue = useRecoilValue(prologueSelector);

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle subtitle={t("common:pleaseReadAloud", "Bitte vorlesen")}>
          {t("prologue.title", "Prolog")}
        </PageTitle>

        <TextMarkdown>{prologue}</TextMarkdown>

        <GameHint>
          <Trans ns="rounds" i18nKey="prologue.copy">
            Als {{ characterName }} liest du den anderen Spielern den Prolog vor. Achte darauf, dass ihr
            zuvor gemeinsam die Abschnitte Spielregeln, Spielablauf und Vorgeschichte gelesen habt.
          </Trans>
        </GameHint>
        <Box sx={{ mt: 2 }}>
          <GameHint>
            {isHost && (
              <Trans ns="rounds" i18nKey="prologue.copy2">
                Nachdem du den Prolog vorgelesen hast, kannst du{" "}
                <InlineButton to={firstRoundRoute(t).fullPath ?? "/"}>Runde&nbsp;1</InlineButton>{" "}
                freischalten.
              </Trans>
            )}

            {!isHost && (
              <Trans ns="rounds" i18nKey="prologue.copy3">
                Nachdem du den Prolog vorgelesen hast, gibt der Gastgeber (
                {{ hostPlayerName: host?.playerName ?? "" }}) Runde 1 frei.
              </Trans>
            )}
          </GameHint>
        </Box>
      </WidthWrapper>
    </PagePadding>
  );
};
