import { atom } from "recoil";

export const configState = atom({
  key: "configState",
  default: {
    baseUri: "",
    localStorageKey: "cm-online",
    protocol: "",
  },
});
