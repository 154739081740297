import { TFunction } from "i18next";

export const createConfig = (t: TFunction) => {
  const environment = process.env.REACT_APP_ENV;

  const config = {
    baseUri: "",
    localStorageKey: "cm-online",
    protocol: "",
  };

  switch (environment) {
    case "ci":
    case "test":
    case "development":
      config.baseUri = t("common:domain.api-dev", "online-api-dev.culinario-mortale.de");
      config.protocol = "wss://";
      break;

    case "production":
      config.baseUri = t("common:domain.api", "online-api.culinario-mortale.de");
      config.protocol = "wss://";
      break;

    default:
      break;
  }

  return config;
};
