import { Grow, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { TextButton } from "../shared";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

const useStyles = makeStyles(({ breakpoints, palette, shadows, typography }: Theme) => ({
  contentRoot: {
    ...typography.subtitle2,
    fontFamily: typography.fontFamily,
    boxShadow: shadows[4],
    [breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  variantSuccess: {
    backgroundColor: palette.success.main,
  },
  variantError: {
    backgroundColor: palette.error.main,
  },
  variantInfo: {
    backgroundColor: "black !important",
  },
  variantWarning: {
    backgroundColor: palette.warning.main,
  },
}));

export const NotificationsWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation(["common"]);
  const classes = useStyles();
  const notistackRef = React.createRef<any>();
  const onClickDismiss = (key: React.ReactText) => () => notistackRef.current.closeSnackbar(key);

  return (
    <Fragment>
      <SnackbarProvider
        action={(key) => (
          <TextButton style={{ color: "white" }} onClick={onClickDismiss(key)}>
            {t("common:okay")}
          </TextButton>
        )}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        autoHideDuration={3500}
        classes={classes}
        hideIconVariant
        maxSnack={1}
        preventDuplicate
        ref={notistackRef}
        style={{ minWidth: "90% !important" }}
        TransitionComponent={Grow as any}
      >
        {children}
      </SnackbarProvider>
    </Fragment>
  );
};
