import { Node, Parent } from "unist";
import { ReactMarkdownProps } from "react-markdown/src/ast-to-react";
import { RoundParagraph } from "./round-paragraph";
import { TimeLink } from "./time-link";
import { TimeProps, Time } from "./time";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import visit from "unist-util-visit";

const plugin = () => {
  const TIME_LINK_SHORT_CODE_RE = /\[Link(.*?)?\](?:(.+?)?\[\/Link\])?/gi;
  const TIME_VALUE_SHORT_CODE_RE = /\[Time(.*?)?\](?:(.+?)?\[\/Time\])?/gi;

  const extractText = (string: string, start: number, end: number) => {
    const startLine = string.slice(0, start).split("\n");
    const endLine = string.slice(0, end).split("\n");

    return {
      type: "text",
      value: string.slice(start, end),
      position: {
        start: {
          line: startLine.length,
          column: startLine[startLine.length - 1].length + 1,
        },
        end: {
          line: endLine.length,
          column: endLine[endLine.length - 1].length + 1,
        },
      },
    };
  };

  const hasWhiteSpace = (s: string) => {
    return /\s/g.test(s);
  };

  const parseShortCode = (innerShortCode: string) => {
    const trimmedInnerShortCode = innerShortCode.trim();

    if (!trimmedInnerShortCode)
      return {
        identifier: "",
        attributes: {},
      };

    // If no whitespace, then shortcode is just name with no attributes.
    if (!hasWhiteSpace(trimmedInnerShortCode)) {
      return { identifier: trimmedInnerShortCode, attributes: {} };
    }

    const splitShortCode = trimmedInnerShortCode.match(/^(\S+)\s(.*)/)?.slice(1);
    const shortCodeName = splitShortCode?.[0] ?? "";
    const attributeString = splitShortCode?.[1] ?? "";
    const attributes = parseShortCodeAttributes(attributeString);

    // If no attributes parsed, something went wrong - return nothing.
    if (!attributes) {
      return {
        identifier: "",
        attributes: {},
      };
    }

    return {
      identifier: shortCodeName,
      attributes: attributes,
    };
  };

  const parseShortCodeAttributes = (attributeString: string) => {
    const attributes: Record<string, any> = {};
    const attrMatch = attributeString.trim().match(/(?:[\w-]*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^}\s]+))/g);

    if (attrMatch) {
      attrMatch.forEach(function (item) {
        const split = item.split("=") ?? [];
        const key = (split.shift() ?? "").trim();
        const val = split
          .join("=")
          .trim()
          .replace(/^"(.*)"$/, "$1");

        attributes[key] = val;
      });
    }

    return attributes;
  };

  const walker = ({
    node,
    parent,
    position,
    regexp,
    shortCodeType,
  }: {
    node: any;
    parent: Parent | undefined;
    position: number;
    regexp: RegExp;
    shortCodeType: string;
  }) => {
    const definition = [];
    let lastIndex = 0;

    const value = String(node.value);
    const results = Array.from(value.matchAll(regexp));

    for (const result of results) {
      const fullShortCodeStr = result[0];
      const innerShortCodeStr = fullShortCodeStr.slice(1, -1);
      const parsedShortCode = parseShortCode(innerShortCodeStr);

      const ix = result.index ?? 0;

      if (result.index !== lastIndex) {
        definition.push(extractText(value, lastIndex, ix));
      }

      definition.push({
        ...parsedShortCode,
        children: [],
        raw: fullShortCodeStr,
        tagName: shortCodeType,
        type: "element",
      });

      lastIndex = ix + fullShortCodeStr.length;
    }

    if (lastIndex !== value.length) {
      const text = extractText(value, lastIndex, value.length);
      definition.push(text);
    }

    if (parent) {
      const last = parent.children.slice(position + 1);
      parent.children = parent.children.slice(0, position);
      parent.children = parent.children.concat(definition);
      parent.children = parent.children.concat(last);
    }
  };

  function transformer(tree: Node) {
    visit(tree, "text", (node, position, parent) =>
      walker({ node, parent, position, regexp: TIME_LINK_SHORT_CODE_RE, shortCodeType: "timeLink" })
    );

    visit(tree, "text", (node, position, parent) =>
      walker({ node, parent, position, regexp: TIME_VALUE_SHORT_CODE_RE, shortCodeType: "timeValue" })
    );
  }

  return transformer;
};

type MarkdownElementRendererProps<Attributes extends Record<string, any>> = ReactMarkdownProps & {
  node: { attributes: Attributes };
};

type TimeValueProps = MarkdownElementRendererProps<TimeProps>;

export const shortCodeConfig = {
  endBlock: "]",
  inlineMode: true,
  startBlock: "[",
};

const TimeValueShortCodeRenderer: React.FC<TimeValueProps> = ({ node }) => {
  return <Time {...node.attributes} />;
};
const TimeLinkShortCodeRenderer: React.FC<TimeValueProps> = ({ node }) => {
  return <TimeLink>{node.attributes.value}</TimeLink>;
};

export const RoundTitleMarkdown = styled(ReactMarkdown).attrs({ components: { p: React.Fragment } })``;

export const RoundTextMarkdown = styled(ReactMarkdown).attrs({
  components: {
    p: RoundParagraph,
    timeLink: TimeLinkShortCodeRenderer,
    timeValue: TimeValueShortCodeRenderer,
  },
  rehypePlugins: [[plugin]],
})``;
