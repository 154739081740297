import { Typography } from "@material-ui/core";
import styled from "styled-components";

type Props = { color?: string; $$narrow?: boolean };

export const GameHint = styled(Typography).attrs({
  color: "primary",
  variant: "body2",
})<Props>`
  max-width: ${(props: Props) =>
    props.$$narrow ? "var(--max-round-text-width)" : "var(--max-text-width)"};
  margin-left: auto;
  margin-right: auto;
`;
