import { BottomNav } from "./bottom-nav";
import { DrawerNav } from "./drawer-nav";
import { Header } from "./header";
import React from "react";

export const Main: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <DrawerNav />
      {children}
      <BottomNav />
    </React.Fragment>
  );
};
