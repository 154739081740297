import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { TextButton } from "../buttons/text-button";
import React from "react";
import styled from "styled-components";

export const Headline = styled(Typography).attrs((props) => ({
  component: "span",
  variant: "h5",
}))``;

type Props = {
  open: boolean;
  options: any;
  onCancel: any;
  onConfirm: any;
  onClose: any;
};

const DialogTitle = styled(MuiDialogTitle)<{ theme: Theme }>`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-style: normal;
  padding-bottom: ${(props) => props.theme.spacing(1)};
`;

export const ConfirmationDialogue: React.FC<Props> = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}) => {
  const theme = useTheme();
  const {
    title,
    description,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
  } = options;

  return (
    <Dialog data-test-id="confirm-dialog" fullWidth onClose={onClose} open={open} {...dialogProps}>
      {title && (
        <Box sx={{ mt: 1 }}>
          <DialogTitle theme={theme}>{title}</DialogTitle>
        </Box>
      )}
      {description && (
        <DialogContent>
          <Typography variant="caption">{description}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <TextButton data-test-id="confirm-modal-cancel" {...cancellationButtonProps} onClick={onCancel}>
          {cancellationText}
        </TextButton>

        <TextButton
          data-test-id="confirm-modal-confirm"
          color="primary"
          {...confirmationButtonProps}
          onClick={onConfirm}
        >
          {confirmationText}
        </TextButton>
      </DialogActions>
    </Dialog>
  );
};
