export class Event {
  public target: any;
  public type: string;
  constructor(type: string, target: any) {
    this.target = target;
    this.type = type;
  }
}

export class ErrorEvent extends Event {
  public message: string;
  public error: Error;
  constructor(error: Error, target: any) {
    super("error", target);
    this.message = error.message;
    this.error = error;
  }
}

export class CloseEvent extends Event {
  public code: number;
  public reason: string;
  public wasClean = true;
  constructor(code = 1000, reason = "", target: any) {
    super("close", target);
    this.code = code;
    this.reason = reason;
  }
}
export interface WebSocketEventMap {
  close: CloseEvent;
  error: ErrorEvent;
  message: MessageEvent;
  open: Event;
}

type MaybePromiseVoid = void | Promise<void>;

export interface WebSocketEventListenerMap {
  close: (event: CloseEvent) => MaybePromiseVoid | { handleEvent: (event: CloseEvent) => void };
  error: (event: ErrorEvent) => MaybePromiseVoid | { handleEvent: (event: ErrorEvent) => void };
  message: (event: MessageEvent) => MaybePromiseVoid | { handleEvent: (event: MessageEvent) => void };
  open: (event: Event) => MaybePromiseVoid | { handleEvent: (event: Event) => void };
}
