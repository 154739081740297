import { Box } from "@material-ui/core";
import {
  InlineButton,
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  TechnicalHint,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { privacyPolicyRoute, termsAndConditionsRoute } from "../legal";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import React from "react";

export const WizardIntroduction: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["matchAdmin", "common"]);
  const navigate = useNavigate();
  const startRoute = t(
    "matchAdmin:createMatch.hostData.step.path",
    "/vorbereitung/spiel-erstellen/deine-daten"
  );

  const startWizard = () => navigate(startRoute);

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle>{t("createMatch.title", "Neues Spiel erstellen")}</PageTitle>

        <Trans ns="matchAdmin" i18nKey="createMatch.copy">
          <Paragraph>
            Mit diesem Assistenten kannst du ein neues Spiel erstellen und deinen Gästen ihr „digitales
            Spielheft“ zukommen lassen.
          </Paragraph>

          <Paragraph>
            Wir erstellen für jeden Spieler einen individuellen Link, mit der Spieler seine geheimen
            Spielmaterialien abrufen kann. Achte darauf, diese Links nur an den jeweiligen Spieler bzw.
            die jeweilige Spielerin zu verteilen.
          </Paragraph>
        </Trans>

        <Box sx={{ mt: 4 }}>
          <PrimaryButton data-test-id="start-wizard-btn" onClick={startWizard}>
            {t("createMatch.buttonLabel", "Neues Spiel erstellen")}
          </PrimaryButton>
        </Box>

        <Box sx={{ mt: 2 }}>
          <TechnicalHint>
            <Trans ns="matchAdmin" i18nKey="createMatch.legalNotice">
              Die Nutzung des Spiels unterliegt unseren{" "}
              <InlineButton to={termsAndConditionsRoute(t).fullPath ?? "/"}>
                Allgemeinen Geschäftsbedingungen
              </InlineButton>{" "}
              und unserer{" "}
              <InlineButton to={privacyPolicyRoute(t).fullPath ?? "/"}>
                Datenschutzerklärung
              </InlineButton>
              .
            </Trans>
          </TechnicalHint>
        </Box>
      </WidthWrapper>
    </PagePadding>
  );
};
