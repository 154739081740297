import { EMatchStatus } from "../game-data";
import { GameRules } from "./game-rules";
import { getRelativeRoutePath } from "../shared";
import { IRoute } from "../navigation";
import { ReactComponent as GameRulesIcon } from "../assets/icon-game-rules.svg";
import { ReactComponent as StructureOfTheGameIcon } from "../assets/icon-structure-of-the-game.svg";
import { ReactComponent as WelcomeIcon } from "../assets/icon-welcome.svg";
import { StructureOfTheGame } from "./structure-of-the-game";
import { SvgIcon } from "@material-ui/core";
import { Welcome } from "./welcome";
import React from "react";

export const welcomeRoute = (t: Function): IRoute => ({
  canAccess: () => true,
  component: <Welcome />,
  icon: <SvgIcon component={WelcomeIcon} viewBox="0 0 512 512" />,
  id: "welcome",
  name: t("routes:welcome.label", "Willkommen"),
  fullPath: t("routes:welcome.path", "/vorbereitung/willkommen"),
  relativePath: getRelativeRoutePath(t("routes:welcome.path", "/vorbereitung/willkommen")),
});

export const gameRulesRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => isHost || (!!status && status >= EMatchStatus.CREATED),
  component: <GameRules />,
  icon: <SvgIcon component={GameRulesIcon} viewBox="0 0 512 512" />,
  id: "game-rules",
  name: t("routes:gameRules.label", "Die Spielregeln"),
  fullPath: t("routes:gameRules.path", "/vorbereitung/spielregeln"),
  relativePath: getRelativeRoutePath(t("routes:gameRules.path", "/vorbereitung/spielregeln")),
});

export const structureOfTheGameRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => isHost || (!!status && status >= EMatchStatus.CREATED),
  component: <StructureOfTheGame />,
  icon: <SvgIcon component={StructureOfTheGameIcon} viewBox="0 0 512 512" />,
  id: "structure-of-the-game",
  name: t("routes:structureOfTheGame.label", "Der Spielablauf"),
  fullPath: t("routes:structureOfTheGame.path", "/vorbereitung/spielablauf"),
  relativePath: getRelativeRoutePath(t("routes:structureOfTheGame.path", "/vorbereitung/spielablauf")),
});
