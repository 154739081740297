import {
  Actions,
  CharacterCardContainer,
  CharacterCardHeader,
  CharacterContent,
  FormikTextInput,
  TextButton,
} from "../shared";
import { Field } from "formik";
import { ICreateMatchFormState } from "./form-state.interface";
import { IGameCharacter } from "../game-data";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useMediaQuery, useTheme } from "@material-ui/core";
import camelCase from "camelcase";
import React, { useState } from "react";

type Props = { character: IGameCharacter; optional?: boolean };

export const CharacterCard: React.FC<Props> = ({ character, optional = false }) => {
  const { age, description, illustration, name } = character;
  const { errors, setFieldValue, values } = useFormikContext<ICreateMatchFormState>();
  const { t } = useTranslation(["matchAdmin", "common"]);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));

  const [initialValues, setInitialValues] = useState({ playerName: "" });
  const [showForm, setShowForm] = useState(false);
  const btnSize = isTablet ? "medium" : "small";
  const cCase = camelCase(name);
  const nameFieldName = `assignedCharacters.${cCase}.playerName`;
  const isHostFieldName = `assignedCharacters.${cCase}.playedByHost`;

  if (Object.keys(values?.assignedCharacters).length === 0) {
    return null;
  }

  const { playedByHost, playerName } = values.assignedCharacters[cCase];
  const cardErrors: object = errors?.assignedCharacters?.[cCase] ?? {};
  const hostAssigned =
    playedByHost || Object.values(values.assignedCharacters).filter((c) => c.playedByHost).length > 0;

  const toggleForm = () => {
    if (showForm) {
      setShowForm(false);
    } else {
      initForm();
    }
  };

  const initForm = () => {
    setShowForm(true);
    setInitialValues({ playerName: playerName ?? "" });
  };

  const cancelForm = () => {
    setFieldValue(nameFieldName, initialValues.playerName);
    setShowForm(false);
  };

  const assignHost = () => {
    setFieldValue(isHostFieldName, true);
    setFieldValue(nameFieldName, values.hostName);
  };

  const unassignHost = () => {
    setFieldValue(isHostFieldName, false);
    setFieldValue(nameFieldName, "");
  };

  const getSubHeadline = (): string => {
    if (optional) {
      return t("characterCard.subtitle.absent", "Abwesend");
    }

    if (playedByHost) {
      return t("characterCard.subtitle.yourRole", "Deine Rolle");
    }

    if (playerName) {
      return playerName;
    }

    if (!playerName && !showForm) {
      return t("characterCard.subtitle.unassigned", "Nicht zugewiesen");
    }

    if (!playerName && showForm) {
      return t("characterCard.subtitle.assigned", "Rolle zuweisen");
    }

    return "";
  };

  const subHeadline = getSubHeadline();

  return (
    <CharacterCardContainer style={{ opacity: optional ? 0.6 : 1 }}>
      <CharacterCardHeader age={age} illustration={illustration} name={name} subHeadline={subHeadline} />
      <CharacterContent>
        {!showForm && description}
        {showForm && !optional && (
          <React.Fragment>
            <p>
              <Trans ns="matchAdmin" i18nKey="characterCard.form.copy">
                Wer soll {{ characterName: character.name }} spielen?
              </Trans>
            </p>
            <Field
              autoFocus={true}
              component={FormikTextInput}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={t("characterCard.form.input.label", "Vorname des Spielers")}
              margin="normal"
              name={nameFieldName}
              placeholder={t("characterCard.form.input.placeholder", "Toni Mustergültig")}
              theme={theme}
              variant="outlined"
            />
          </React.Fragment>
        )}
      </CharacterContent>
      <Actions>
        {optional && (
          <CharacterContent style={{ paddingBottom: theme.spacing(2), width: "100%" }}>
            {t(
              "characterCard.actions.unassignableHint",
              "Diese Figur wird bei deiner Spieleranzahl nicht verteilt."
            )}
          </CharacterContent>
        )}

        {!optional && !showForm && !hostAssigned && !playedByHost && (
          <TextButton
            onClick={assignHost}
            size={btnSize}
            style={{ marginRight: theme.spacing(2) }}
            type="button"
          >
            {t("characterCard.actions.assignToHost", "Selbst spielen")}
          </TextButton>
        )}

        {!optional && !showForm && playedByHost && (
          <TextButton onClick={unassignHost} size={btnSize} type="button">
            {t("characterCard.actions.unassignHost", "Nicht selbst spielen")}
          </TextButton>
        )}

        {!optional && showForm && (
          <TextButton
            onClick={cancelForm}
            size={btnSize}
            style={{ marginRight: theme.spacing(2) }}
            type="button"
          >
            {t("common:cancelButton.label", "Abbrechen")}
          </TextButton>
        )}

        {!optional && !playedByHost && (
          <TextButton
            disabled={showForm && Object.keys(cardErrors).length > 0}
            onClick={toggleForm}
            size={btnSize}
            type="button"
          >
            {showForm && t("common:saveButton.label", "Speichern")}
            {!showForm && playerName && t("common:editButton.label", "Bearbeiten")}
            {!showForm && !playerName && t("assignButton.label", "Zuweisen")}
          </TextButton>
        )}
      </Actions>
    </CharacterCardContainer>
  );
};
