import { CommonRulesText } from "./common";
import { credentialsType, ECredentialsType } from "../authentication";
import { PagePadding, useHasRenderedClass } from "../shared";
import { useRecoilValue } from "recoil";
import { WelcomeGuest } from "./welcome-guest";
import { WelcomeHost } from "./welcome-host";
import React from "react";

export const Welcome: React.FC = () => {
  useHasRenderedClass();
  const type = useRecoilValue(credentialsType);

  return (
    <PagePadding>
      {type === ECredentialsType.ACCESS_CODE ? <WelcomeHost /> : <WelcomeGuest />}
      <CommonRulesText />
    </PagePadding>
  );
};
