import { Theme, Typography, useTheme } from "@material-ui/core";
import React, { CSSProperties } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  line-height: 1;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-text-width);
  width: 100%;
`;

const Text = styled(Typography).attrs({ variant: "h4" })<{ theme: Theme }>`
  color: white;
  display: inline-block;
  margin-bottom: calc(var(--ion-margin) * 0.375);
  margin-top: 1em;
  padding: 1px 6px;
  position: relative;

  &:after {
    background-color: ${(props) => props.theme.palette.common.black};
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0px;
    width: 100%;
  }
`;

type Props = { style?: CSSProperties };

export const SubHeadline: React.FC<Props> = ({ children, style }) => {
  const theme = useTheme();

  return (
    <Container>
      <Text style={style} theme={theme}>
        <span style={{ position: "relative", zIndex: 10 }}>{children}</span>
      </Text>
    </Container>
  );
};
