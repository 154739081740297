import { accusationRoute } from "./routes";
import { ConditionalInlineLink, GameHint, InlineButton } from "../shared";
import {
  EMatchStatus,
  ERound,
  isHostSelector,
  matchStatusSelector,
  roundsTimeSelector,
} from "../game-data";
import { secondRoundRoute, thirdRoundRoute } from "./routes";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import React from "react";

type Props = { round: ERound };

export const RoundHint: React.FC<Props> = ({ round }) => {
  const { t } = useTranslation(["incidents", "routes"]);
  const isHost = useRecoilValue(isHostSelector);
  const rounds = useRecoilValue(roundsTimeSelector);
  const matchStatus = useRecoilValue(matchStatusSelector);
  const timeStart = rounds?.[round]?.start ?? "";
  const timeEnd = rounds?.[round]?.end ?? "";
  const incidentRoute =
    round === ERound.SECOND
      ? t("routes:incidentA.path", "/ereignisse/ereignis-a")
      : t("routes:incidentB.path", "/ereignisse/ereignis-b");

  const getContent = (round: ERound) => {
    if (round === ERound.FIRST) {
      return (
        <Trans ns="rounds" i18nKey="hint.firstRound">
          Diskutiert nach dem Lesen gemeinsam den Zeitabschnitt zwischen {{ timeStart }} und{" "}
          {{ timeEnd }}. Rundendauer: ca. 30&nbsp;–&nbsp;60&nbsp;Minuten. Hinweise über den weiteren
          Tatverlauf bekommen alle Spieler erst in der folgenden{" "}
          <ConditionalInlineLink
            path={secondRoundRoute(t).fullPath ?? "/"}
            show={isHost || matchStatus >= EMatchStatus.SECOND_ROUND_OPENED}
          >
            Runde
          </ConditionalInlineLink>
          .
        </Trans>
      );
    }

    if (round === ERound.SECOND) {
      if (isHost) {
        return (
          <Trans ns="rounds" i18nKey="hint.secondRoundHost">
            Du liest <InlineButton to={incidentRoute}>Ereignis A</InlineButton> vor. Diskutiert
            anschließend den Zeitabschnitt ­zwischen {{ timeStart }} und {{ timeEnd }}. Runden­dauer: ca.
            30&nbsp;–&nbsp;60&nbsp;Minuten. Hinweise über den weiteren Tatverlauf bekommen alle Spieler
            erst in der folgenden{" "}
            <ConditionalInlineLink
              path={thirdRoundRoute(t).fullPath ?? "/"}
              show={isHost || matchStatus >= EMatchStatus.THIRD_ROUND_OPENED}
            >
              Runde
            </ConditionalInlineLink>
            .
          </Trans>
        );
      }

      return (
        <Trans ns="rounds" i18nKey="hint.secondRound">
          Der Gastgeber liest Ereignis A vor. Diskutiert anschließend den Zeitabschnitt ­zwischen{" "}
          {{ timeStart }} und {{ timeEnd }}. Runden­dauer: ca. 30&nbsp;–&nbsp;60&nbsp;Minuten. Hinweise
          über den weiteren Tatverlauf bekommen alle Spieler erst in der folgenden
          <ConditionalInlineLink
            path={thirdRoundRoute(t).fullPath ?? "/"}
            show={matchStatus >= EMatchStatus.THIRD_ROUND_OPENED}
          >
            Runde
          </ConditionalInlineLink>
          .
        </Trans>
      );
    }

    if (round === ERound.THIRD) {
      if (isHost) {
        return (
          <Trans ns="rounds" i18nKey="hint.thirdRoundHost">
            Du liest <InlineButton to={incidentRoute}>Ereignis B</InlineButton> vor. Diskutiert
            anschließend den Zeitabschnitt zwischen {{ timeStart }} und {{ timeEnd }}. Rundendauer: ca.
            30&nbsp;–&nbsp;60&nbsp;Minuten. Wenn sich eure Diskussionen im Kreis drehen, könnt ihr zur{" "}
            <ConditionalInlineLink
              path={accusationRoute(t).fullPath ?? "/"}
              show={isHost || matchStatus >= EMatchStatus.ACCUSATION_OPENED}
            >
              Anklage & Verteidigung
            </ConditionalInlineLink>{" "}
            übergehen.
          </Trans>
        );
      }

      return (
        <Trans ns="rounds" i18nKey="hint.thirdRound">
          Der Gastgeber liest <InlineButton to={incidentRoute}>Ereignis B</InlineButton> vor. Diskutiert
          anschließend den Zeitabschnitt zwischen {{ timeStart }} und {{ timeEnd }}. Rundendauer: ca.
          30&nbsp;–&nbsp;60&nbsp;Minuten. Wenn sich eure Diskussionen im Kreis drehen, könnt ihr zur{" "}
          <ConditionalInlineLink
            path={accusationRoute(t).fullPath ?? "/"}
            show={isHost || matchStatus >= EMatchStatus.ACCUSATION_OPENED}
          >
            Anklage & Verteidigung
          </ConditionalInlineLink>{" "}
          übergehen.
        </Trans>
      );
    }
  };

  return <GameHint $$narrow>{getContent(round)}</GameHint>;
};
