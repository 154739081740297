import { useEffect, useState } from "react";
import throttle from "lodash/throttle";

export const useViewportSize = (throttleFreq = 300) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    const throttledHandleResize = throttle(handleResize, throttleFreq, { trailing: true });

    window.addEventListener("resize", throttledHandleResize);

    return function cleanUp() {
      window.removeEventListener("resize", throttledHandleResize);
    };
  }, [throttleFreq]);

  return { height, width };
};
