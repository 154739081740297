import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ISharingAction } from "./match-character-card";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import React from "react";

type Props = {
  anchor: HTMLButtonElement | null;
  name: string;
  onClose: () => void;
  open: boolean;
  sharingActions: ISharingAction[];
};

export const SharingList: React.FC<Props> = ({ anchor, name, onClose, open, sharingActions }) => {
  const { t } = useTranslation(["matchAdmin", "common"]);
  const { closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const isAtLeastTablet = useMediaQuery(theme.breakpoints.up("sm"));

  const list = (
    <List aria-label="sharing" component="nav">
      {sharingActions.map((sa, ix) => (
        <ListItem button key={`${name}-sharing-action-${ix}`} onClick={sa.handler}>
          <ListItemIcon>{sa.icon}</ListItemIcon>
          <Typography variant={isAtLeastTablet ? "caption" : "subtitle1"}>{sa.text}</Typography>
        </ListItem>
      ))}
    </List>
  );

  if (isAtLeastTablet) {
    return (
      <Popover
        elevation={8}
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {list}
      </Popover>
    );
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      disableDiscovery={true}
      onClose={onClose}
      onOpen={() => closeSnackbar()}
      open={open}
    >
      <Box sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
        <Typography variant="subtitle1" color="textSecondary">
          {t("characterCard.sharing.header", "Zugang für {{name}} teilen", { name: name })}
        </Typography>
      </Box>
      {list}
    </SwipeableDrawer>
  );
};
