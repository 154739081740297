import { useEffect, useState } from "react";

export const usePageBottom = (threshold = 0) => {
  const [bottom, setBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isBottom =
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - threshold;

      setBottom(isBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return function cleanUp() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold]);

  return bottom;
};
