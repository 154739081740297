import { animated, useSpring } from "react-spring";
import { Box, Theme, useTheme } from "@material-ui/core";
import { canUseAnimation } from "../theme";
import { ECharacterGender, ERound, genderSelector, isPerpetratorSelector } from "../game-data";
import { getRoundNumber, Modal, PrimaryButton } from "../shared";
import { ReactComponent as PerpetratorHintFemaleDe } from "../assets/round-circle-perpetrator-female-de.svg";
import { ReactComponent as PerpetratorHintFemaleFr } from "../assets/round-circle-perpetrator-female-fr.svg";
import { ReactComponent as PerpetratorHintGb } from "../assets/round-circle-perpetrator-gb.svg";
import { ReactComponent as PerpetratorHintJp } from "../assets/round-circle-perpetrator-jp.svg";
import { ReactComponent as PerpetratorHintMaleDe } from "../assets/round-circle-perpetrator-male-de.svg";
import { ReactComponent as PerpetratorHintMaleFr } from "../assets/round-circle-perpetrator-male-fr.svg";
import { ReactComponent as SuspectHintDe } from "../assets/round-circle-suspect-de.svg";
import { ReactComponent as SuspectHintFr } from "../assets/round-circle-suspect-fr.svg";
import { ReactComponent as SuspectHintGb } from "../assets/round-circle-suspect-gb.svg";
import { ReactComponent as SuspectHintJp } from "../assets/round-circle-suspect-jp.svg";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { visuallyHidden } from "@material-ui/utils";
import React, { useState } from "react";
import styled from "styled-components";

const ContentContainer = styled.div<{ theme: Theme }>`
  align-items: center;
  background-color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const NumberContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 275px;
  width: 66%;
`;

const RoundHeading = styled(animated.h1)<{ theme: Theme }>`
  color: white;
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 2.35rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.3px;
  margin-top: ${(props) => props.theme.spacing(3)};
  padding: 0;
  text-transform: uppercase;
`;

const CircleText: React.FC<{
  circleStyle?: any;
  strokeColor?: string;
  strokeDasharray?: number;
  textStyle?: React.CSSProperties;
}> = ({ children, circleStyle = {}, strokeColor = "white", strokeDasharray = 3 }) => {
  return (
    <svg aria-hidden="true" viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>
        <clipPath id="clipPath">
          <circle r="49" cx="50" cy="50" />
        </clipPath>
      </defs>
      <animated.circle
        cx="50"
        cy="50"
        r="48"
        stroke={strokeColor}
        strokeDasharray={strokeDasharray}
        style={circleStyle}
      />

      {children}
    </svg>
  );
};

const ClippingGroup = styled.g`
  clip-path: url(#clipPath);
  -webkit-clip-path: url(#clipPath);
`;

const AnimatedButton = animated(PrimaryButton);

type Props = { isOpen: boolean; round: ERound; onDismiss: () => void };

export const RoundCover: React.FC<Props> = ({ isOpen, onDismiss, round }) => {
  const { i18n, t } = useTranslation(["rounds", "common"]);
  const theme = useTheme();
  const [isHintVisible, setIsHintVisible] = useState(false);
  const handleBtnClick = () => (isHintVisible ? onDismiss() : setIsHintVisible(true));

  const isPerpetrator = useRecoilValue(isPerpetratorSelector);
  const gender = useRecoilValue(genderSelector);

  const isLanguage = (lng: string): boolean => {
    return i18n.language.toLowerCase().startsWith(lng);
  };

  const getPerpetratorHint = () => {
    if (isLanguage("en") || isLanguage("gb") || isLanguage("us")) {
      return <PerpetratorHintGb />;
    }

    if (isLanguage("ja") || isLanguage("jp")) {
      return <PerpetratorHintJp />;
    }

    if (isLanguage("de")) {
      return gender === ECharacterGender.FEMALE ? (
        <PerpetratorHintFemaleDe />
      ) : (
        <PerpetratorHintMaleDe />
      );
    }

    if (isLanguage("fr")) {
      return gender === ECharacterGender.FEMALE ? (
        <PerpetratorHintFemaleFr />
      ) : (
        <PerpetratorHintMaleFr />
      );
    }
  };

  const getSuspectHint = () => {
    if (isLanguage("en") || isLanguage("gb") || isLanguage("us")) {
      return <SuspectHintGb aria-label={t("suspectHint.generic", "")} />;
    }

    if (isLanguage("ja") || isLanguage("jp")) {
      return <SuspectHintJp aria-label={t("suspectHint.generic", "")} />;
    }

    if (isLanguage("de")) {
      return <SuspectHintDe aria-label={t("suspectHint.generic", "")} />;
    }

    if (isLanguage("fr")) {
      return <SuspectHintFr aria-label={t("suspectHint.generic", "")} />;
    }
  };

  const getNumberPath = (round: ERound): string => {
    switch (round) {
      case ERound.FIRST:
        return "M42.7,78.5h18.3V17.2H46.6c-0.5,10.1-9.3,13.5-18.3,13.3v12.7h14.4V78.5z";

      case ERound.SECOND:
        return "M41.4,43c-0.2-7.5,2.2-12.6,9.3-12.6c4.1,0,7.4,1.9,7.4,7.5c0,4.5-5.1,7-8.3,8.9C42,51.6,33.2,56.2,28,63.4c-3,4.1-4.8,9.3-4.4,15.2h53.2v-15H49v-0.1c11.6-7.1,26.9-12,26.9-27.7c0-13.1-10.1-19.3-25-19.3c-16.5,0-27,9.7-26,26.5H41.4z";

      case ERound.THIRD:
        return `M41.9,37.6c-0.2-5.1,3.7-7.7,8.5-7.7c3.6,0,8,1.5,8,5.4c0,4.8-4.7,5.6-8.4,5.6c-2.4,0-3.8-0.3-5.1-0.4v12
		c1.3-0.2,2.6-0.4,5.1-0.4c4.1,0,8.2,1,8.2,6.3c0,5-3.2,6.9-8.2,6.9c-3.1,0-5.6-0.7-7.3-2.7c-1.7-1.6-2.5-4.1-2.4-7.3H23.5
		c-0.4,4.6-0.4,24,27.3,24c20.1,0,25.8-11.8,25.8-19.3c0-13.7-10.1-13.7-10.1-14.4v-0.2c0-0.3,8.1-0.3,8.1-13.1
		c0-5.2-5.9-15.8-23.7-15.8c-6.9,0-13.2,1.7-17.9,5.1c-4.7,3.5-7.6,8.8-7.7,16.1H41.9z`;

      default:
        return "";
    }
  };

  const btnAnim = useSpring({
    to: {
      transform: `translateY(${isHintVisible ? "-160" : 0}%)`,
    },
    immediate: !canUseAnimation,
  });
  const roundHeadingAnim = useSpring({
    to: { transform: `scale(${isHintVisible ? 0 : 1})` },
    immediate: !canUseAnimation,
  });

  const circleAnim: any = useSpring({
    to: {
      fill: isHintVisible ? "black" : "white",
      strokeWidth: isHintVisible ? 1 : 0,
    },
    immediate: !canUseAnimation,
  });

  const roundNumberAnim: any = useSpring({
    to: {
      transform: `scale(${isHintVisible ? 0.3 : 1})`,
      opacity: isHintVisible ? 0 : 1,
    },
    immediate: !canUseAnimation,
  });

  const hintAnim: any = useSpring({
    to: {
      transform: `scale(${isHintVisible ? 1 : 0.3})`,
      opacity: isHintVisible ? 1 : 0,
    },
    immediate: !canUseAnimation,
  });

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ContentContainer data-test-id="round-cover" theme={theme}>
        <NumberContainer>
          <CircleText
            aria-hidden="true"
            circleStyle={circleAnim}
            key="roundNumber"
            strokeColor="white"
            strokeDasharray={3}
          >
            <ClippingGroup>
              <animated.path
                d={getNumberPath(round)}
                style={{ ...roundNumberAnim, transformOrigin: "center" }}
              />
              <animated.g style={{ ...hintAnim, transformOrigin: "center" }}>
                {isPerpetrator ? getPerpetratorHint() : getSuspectHint()}
              </animated.g>
            </ClippingGroup>
          </CircleText>

          <RoundHeading
            aria-hidden={isHintVisible}
            key="roundHeading"
            style={roundHeadingAnim}
            theme={theme}
          >
            {t("common:round", "Runde")} <span style={visuallyHidden}>{getRoundNumber(round)}</span>
          </RoundHeading>

          <AnimatedButton
            data-test-id={`round-cover-btn`}
            key="roundButton"
            onClick={handleBtnClick}
            size="large"
            style={{
              ...btnAnim,
              background: isHintVisible ? "white" : "black",
              color: isHintVisible ? "black" : "white",
              marginTop: 0,
              width: "unset",
            }}
          >
            {isHintVisible
              ? t("hintConfirmButton.label", "Los geht's")
              : t("common:startButton.label", "Starten")}
          </AnimatedButton>

          {isHintVisible && (
            <Box sx={visuallyHidden}>
              {isPerpetrator
                ? gender === ECharacterGender.FEMALE
                  ? t(
                      "perpetratorHint.female",
                      "Als Täterin bist du die einzige Person, die lügen darf. Versuche jedoch, so nah wie möglich an der Wahrheit zu bleiben, um keinen Verdacht zu erregen."
                    )
                  : t(
                      "perpetratorHint.male",
                      "Als Täter bist du die einzige Person, die lügen darf. Versuche jedoch, so nah wie möglich an der Wahrheit zu bleiben, um keinen Verdacht zu erregen."
                    )
                : t(
                    "suspectHint.generic",
                    "Versuche die Wahrheit möglichst vorteilhaft für dich auszulegen. Du darfst jedoch nicht lügen. Informationen über Mitspieler und Tathergang solltest du weitergeben."
                  )}
            </Box>
          )}
        </NumberContainer>
      </ContentContainer>
    </Modal>
  );
};
