import { EIncident, ERound, roundsTimeSelector } from "../game-data";
import { GameHint, getRoundNumber, InlineButton } from "../shared";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import React from "react";

type Props = { incident: EIncident; modal?: () => void };

const IncidentLink: React.FC<Props> = ({ incident, modal }) => {
  const { t } = useTranslation(["incidents"]);
  const round = incident === EIncident.A ? ERound.SECOND : ERound.THIRD;
  const roundNumber = getRoundNumber(round);
  const roundRoute =
    incident === EIncident.A
      ? t("routes:secondRound.path", "/spiel/runde-2")
      : t("routes:thirdRound.path", "/spiel/runde-3");
  const label = t("round", "Runde") + " " + String(roundNumber);

  return modal ? (
    <InlineButton onClick={modal}>{label}</InlineButton>
  ) : (
    <InlineButton to={roundRoute ?? "/"}>{label}</InlineButton>
  );
};

export const IncidentHint: React.FC<Props> = ({ incident, modal }) => {
  const round = incident === EIncident.A ? ERound.SECOND : ERound.THIRD;
  const rounds = useRecoilValue(roundsTimeSelector);
  const timeStart = rounds?.[round]?.start ?? "";
  const timeEnd = rounds?.[round]?.end ?? "";

  return (
    <GameHint>
      <Trans ns="incidents" i18nKey="hint">
        Lies allen Mitspielern das Ereignis vor. Diskutiert anschließend in{" "}
        <IncidentLink incident={incident} modal={modal} /> den Zeitabschnitt zwischen {{ timeStart }} und{" "}
        {{ timeEnd }}.
      </Trans>
    </GameHint>
  );
};
