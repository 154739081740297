import { TFunction } from "i18next";
import * as Yup from "yup";

export const createValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    hostName: Yup.string()
      .max(
        25,
        t("matchAdmin:createMatch.hostData.input.name.validation", "Darf maximal 25 Zeichen lang sein")
      )
      .required(t("common:validation.required", "Pflichtangabe")),
    numberOfPlayers: Yup.string().required(t("common:validation.required", "Pflichtangabe")),
  });
