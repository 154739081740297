import { Accusation } from "./accusation";
import { EMatchStatus } from "../game-data";
import { ERound } from "./rounds.declarations";
import { getRelativeRoutePath } from "../shared";
import { IRoute } from "../navigation";
import { Prologue } from "./prologue";
import { ReactComponent as AccusationIcon } from "../assets/icon-accusation.svg";
import { ReactComponent as FirstRoundIcon } from "../assets/icon-round-1.svg";
import { ReactComponent as PrologueIcon } from "../assets/icon-prologue.svg";
import { ReactComponent as SecondRoundIcon } from "../assets/icon-round-2.svg";
import { ReactComponent as SolutionIcon } from "../assets/icon-solution.svg";
import { ReactComponent as ThirdRoundIcon } from "../assets/icon-round-3.svg";
import { Round } from "./round";
import { Solution } from "./solution";
import { SvgIcon } from "@material-ui/core";
import React from "react";

export const prologueRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { assignedCharacters, status }, characterName) => {
    const prologueReadBy = assignedCharacters?.find((c) => c.readsPrologue);

    return (
      !!prologueReadBy &&
      prologueReadBy.name === characterName &&
      !!status &&
      status >= EMatchStatus.CREATED
    );
  },
  component: <Prologue />,
  hideInSubNav: false,
  icon: <SvgIcon component={PrologueIcon} viewBox="0 0 512 512" />,
  id: "prologue",
  name: t("routes:prologue.label", "Prolog"),
  fullPath: t("routes:prologue.path", "/spiel/prolog"),
  relativePath: getRelativeRoutePath(t("routes:prologue.path", "/spiel/prolog")),
  isVisible: (isHost, { assignedCharacters, status }, characterName) => {
    const prologueReadBy = assignedCharacters?.find((c) => c.readsPrologue);

    return (
      !!prologueReadBy &&
      prologueReadBy.name === characterName &&
      !!status &&
      status >= EMatchStatus.CREATED
    );
  },
});

export const firstRoundRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) =>
    (isHost && !!status && status >= EMatchStatus.CREATED) ||
    (!!status && status >= EMatchStatus.CREATED),
  component: <Round round={ERound.FIRST} />,
  icon: <SvgIcon component={FirstRoundIcon} viewBox="0 0 512 512" />,
  id: "round-1",
  name: t("routes:firstRound.label", "Runde 1"),
  fullPath: t("routes:firstRound.path", "/spiel/runde-1"),
  relativePath: getRelativeRoutePath(t("routes:firstRound.path", "/spiel/runde-1")),
});

export const secondRoundRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) =>
    (isHost && !!status && status >= EMatchStatus.FIRST_ROUND_OPENED) ||
    (!!status && status >= EMatchStatus.SECOND_ROUND_OPENED),
  component: <Round round={ERound.SECOND} />,
  icon: <SvgIcon component={SecondRoundIcon} viewBox="0 0 512 512" />,
  id: "round-2",
  name: t("routes:secondRound.label", "Runde 2"),
  fullPath: t("routes:secondRound.path", "/spiel/runde-2"),
  relativePath: getRelativeRoutePath(t("routes:secondRound.path", "/spiel/runde-2")),
});

export const thirdRoundRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) =>
    (isHost && !!status && status >= EMatchStatus.SECOND_ROUND_OPENED) ||
    (!!status && status >= EMatchStatus.THIRD_ROUND_OPENED),
  component: <Round round={ERound.THIRD} />,
  icon: <SvgIcon component={ThirdRoundIcon} viewBox="0 0 512 512" />,
  id: "round-3",
  name: t("routes:thirdRound.label", "Runde 3"),
  fullPath: t("routes:thirdRound.path", "/spiel/runde-3"),
  relativePath: getRelativeRoutePath(t("routes:thirdRound.path", "/spiel/runde-3")),
});

export const accusationRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) =>
    (isHost && !!status && status >= EMatchStatus.THIRD_ROUND_OPENED) ||
    (!!status && status >= EMatchStatus.ACCUSATION_OPENED),
  component: <Accusation />,
  icon: <SvgIcon component={AccusationIcon} viewBox="0 0 512 512" />,
  id: "accusation",
  name: t("routes:accusation.label", "Anklage"),
  fullPath: t("routes:accusation.path", "/spiel/anklage"),
  relativePath: getRelativeRoutePath(t("routes:accusation.path", "/spiel/anklage")),
});

export const solutionRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) =>
    (isHost && !!status && status >= EMatchStatus.ACCUSATION_OPENED) ||
    (!!status && status >= EMatchStatus.SOLUTION_SHARED),
  component: <Solution />,
  icon: <SvgIcon component={SolutionIcon} viewBox="0 0 512 512" />,
  id: "solution",
  name: t("routes:solution.label", "Aufklärung"),
  fullPath: t("routes:solution.path", "/spiel/aufklaerung"),
  relativePath: getRelativeRoutePath(t("routes:solution.path", "/spiel/aufklaerung")),
});
