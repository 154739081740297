import React from "react";

export interface IMarginalContext {
  open: boolean;
  sliding: boolean;
  toggle: () => void;
}

export const MarginalContext = React.createContext<IMarginalContext>({
  open: false,
  toggle: () => null,
  sliding: true,
});
