import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

export const MarginalOffsetWrapper: React.FC = ({ children }) => {
  const [offset, setOffset] = useState(0);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    const handleResize = () => {
      if (!isTablet || isDesktop) {
        setOffset(0);
        return;
      }

      const screenSize = window.innerWidth;
      const textWidth = parseInt(
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue("--max-round-text-width")
          .replace("px", "")
      );

      const padding = parseInt(theme.spacing(2).replace("px", ""));

      const availableSpace = (screenSize - textWidth) / 2;
      const marginalWidth = parseInt(
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue("--marginal-width")
          .replace("px", "")
      );
      const neededSpace = marginalWidth + padding * 1.5;

      if (neededSpace > availableSpace) {
        setOffset(neededSpace - availableSpace);
        return;
      }

      setOffset(0);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return function cleanUp() {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop, isTablet, offset, theme]);

  if (!offset) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <div className="marginal-offset" style={{ transform: `translateX(${offset}px)` }}>
      {children}
    </div>
  );
};

/**
 * + 768 screen width (set in media query)
- 480 paragraph width (set as css variable)
= 288 difference

since the content is centered, the difference is split even on both sides
288px / 2 = 144px empty space on both sides

## Needed space for marginal elements
- 180 marginal width (set as const in marginal container)
- 9px margin to paragraph (set in css variable --ion-padding / 2)
- 18px safety right (set in css variable --ion-padding)
= -207px sum

the difference between available space and needed space is the amount we need to shift the content to the right

288 / 2 = 144 on both sides
207-144 = 63px shift to right
 */
