import { Box } from "@material-ui/core";
import { hintsState } from "./state";
import { PagePadding, PageTitle, Paragraph, PrimaryButton, WidthWrapper } from "../shared";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

type Props = {};

export const UnlockSolution: React.FC<Props> = () => {
  const { t } = useTranslation(["rounds"]);
  const setHints = useSetRecoilState(hintsState);

  const unlock = () => setHints((currVal) => ({ ...currVal, hostSolutionWarningRead: true }));

  return (
    <Fragment>
      <PagePadding>
        <WidthWrapper>
          <PageTitle>{t("openSolution.title", "Aufklärung")}</PageTitle>

          <Paragraph>
            {t(
              "openSolution.copy",
              "Möchtest du wirklich die Aufklärung öffnen? Darin erfährst du, wer der Täter bzw. die Täterin ist. Du solltest die Aufklärung erst am Spielende öffnen und den anderen Mitspielern vorlesen."
            )}
          </Paragraph>

          <Box sx={{ mt: 5 }}>
            <PrimaryButton data-test-id="host-unlock-solution-btn" onClick={unlock}>
              {t("openSolution.button.label", "Aufklärung öffnen")}
            </PrimaryButton>
          </Box>
        </WidthWrapper>
      </PagePadding>
    </Fragment>
  );
};
