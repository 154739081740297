import { gameTitleSelector } from "../game-data";
import { PageTitle, Paragraph } from "../shared";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import React, { Fragment } from "react";

export const WelcomeHost: React.FC = () => {
  const { t } = useTranslation(["rules"]);
  const title = useRecoilValue(gameTitleSelector);

  return (
    <Fragment>
      <PageTitle>
        <Trans ns="rules" i18nKey="welcomeHost.title">
          Liebe Gastgeberin,
          <br />
          lieber Gastgeber,
        </Trans>
      </PageTitle>

      <Paragraph>
        <Trans ns="rules" i18nKey="welcomeHost.copy">
          bei „Culinario Mortale – {{ gameTitle: title }}“ gilt es, einen spannenden Kriminalfall zu
          lösen. Das Besondere dabei: Bei diesem Verbrechen schlüpfen du und deine Gäste in die Rollen
          der Hauptverdächtigen. Das Ziel des Spiels ist es, den Täter in eurer Mitte zu überführen.
        </Trans>
      </Paragraph>

      <Paragraph>
        {t(
          "welcomeHost.copy2",
          "Dies kann nur gelingen, indem ihr untereinander den Tatablauf diskutiert und eure Erinnerungen an das Geschehen ­zusammentragt. Doch Vorsicht: Jeder Figur (dich mit eingeschlossen!) hat ihre eigenen dunklen Geheimnisse, die sie am liebsten für sich behalten würde."
        )}
      </Paragraph>

      <Paragraph>
        {t(
          "welcomeHost.copy3",
          "Wir führen dich als Gastgeber durch das Spiel. Du musst lediglich im Vorfeld die Rollen an deine Gäste verteilen. Für dich selbst kannst du dir eine der zu verteilenden Rollen aussuchen."
        )}
      </Paragraph>

      <Paragraph>
        {t(
          "welcomeHost.copy4",
          "Jeder Spieler erhält einen eigenen Link mit Zugang zu den Spielmaterialien. Damit können alle Gäste die Informationen über Spielregeln, Spielablauf und ihre Rolle in Ruhe zuhause lesen."
        )}
      </Paragraph>

      <Paragraph>
        {t(
          "welcomeHost.copy5",
          "Am Spielabend selbst versucht ihr dann mit Hilfe der drei Hinweisrunden eure Erinnerungen an den Tatabend zu rekonstruieren. Zum Schluss des Spiels stimmt ihr gemeinsam darüber ab, wen ihr als Tatverdächtigen der Polizei übergeben wollt."
        )}
      </Paragraph>
    </Fragment>
  );
};
