import { Theme, Typography, useTheme } from "@material-ui/core";
import React, { CSSProperties } from "react";
import styled from "styled-components";

type Props = {
  style?: CSSProperties;
};

const Text = styled(Typography).attrs({
  component: "h3",
  variant: "h3",
})<{ theme: Theme }>`
  margin-top: ${(props) => props.theme.spacing(3)};
  margin-bottom: 0;
`;

export const H3: React.FC<Props> = ({ children, style }) => {
  const theme = useTheme();
  return <Text theme={theme}>{children}</Text>;
};
