import { Typography } from "@material-ui/core";
import styled from "styled-components";

type Props = { $$narrow?: boolean };

export const Paragraph = styled(Typography)`
  font-kerning: normal;
  hyphens: inherit;
  max-width: ${(props: Props) =>
    props.$$narrow ? "var(--max-round-text-width)" : "var(--max-text-width)"};
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 1em;
`;
