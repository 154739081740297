import { Close } from "@material-ui/icons";
import { Fab, useTheme, Zoom } from "@material-ui/core";
import { floorPlanSelector } from "../game-data";
import { Map } from "./map";
import { Modal, useFabOffset } from "../shared";
import { useRecoilValue } from "recoil";
import React from "react";
import { canUseAnimation } from "../theme";

type Props = { isOpen: boolean; onDismiss: () => void };

export const FloorPlan: React.FC<Props> = ({ isOpen, onDismiss }) => {
  const floorPlanUrl = useRecoilValue(floorPlanSelector);
  const theme = useTheme();
  const fabOffset = useFabOffset();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Map url={floorPlanUrl} />

      <div style={{ position: "relative" }}>
        <Zoom
          in={true}
          style={{ transitionDelay: canUseAnimation ? "300ms" : "0ms" }}
          timeout={transitionDuration}
          unmountOnExit
        >
          <Fab
            aria-label="close"
            onClick={onDismiss}
            style={{
              background: theme.palette.common.black,
              bottom: theme.spacing(4),
              color: "white",
              position: "fixed",
              right: fabOffset,
              textTransform: "none",
            }}
          >
            <Close />
          </Fab>
        </Zoom>
      </div>
    </Modal>
  );
};
