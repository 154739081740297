import { characterNameSelector, ECharacterGender, genderSelector, hostSelector } from "../game-data";
import { gameRulesRoute, structureOfTheGameRoute } from "./routes";
import { InlineButton, PageTitle, Paragraph } from "../shared";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { yourRoleRoute } from "../your-role";
import React, { Fragment } from "react";

type Props = {};

export const WelcomeGuest: React.FC<Props> = () => {
  const { t } = useTranslation(["rules"]);
  const gender = useRecoilValue(genderSelector);
  const characterName = useRecoilValue(characterNameSelector);
  const host = useRecoilValue(hostSelector);

  const maleLabel = t("common:perpetratorMale", "der Täter");
  const femaleLabel = t("common:perpetratorFemale", "die Täterin");
  const perpetrator = gender === ECharacterGender.FEMALE ? femaleLabel : maleLabel;

  return (
    <Fragment>
      <PageTitle>{t("welcomeGuest.title", "Herzlich willkommen")}</PageTitle>

      <Paragraph>
        <Trans ns="rules" i18nKey="welcomeGuest.copy">
          Du wurdest zu einem „Culinario Mortale“-Spiel eingeladen. ­Gemeinsam mit den anderen Spielern
          gehörst du für einen Abend zu den Hauptverdächtigen in einem spannenden Kriminalfall.
        </Trans>
      </Paragraph>

      <Paragraph>
        {t(
          "welcomeGuest.copy2",
          "Das Ziel des Spiels ist es, den Täter, der mit am Tisch sitzt, zu überführen. Dies kann nur gelingen, indem ihr untereinander den Tatablauf diskutiert und eure Erinnerungen an die Tat ­zusammentragt. Doch Vorsicht: Jede Figur hat ihre eigenen dunklen Geheimnisse, die sie am liebsten für sich behalten würde."
        )}
      </Paragraph>

      <Paragraph>
        <Trans ns="rules" i18nKey="welcomeGuest.copy3">
          Bitte suche dir einen Ort, an dem du ungestört die Informationen über das Spiel und über deine
          Rolle lesen kannst. Darin erhältst du alle wichtigen Informationen zu den{" "}
          <InlineButton to={gameRulesRoute(t).fullPath}>
            {{ gameRulesRoute: gameRulesRoute(t).name }}
          </InlineButton>
          , zum{" "}
          <InlineButton to={structureOfTheGameRoute(t).fullPath}>
            {{ structureOfTheGameRoute: structureOfTheGameRoute(t).name }}
          </InlineButton>{" "}
          und zu deiner <InlineButton to={yourRoleRoute(t).fullPath}>Rolle</InlineButton>. In deiner
          Rollenbeschreibung erfährst du auch, ob deine Figur {{ characterName }} {{ perpetrator }} ist
          oder nicht.
        </Trans>
      </Paragraph>

      <Paragraph>
        <Trans ns="rules" i18nKey="welcomeGuest.copy4">
          Bitte mache dich mit diesem Informationen schon vor dem Spielabend vertraut. Die Hinweisrunden
          können erst während des Spielabends nach gelesen werden, nachdem sie vom Gastgeber (
          {{ hostName: host?.playerName ?? "" }}) freigeschaltet wurden. Sowohl deine Rollenbeschreibung
          als auch die Hinweisrunden sind nur für deine Augen bestimmt sind. Du solltest sie mit keinem
          anderen Spieler teilen.
        </Trans>
      </Paragraph>

      <Paragraph>
        {t(
          "welcomeGuest.copy5",
          "Bitte erscheine pünktlich zum Spielabend. Wenn du möchtest, kannst du dich auch deiner Rolle entsprechend verkleiden. Bei ­Fragen kannst du dich jederzeit an den Gastgeber wenden."
        )}
      </Paragraph>
    </Fragment>
  );
};
