import { charactersSelector, EMatchStatus, matchData, playerRangeSelector } from "../game-data";
import { createValidationSchema } from "./validation-schema";
import { Form, Formik, FormikHelpers } from "formik";
import { getRelativeRoutePath } from "../shared";
import { HostData } from "./host-data";
import { ICreateMatchFormState } from "./form-state.interface";
import { NumberOfPlayers } from "./number-of-players";
import { RoleDistribution } from "./role-distribution";
import { Route, Routes, useNavigate } from "react-router";
import { Story } from "./story";
import { Summary } from "./summary";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { viewMatchRoute } from "./routes";
import { WS } from "../websocket";
import camelCase from "camelcase";
import React, { Fragment } from "react";

export const Wizard: React.FC = () => {
  const { maxPlayers } = useRecoilValue(playerRangeSelector);
  const { t } = useTranslation(["matchAdmin", "common"]);
  const characters = useRecoilValue(charactersSelector);
  const navigate = useNavigate();
  const setMatchData = useSetRecoilState(matchData);
  const ValidationSchema = createValidationSchema(t);

  const handleSubmit = (
    data: ICreateMatchFormState,
    { setSubmitting }: FormikHelpers<ICreateMatchFormState>
  ) => {
    WS.send(JSON.stringify({ action: "createMatch", data }));
    setMatchData((currVal) => ({
      ...currVal,
      status: EMatchStatus.CREATION_PENDING,
    }));

    setSubmitting(false);
    navigate(viewMatchRoute(t).fullPath);
  };

  const initialValues: ICreateMatchFormState = {
    assignedCharacters:
      characters.reduce(
        (acc: any, c: any) => ({
          ...acc,
          [camelCase(c.name)]: {
            playedByHost: false,
            playerName: "",
          },
        }),
        {}
      ) ?? {},
    hostName: "",
    numberOfPlayers: String(maxPlayers),
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema}
      >
        {({ values }) => {
          return (
            <Form>
              <Routes>
                <Route
                  path={getRelativeRoutePath(t("matchAdmin:createMatch.hostData.step.path"))}
                  element={<HostData />}
                />
                <Route
                  path={getRelativeRoutePath(t("matchAdmin:createMatch.playerNumber.step.path"))}
                  element={<NumberOfPlayers />}
                />
                <Route
                  path={getRelativeRoutePath(t("matchAdmin:createMatch.case.step.path"))}
                  element={<Story />}
                />
                <Route
                  path={getRelativeRoutePath(t("matchAdmin:createMatch.playerDistribution.step.path"))}
                  element={<RoleDistribution />}
                />
                <Route
                  path={getRelativeRoutePath(t("matchAdmin:createMatch.summary.step.path"))}
                  element={<Summary />}
                />
              </Routes>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};
