import { Box, Skeleton, Theme, useTheme } from "@material-ui/core";
import { createMatchRoute } from "./routes";
import { EMatchStatus, matchCharactersSelector, matchData, matchStatusSelector } from "../game-data";
import {
  InlineButton,
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  TechnicalHint,
  UnstyledList,
  useConfirm,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { MatchCharacterCard } from "./match-character-card";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { WS } from "../websocket";
import { yourRoleRoute } from "../your-role";
import React from "react";
import styled from "styled-components";

const List = styled(UnstyledList)<{ theme: Theme }>`
  border-top: 1px dashed ${(props) => props.theme.palette.grey[400]};
  border-bottom: 1px dashed ${(props) => props.theme.palette.grey[400]};
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

export const ViewMatch: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["matchAdmin", "common", "routes"]);
  const theme = useTheme();
  const confirm = useConfirm();
  const characters = useRecoilValue(matchCharactersSelector);
  const status = useRecoilValue(matchStatusSelector);
  const resetMatchData = useResetRecoilState(matchData);
  const navigate = useNavigate();

  const deleteMatch = async () => {
    const confirmed = await confirm({
      title: t("viewMatch.deleteButtonLabel", "Spiel löschen"),
      description: t(
        "viewMatch.deleteConfirmationCopy",
        "Möchtest du deine Spielpartie sicher löschen? Du kannst mit deinem Code zu einem späteren Zeitpunkt ein neues Spiel erstellen."
      ),
      confirmationText: t("common:confirmButton.label", "Okay"),
      cancellationText: t("common:cancelButton.label", "Abbrechen"),
    });

    if (!confirmed) {
      return;
    }

    resetMatchData();
    navigate(createMatchRoute(t).fullPath);
    WS.send(JSON.stringify({ action: "deleteMatch", data: {} }));
  };

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle>{t("viewMatch.title", "Dein Spiel")}</PageTitle>

        <Box sx={{ mb: 5 }}>
          <Trans ns="matchAdmin" i18nKey="viewMatch.copy">
            <Paragraph>
              Bitte verteile die Links zu den Spielmaterialien an deine Gäste. Achtung: Jeder Link ist
              nur für die Augen des jeweiligen Spielers bestimmt.
            </Paragraph>

            <Paragraph>
              Im Menü findest du die Informationen zu deiner{" "}
              <InlineButton to={yourRoleRoute(t).fullPath ?? "/"}>eigenen Rolle</InlineButton>. Bitte
              lies die die Rollenbeschreibung vor dem Spielabend durch.
            </Paragraph>
          </Trans>
        </Box>

        {status < EMatchStatus.CREATED && (
          <WidthWrapper>
            {Array(5)
              .fill("")
              .map((_, ix) => (
                <Box sx={{ mb: 1 }} key={`match-placeholder-${ix}`}>
                  <Skeleton height={140} variant="rectangular" />
                </Box>
              ))}
          </WidthWrapper>
        )}

        {status >= EMatchStatus.CREATED && (
          <React.Fragment>
            <WidthWrapper>
              <List theme={theme}>
                {characters.map((c, ix) => (
                  <MatchCharacterCard character={c} key={`char-${ix}`} />
                ))}
              </List>
            </WidthWrapper>

            <Box sx={{ mt: 3 }}>
              <Paragraph>
                {t(
                  "viewMatch.copy2",
                  "Wenn du die Rollen anders verteilen möchtest, kannst du die Partie vor Spielbeginn einfach löschen und neu erstellen."
                )}
              </Paragraph>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Box sx={{ mb: 2 }}>
                <WidthWrapper>
                  <PrimaryButton
                    disabled={status >= EMatchStatus.THIRD_ROUND_OPENED}
                    onClick={deleteMatch}
                  >
                    {t("viewMatch.deleteButtonLabel", "Spiel löschen")}
                  </PrimaryButton>
                </WidthWrapper>
              </Box>

              <TechnicalHint>
                {t(
                  "viewMatch.deleteNotice",
                  "Du kannst mit deinem Code das Spiel nur einmal spielen. Nachdem du die dritte Spielrunde geöffnet hast, kannst du das Spiel nicht mehr löschen."
                )}
              </TechnicalHint>
            </Box>
          </React.Fragment>
        )}
      </WidthWrapper>
    </PagePadding>
  );
};
