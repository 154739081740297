import { Theme, useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Properties } from "csstype";

export const CharacterContentElement = styled.div<{ theme: Theme }>`
  -webkit-font-kerning: normal;
  color: var(--ion-text-color);
  font-family: var(--font-serif);
  font-kerning: normal;
  font-size: var(--ion-font-size-body);
  font-style: normal;
  font-weight: 400;
  line-height: var(--ion-line-height-body);
  max-width: var(--max-text-width);
  padding: 0;
  transition: ${(props) => props.theme.transitions.create("all")};
`;

type Props = { style?: Properties };

export const CharacterContent: React.FC<Props> = ({ children, style = {} }) => {
  const theme = useTheme();
  return (
    <CharacterContentElement style={style} theme={theme}>
      {children}
    </CharacterContentElement>
  );
};
