import { isJapanese } from "../shared";
import { ThemeOptions } from "@material-ui/core";

export const latinThemeDefaults: ThemeOptions = {
  components: {
    MuiSpeedDialAction: {
      styleOverrides: {
        staticTooltipLabel: {
          fontFamily: "aktiv-grotesk, sans-serif",
          fontSize: "0.85rem",
          fontStyle: "normal",
          fontWeight: 400,
          letterSpacing: "0.2px",
          lineHeight: 1.4,
          padding: "8px 4px",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.35)",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          overflowY: "visible",
          overflowX: "visible",
          marginTop: "5px",
          "&:before": {
            background: "white",
            content: '""',
            display: "block",
            height: "12px",
            left: "50%",
            position: "absolute",
            top: "0px",
            transform: "rotate(45deg) translateX(-50%)",
            width: "12px",
            zIndex: 1000000000,
          },
        },
      },
    },
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: ["aktiv-grotesk", "sans-serif"].join(","),
    /** h1: Page title */
    h1: {
      fontFamily: "eb-garamond, serif",
      fontKerning: "normal",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: "eb-garamond, serif",
      fontKerning: "normal",
      fontSize: "1.45rem",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "0.1px",
      lineHeight: 1.25,
    },
    h3: {
      fontFamily: "eb-garamond, serif",
      fontKerning: "normal",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: "0",
      lineHeight: 1.4,
    },
    /** h4: Dachzeile / Subheadline with black background */
    h4: {
      fontFamily: "aktiv-grotesk-condensed, sans-serif",
      fontSize: "1rem",
      fontStyle: "normal",
      fontVariantNumeric: "lining-nums",
      fontWeight: 500,
      letterSpacing: "0.4px",
      lineHeight: 1,
    },
    /** body1: Fließtext */
    body1: {
      fontFamily: "eb-garamond, serif",
      fontKerning: "normal",
      fontSize: "1.25rem",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.012rem",
      lineHeight: 1.4,
    },
    /** body2: Spielhinweis / game hint */
    body2: {
      fontFamily: "eb-garamond, serif",
      fontKerning: "normal",
      fontSize: "1.25rem",
      fontStyle: isJapanese ? "normal" : "italic",
      fontWeight: 500,
      letterSpacing: "0",
      lineHeight: 1.4,
    },
    subtitle1: {
      fontFamily: "aktiv-grotesk, sans-serif",
      fontSize: "1rem",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.2px",
      lineHeight: 1.4,
    },
    subtitle2: {
      fontFamily: "aktiv-grotesk-condensed, sans-serif",
      fontSize: "0.85rem",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.2px",
      lineHeight: 1.4,
    },
    /** caption: technical hint parahraph */
    caption: {
      fontFamily: "aktiv-grotesk, sans-serif",
      fontSize: "0.85rem",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.2px",
      lineHeight: 1.4,
    },
  },
  palette: {
    common: {
      black: "#222126",
      white: "#fff",
    },
    background: {
      paper: "#ffffff",
      default: "#ffffff",
    },
    secondary: {
      main: "#222126",
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0 0.6px 2.2px rgba(0, 0, 0, 0.006), 0 1.3px 5.3px rgba(0, 0, 0, 0.008)",
    "0 2.5px 10px rgba(0, 0, 0, 0.01), 0 4.5px 17.9px rgba(0, 0, 0, 0.012)",
    "0 0 8px 0 rgba(0,0,0,.125)",
    "0 0 8px 0 rgba(0,0,0,.25)",
    "0 2.8px 2.2px rgba(0, 0, 0, 0.008), 0 6.7px 5.3px rgba(0, 0, 0, 0.012)",
    "0 12.5px 10px rgba(0, 0, 0, 0.015), 0 22.3px 17.9px rgba(0, 0, 0, 0.018)",
    "0 41.8px 33.4px rgba(0, 0, 0, 0.022), 0 100px 80px rgba(0, 0, 0, 0.03)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0 2.8px 2.2px rgba(0, 0, 0, 0.008), 0 6.7px 5.3px rgba(0, 0, 0, 0.012)",
    "0 12.5px 10px rgba(0, 0, 0, 0.015), 0 22.3px 17.9px rgba(0, 0, 0, 0.018)",
    "0 41.8px 33.4px rgba(0, 0, 0, 0.022), 0 100px 80px rgba(0, 0, 0, 0.03)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
  ],
};
