import { Box, useTheme } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import {
  FormikTextInput,
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  TechnicalHint,
  WidthWrapper,
} from "../shared";
import { StepIndicator } from "./step-indicator";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

export const HostData: React.FC = () => {
  const { errors, touched } = useFormikContext();
  const { t } = useTranslation(["matchAdmin", "common", "routes"]);
  const isInvalid = "hostName" in touched === false || "hostName" in errors;
  const navigate = useNavigate();
  const theme = useTheme();

  const continueWizard = () =>
    navigate(
      t("matchAdmin:createMatch.playerNumber.step.path", "/vorbereitung/spiel-erstellen/spieleranzahl")
    );

  return (
    <Fragment>
      <PagePadding>
        <WidthWrapper>
          <StepIndicator activeStep={1} steps={5} />
          <PageTitle>{t("createMatch.hostData.title", "Wie heißt du?")}</PageTitle>

          <Paragraph>
            {t(
              "createMatch.hostData.copy",
              "Bitte gib deinen Vornamen an. Dieser wird den anderen Spielern als Gastgeber angezeigt."
            )}
          </Paragraph>

          <Field
            component={FormikTextInput}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={t("createMatch.hostData.input.name.label", "Dein Vorname")}
            margin="normal"
            name="hostName"
            placeholder={t("createMatch.hostData.input.name.placeholder", "Dein Vorname")}
            required
            theme={theme}
            variant="outlined"
          />

          <Box sx={{ mt: 1 }}>
            <PrimaryButton data-test-id="wizard-next-btn" disabled={isInvalid} onClick={continueWizard}>
              {t("common:nextButton.label", "Weiter")}
            </PrimaryButton>
          </Box>

          <Box sx={{ mt: 2 }}>
            <TechnicalHint>
              {t(
                "createMatch.hostData.legalNotice",
                "Wir verwenden deine Daten lediglich, um dir das Spiel zur Verfügung zu stellen. Deine persönlichen Daten werden innerhalb von 48 Stunden nach Spielende gelöscht."
              )}
            </TechnicalHint>
          </Box>
        </WidthWrapper>
      </PagePadding>
    </Fragment>
  );
};
