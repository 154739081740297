import { Button, Theme, useTheme } from "@material-ui/core";
import { useNavigate } from "react-router";
import React, { CSSProperties } from "react";
import styled from "styled-components";

type BaseProps = { color?: string; theme: Theme };

export const InlineButtonBase = styled(Button).attrs({ component: "a" })<BaseProps>`
  &&& {
    appearance: none;
    background: none;
    color: ${(props) => (props.color ? props.color : "inherit")};
    display: inline;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    line-height: inherit;
    min-width: unset;
    padding: 0;
    text-decoration-skip-ink: auto;
    text-decoration-skip: ink;
    text-decoration: underline solid;
    text-transform: none;
    text-underline-position: from-font;
    transition: ${(props) =>
      props.theme.transitions.create(["color, text-decoration-color"], { duration: "short" })};
    vertical-align: baseline;
    margin: 0;

    &:hover {
      background: transparent;
      color: ${(props) => props.theme.palette.primary.dark};
      text-decoration-color: ${(props) => props.theme.palette.primary.dark};

      .MuiButton-label {
        color: ${(props) => props.theme.palette.primary.dark};
      }
    }
  }

  .MuiButton-label {
    position: relative;
    transition: ${(props) => props.theme.transitions.create("color", { duration: "short" })};
  }

  &:focus {
    outline: none;
    text-decoration: none;
  }
`;

type Props = { color?: any; onClick?: () => void; style?: CSSProperties; to?: string };

export const InlineButton: React.FC<Props> = ({ children, color, onClick, style = {}, to }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  if (!!!onClick && !!!to) {
    console.warn("Inline button either needs a to path or an onClick function");
    return null;
  }

  const goToPath = () => navigate(to ?? "");
  const handleClick = onClick ? onClick : goToPath;

  return (
    <InlineButtonBase color={color} onClick={handleClick} style={style} theme={theme}>
      {children}
    </InlineButtonBase>
  );
};
