import { backgroundRoute } from "../your-role";
import {
  characterNameSelector,
  characterReadsPrologueSelector,
  EMatchStatus,
  ERound,
  hostSelector,
  matchStatusSelector,
  roundsTimeSelector,
} from "../game-data";
import { CommonRulesText } from "./common";
import {
  ConditionalInlineLink,
  InlineButton,
  PagePadding,
  PageTitle,
  Paragraph,
  SubHeadline,
  useHasRenderedClass,
} from "../shared";
import { credentialsType, ECredentialsType } from "../authentication";
import {
  firstRoundRoute,
  prologueRoute,
  secondRoundRoute,
  solutionRoute,
  thirdRoundRoute,
} from "../rounds";
import { gameRulesRoute } from "./routes";
import { incidentARoute, incidentBRoute } from "../incidents";
import { IRoute } from "../navigation";
import { matchData, prologueReadBySelector } from "../game-data";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import React from "react";

export const StructureOfTheGame: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["rules", "common"]);

  const characterName = useRecoilValue(characterNameSelector);
  const characterReadsPrologue = useRecoilValue(characterReadsPrologueSelector);
  const host = useRecoilValue(hostSelector);
  const match = useRecoilValue(matchData);
  const matchStatus = useRecoilValue(matchStatusSelector);
  const prologueReadBy = useRecoilValue(prologueReadBySelector);
  const rounds = useRecoilValue(roundsTimeSelector);
  const type = useRecoilValue(credentialsType);
  const isHost = type === ECredentialsType.ACCESS_CODE;

  let prologueHint = "";

  if (matchStatus < EMatchStatus.CREATED) {
    prologueHint = prologueReadBy?.name ?? "";
  } else {
    prologueHint = characterReadsPrologue
      ? t("common:you", "du")
      : `${prologueReadBy?.name} (${prologueReadBy?.playerName})`;
  }

  const hostHint = isHost ? "" : ` (${host?.playerName})`;
  const canAccess = (r?: IRoute) =>
    r && r.canAccess && characterName ? r.canAccess(isHost, match, characterName) : false;

  const canAccessFirstRound = canAccess(firstRoundRoute(t));
  const canAccessSecondRound = canAccess(secondRoundRoute(t));
  const canAccessThirdRound = canAccess(thirdRoundRoute(t));
  const canAccessSolution = canAccess(solutionRoute(t));
  const canAccessIncidentA = canAccess(incidentARoute(t));
  const canAccessIncidentB = canAccess(incidentBRoute(t));

  return (
    <PagePadding>
      <PageTitle>{t("structureOfTheGame.title", "Der Spielablauf")}</PageTitle>

      <SubHeadline>{t("structureOfTheGame.start.title", "Spielbeginn")}</SubHeadline>
      <Paragraph>
        <Trans ns="rules" i18nKey="structureOfTheGame.start.copy">
          Der Gastgeber {{ hostHint }} liest die Abschnitte die{" "}
          <InlineButton to={gameRulesRoute(t).fullPath ?? "/"}>Spielregeln</InlineButton>, der
          Spielablauf (dieser Text) und die{" "}
          <ConditionalInlineLink
            show={matchStatus >= EMatchStatus.CREATED}
            path={backgroundRoute(t).fullPath ?? "/"}
          >
            ­Vorgeschichte
          </ConditionalInlineLink>{" "}
          vor. Jeder stellt sich der Reihe nach mit ein bis zwei Sätzen vor. Danach liest{" "}
          {{ prologueHint }} aus dem Ereignisheft den{" "}
          <ConditionalInlineLink path={prologueRoute(t).fullPath ?? "/"} show={characterReadsPrologue}>
            Prolog
          </ConditionalInlineLink>{" "}
          vor. Er ist in Form einer kurzen Ansprache verfasst.
        </Trans>
      </Paragraph>

      <SubHeadline>{t("structureOfTheGame.firstRound.title", "Runde 1")}</SubHeadline>
      <Paragraph>
        <Trans ns="rules" i18nKey="structureOfTheGame.firstRound.copy">
          Nach Aufforderung durch den Gastgeber lesen alle Spieler{" "}
          <ConditionalInlineLink path={firstRoundRoute(t).fullPath ?? "/"} show={canAccessFirstRound}>
            Runde&nbsp;1
          </ConditionalInlineLink>
          . Diskutiert anschließend den Tatabend bis{" "}
          {{ firstRoundStart: rounds?.[ERound.FIRST]?.end ?? "" }}.
        </Trans>
      </Paragraph>
      <Paragraph>
        <Trans ns="rules" i18nKey="structureOfTheGame.firstRound.copy2">
          Da jede Hinweisrunde einen anderen Zeitabschnitt behandelt, besitzt ihr in der ersten Runde
          noch nicht alle Informationen über den Tathergang. Die Tat lässt sich erst in Runde 3
          vollständig rekonstruieren.
        </Trans>
      </Paragraph>

      <SubHeadline>{t("structureOfTheGame.secondRound.title", "Runde 2")}</SubHeadline>
      <Paragraph>
        <Trans ns="rules" i18nKey="structureOfTheGame.secondRound.copy">
          Alle Spieler lesen{" "}
          <ConditionalInlineLink path={secondRoundRoute(t).fullPath ?? "/"} show={canAccessSecondRound}>
            Runde&nbsp;2
          </ConditionalInlineLink>
          . Der Gastgeber
          {{ hostHint }} liest aus seinen Spielmaterialien{" "}
          <ConditionalInlineLink path={incidentARoute(t).fullPath ?? "/"} show={canAccessIncidentA}>
            Ereignis A
          </ConditionalInlineLink>{" "}
          vor Mit den neuen Informationen könnt ihr den Zeitabschnitt ­zwischen{" "}
          {{ secondRoundStart: rounds?.[ERound.SECOND].start }} und{" "}
          {{ secondRoundEnd: rounds?.[ERound.SECOND].end }} diskutieren.
        </Trans>
      </Paragraph>

      <SubHeadline>{t("structureOfTheGame.thirdRound.title", "Runde 3")}</SubHeadline>
      <Paragraph>
        <Trans ns="rules" i18nKey="structureOfTheGame.thirdRound.copy">
          Alle Spieler lesen{" "}
          <ConditionalInlineLink path={thirdRoundRoute(t).fullPath ?? "/"} show={canAccessThirdRound}>
            Runde 3
          </ConditionalInlineLink>
          . Der Gastgeber{{ hostHint }} liest aus seinen Spielmaterialien{" "}
          <ConditionalInlineLink path={incidentBRoute(t).fullPath ?? "/"} show={canAccessIncidentB}>
            Ereignis B
          </ConditionalInlineLink>{" "}
          vor. Diskutiert anschließend den Zeitabschnitt ­zwischen{" "}
          {{ thirdRoundStart: rounds?.[ERound.THIRD].start }} und{" "}
          {{ thirdRoundEnd: rounds?.[ERound.THIRD].end }}. Ihr verfügt nun über alle nötigen
          Informationen, um den Täter bzw. die Täterin zu überführen.
        </Trans>
      </Paragraph>

      <SubHeadline>{t("structureOfTheGame.accusation.title", "Verdacht und Verteidigung")}</SubHeadline>
      <Paragraph>
        {t(
          "structureOfTheGame.accusation.copy",
          "Alle Spieler äußern ihren Verdacht, wen sie für den Täter halten. Alle Verdächtigten haben die Gelegenheit, sich zu verteidigen."
        )}
      </Paragraph>

      <SubHeadline>{t("structureOfTheGame.solution.title", "Aufklärung")}</SubHeadline>
      <Paragraph>
        <Trans ns="rules" i18nKey="structureOfTheGame.solution.copy">
          Der Gastgeber liest aus seinen Spielmaterialien die{" "}
          <ConditionalInlineLink path={solutionRoute(t).fullPath ?? "/"} show={canAccessSolution}>
            Aufklärung
          </ConditionalInlineLink>{" "}
          vor. Ist es euch gelungen, den Täter zu überführen oder konnte er entkommen?
        </Trans>
      </Paragraph>

      <CommonRulesText />
    </PagePadding>
  );
};
