import { EMatchStatus, ERound } from "../game-data";

export const setJapaneseCss = (): void => {
  document.documentElement.classList.add("japanese");
  document.documentElement.style.setProperty(
    "--ion-font-family",
    "toppan-bunkyu-midashi-go-std, sans-serif"
  );
  document.body.style.setProperty("--ion-font-family", "toppan-bunkyu-midashi-go-std, sans-serif");
  document.documentElement.style.setProperty("--font-heading", "dnp-shuei-ymincho-std, sans-serif");
  document.body.style.setProperty("--font-heading", "dnp-shuei-ymincho-std, sans-serif");
  document.documentElement.style.setProperty("--font-sans", "toppan-bunkyu-midashi-go-std, sans-serif");
  document.body.style.setProperty("--font-sans", "toppan-bunkyu-midashi-go-std, sans-serif");
  document.body.style.setProperty("--font-sans-condensed", "dnp-shuei-gothic-gin-std, sans-serif");
  document.documentElement.style.setProperty(
    "--font-sans-condensed",
    "dnp-shuei-gothic-gin-std, sans-serif"
  );
  document.documentElement.style.setProperty("--font-serif", "dnp-shuei-ymincho-std, sans-serif");
  document.body.style.setProperty("--font-serif", "dnp-shuei-ymincho-std, sans-serif");
};

export const getLngFromDomain = (): string => {
  const hostname = window.location.hostname;

  if (process.env.REACT_APP_LNG) {
    return process.env.REACT_APP_LNG;
  }

  if (hostname.includes("culinario-mortale.co.uk")) {
    return "en-GB";
  }

  if (hostname.includes("culinario-mortale.de")) {
    return "de-DE";
  }

  if (hostname.includes("culinario-mortale.com")) {
    return "en-US";
  }

  if (hostname.includes("culinario-mortale.jp")) {
    return "ja-JP";
  }

  if (hostname.includes("culinario-mortale.fr")) {
    return "fr-FR";
  }

  return "en-GB";
};

export const getStatusForRound = (round: ERound): EMatchStatus => {
  switch (round) {
    case ERound.FIRST:
      return EMatchStatus.FIRST_ROUND_OPENED;

    case ERound.SECOND:
      return EMatchStatus.SECOND_ROUND_OPENED;

    case ERound.THIRD:
      return EMatchStatus.THIRD_ROUND_OPENED;

    default:
      return EMatchStatus.NONE;
  }
};

export const getRoundNumber = (round: ERound): number => {
  switch (round) {
    case ERound.FIRST:
      return 1;

    case ERound.SECOND:
      return 2;

    case ERound.THIRD:
      return 3;
  }
};

export const getNextRound = (round: ERound): ERound => {
  switch (round) {
    case ERound.FIRST:
      return ERound.SECOND;

    case ERound.SECOND:
      return ERound.THIRD;

    case ERound.THIRD:
      return ERound.THIRD;
  }
};
