import { Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";
import styled from "styled-components";

type MarginalElementProps = {
  title: string;
  content: string;
};

const Container = styled.span<{ theme: Theme }>`
  display: inline-block;
  float: left;
  left: 0;
  margin-left: calc(-1 * var(--marginal-width));
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  text-align: right;
  width: var(--marginal-width);

  @media screen and (min-width: 768px) {
    margin-left: calc(-1 * var(--marginal-width) - 24px * 0.5);
  }

  @media screen and (min-width: 1280px) {
    margin-left: calc(-1 * var(--marginal-width) - 24px * 0.75);
  }
`;

const Root = styled.span`
  display: inline-block;
  width: 100%;
`;

const Content = styled.span`
  font-family: var(--font-sans-condensed);
  font-size: 1rem;
  font-weight: 300;
  hyphens: manual;
`;

const Title = styled.span<{ language: string }>`
  color: white;
  display: inline-block;
  font-family: ${(props) =>
    props.language.includes("jp") ? "var(--font-sans)" : "var(--font-sans-condensed)"};
  font-size: 1rem;
  font-variant-numeric: lining-nums;
  font-weight: 900;
  hyphens: none;
  -webkit-hyphens: none;
  letter-spacing: 0.5px;
  line-height: 1;
  padding: 0px 6px;
  position: relative;
  text-transform: ${(props) =>
    ["fr", "FR", "fr-FR"].includes(props.language) ? "normal" : "uppercase"};

  &:after {
    background-color: var(--ion-text-color);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }
`;

export const MarginalElement: React.FC<MarginalElementProps> = ({ title, content }) => {
  const { i18n } = useTranslation(["yourRole", "common"]);

  return (
    <Fragment>
      <Container className="marginal-element">
        <Root>
          <Title language={i18n.language}>
            <span style={{ position: "relative", zIndex: 10 }}>{title}</span>
          </Title>
        </Root>
        <Content>{content}</Content>
      </Container>
    </Fragment>
  );
};
