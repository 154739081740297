import { H2, PagePadding, PageTitle, Paragraph, useHasRenderedClass, WidthWrapper } from "../shared";
import { Trans, useTranslation } from "react-i18next";
import React from "react";

export const Imprint: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["legal", "common"]);

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle>{t("imprint.title", "Impressum")}</PageTitle>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy">
            Culinario Mortale® ist eine eingetragene Marke der Bischoff, Kauffmann & Otani GbR.
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="imprint.copy2">
            Angaben gemäß §5 TMG
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy3">
            Bischoff, Kauffmann & Otani GbR
            <br />
            Württembergallee 29 <br />
            14052 Berlin
            <br />
            Deutschland
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy4">
            Vertreten durch die Gesellschafter:
            <br />
            Florian Norbert Bischoff
            <br />
            Jens Kauffmann
            <br />
            Takeshi Otani
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="imprint.copy5">
            Kontakt
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy6">
            E-Mail: mail@culinaio-mortale.de
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="imprint.copy7">
            USt.-Id-Nr.
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy8">
            Umsatzsteuer-Identifikationsnummer gem. §27a UStG: DE288168520
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="imprint.copy9">
            Streitschlichtung
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy10">
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Du
            findest dieser unter https://ec.europa.eu/consumers/odr.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy11">
            Unsere E-Mail-Adresse lautet mail@culinario-mortale.de.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy12">
            Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind
            wir nicht verpflichtet und nicht bereit.
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="imprint.copy13">
            Quellenangaben
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="imprint.copy14">
            Die Illustrationen der Spiele stammen von Katja Hasenöhrl, Cornelia Wiekort und Gerald
            Wagner.
          </Trans>
        </Paragraph>
      </WidthWrapper>
    </PagePadding>
  );
};
