import { atom, selector } from "recoil";
import { configState } from "../config";
import { EAuthStatus } from "./auth-status.enum";
import { ECredentialsType } from "./credentials-type.enum";
import qs from "query-string";

export enum EConnectionStatus {
  CLOSED = "CLOSED",
  CONNECTED = "CONNECTED",
  CONNECTING = "CONNECTING",
}

const parseStoredCredentials = selector({
  key: "parseStoredCredentials",
  get: ({ get }) => {
    const { localStorageKey } = get(configState);
    const parsed = qs.parse(window.location.search);
    const accessCode = !!parsed.accessCode ? String(parsed.accessCode) : "";
    const token = !!parsed.token ? String(parsed.token) : "";
    const savedAccessCode = localStorage.getItem(`${localStorageKey}-accessCode`) ?? "";
    const savedToken = localStorage.getItem(`${localStorageKey}-token`) ?? "";

    if (!!accessCode) {
      return {
        value: accessCode,
        type: ECredentialsType.ACCESS_CODE,
      };
    }

    if (!!token) {
      return {
        value: token,
        type: ECredentialsType.TOKEN,
      };
    }

    if (!!savedAccessCode) {
      return {
        value: savedAccessCode,
        type: ECredentialsType.ACCESS_CODE,
      };
    }

    if (!!savedToken) {
      return {
        value: savedToken,
        type: ECredentialsType.TOKEN,
      };
    }

    return {
      value: "",
      type: ECredentialsType.TOKEN,
    };
  },
});

export const authStatusState = atom({
  key: "authStatus",
  default: EAuthStatus.SIGNED_OUT,
});

export const credentialsState = atom({
  key: "credentialsState",
  default: parseStoredCredentials,
});

export const connectionStatusState = atom({
  key: "connectionStatusState",
  default: EConnectionStatus.CLOSED,
});

export const credentialsType = selector({
  key: "credentialsType",
  get: ({ get }): ECredentialsType => {
    const { type } = get(credentialsState);
    return type;
  },
});

export const connectionUrl = selector({
  key: "connectionUrl",
  get: ({ get }): string | null => {
    const { type, value } = get(credentialsState);
    const { baseUri = "", protocol = "" } = get(configState);

    if (!baseUri || !protocol) {
      return null;
    }

    const authType = type === ECredentialsType.ACCESS_CODE ? "HOST" : "GUEST";
    const encodedCredentials = type === ECredentialsType.ACCESS_CODE ? encodeURIComponent(value) : value;

    return !!encodedCredentials
      ? `${protocol}${baseUri}?Authorization=${authType}:${encodedCredentials}`
      : null;
  },
});
