import { Container, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { imprintRoute, privacyPolicyRoute, termsAndConditionsRoute } from "../legal";
import { TextButton } from "../shared";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";
import styled from "styled-components";

type Props = { selected: boolean; theme: Theme };

const NavItem = styled(TextButton).attrs({ size: "small" })<Props>`
  color: ${(props) => (props.selected ? props.theme.palette.grey[700] : props.theme.palette.grey[500])};
  margin-left: ${(props) => props.theme.spacing(1)};
`;
export const BottomNav: React.FC = () => {
  const { t } = useTranslation(["routes"]);
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const routes = [privacyPolicyRoute(t), termsAndConditionsRoute(t), imprintRoute(t)];

  if (!isDesktop) {
    return null;
  }

  return (
    <Fragment>
      <Container
        maxWidth="lg"
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: theme.spacing(4),
          padding: `${theme.spacing(2)} 0`,
        }}
      >
        {routes.map((route) => (
          <NavItem
            data-test-id={`bottom-nav-${route.id}`}
            key={`bottom-nav-${route.fullPath}`}
            onClick={() => navigate(route.fullPath)}
            selected={pathname === route.fullPath}
            theme={theme}
          >
            {route.name}
          </NavItem>
        ))}
      </Container>
    </Fragment>
  );
};
