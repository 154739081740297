import { Button } from "@material-ui/core";
import styled from "styled-components";

export const SecondaryButton = styled(Button).attrs({
  color: "secondary",
  disableElevation: true,
  size: "large",
  variant: "outlined",
})`
  text-transform: none;
  width: 100%;
`;
