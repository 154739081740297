import { gameData } from "../game-data/state";
import { setJapaneseCss } from "../shared";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect } from "react";

export const LanguageWrapper: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  const { language: gameLanguage } = useRecoilValue(gameData);
  const language = gameLanguage?.toLowerCase() ?? "";
  const navigate = useNavigate();

  useEffect(() => {
    if (language && i18n.language !== language) {
      i18n.changeLanguage(language);
      navigate("/");
    }

    if (language === "ja-JP" || language === "ja" || language === "jp") {
      setJapaneseCss();
    }
  }, [i18n, language, navigate]);

  return <Fragment>{children}</Fragment>;
};
