import { MarginalElement } from "./marginal-element";
import { TimeLink } from "./time-link";
import React, { Fragment } from "react";

export type TimeProps = {
  children?: string;
  marginalHeader?: string;
  marginalText?: string;
  value?: string;
};

export const Time: React.FC<TimeProps> = ({ children, marginalHeader, marginalText, value }) => {
  return (
    <Fragment>
      <TimeLink>{value || children}</TimeLink>
      {marginalText && (
        <MarginalElement title={marginalHeader ?? value ?? children ?? ""} content={marginalText} />
      )}
    </Fragment>
  );
};
