import { Actions } from "./actions";
import { CharacterCardHeader } from "./character-card-header";
import { CharacterContent } from "./character-card-content";
import { IAssignedCharacter, IGameCharacter, ISuspect, IVictim } from "../../game-data";
import { TextButton } from "../buttons/text-button";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

export interface ICharacterCardAction {
  action: (...args: any[]) => void;
  label: string;
  role: "primary" | "secondary";
}

export const Container = styled.li`
  border-bottom: 1px dashed var(--ion-color-step-350);

  &:last-child {
    border-bottom: none;
  }
`;

const Paragraph = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`;

type Props = {
  actions?: ICharacterCardAction[];
  character: IAssignedCharacter | ISuspect | IVictim | IGameCharacter;
  isVictim?: boolean;
  noPlayer?: boolean;
};

export const CharacterCard: React.FC<Props> = ({ actions = [], character, isVictim, noPlayer }) => {
  const { name, age, description } = character;
  const { t } = useTranslation(["matchAdmin", "common"]);
  const theme = useTheme();
  const getSubHeadline = () => {
    if (isVictim) {
      return "";
    }

    if (noPlayer) {
      return t("characterCard.subtitle.absent", "Abwesend");
    }

    if ("playerName" in character) {
      return character["playerName"];
    }

    return "";
  };

  const subHeadline = getSubHeadline();
  const illustration = "illustration" in character ? character.illustration : "";

  return (
    <Container>
      <CharacterCardHeader age={age} illustration={illustration} name={name} subHeadline={subHeadline} />
      <CharacterContent>
        <ReactMarkdown components={{ p: ({ node, ...props }) => <Paragraph {...props} /> }}>
          {description}
        </ReactMarkdown>
      </CharacterContent>
      <Actions style={{ paddingBottom: actions.length === 0 ? theme.spacing(1) : 0 }}>
        {actions.map(({ action, label, role }) => (
          <TextButton color={role} onClick={action}>
            {label}
          </TextButton>
        ))}
      </Actions>
    </Container>
  );
};
