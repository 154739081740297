import { canUseAnimation } from "./can-use-animation";
import { createTheme as createMuiTheme } from "@material-ui/core";
import { latinThemeDefaults } from "./defaults";

export const createTheme = (primaryColor: string, tertiaryColor: string) =>
  createMuiTheme({
    ...latinThemeDefaults,
    palette: {
      ...latinThemeDefaults.palette,
      primary: {
        ...latinThemeDefaults.palette?.primary,
        main: primaryColor,
        light: tertiaryColor,
      },
    },
    ...(!canUseAnimation && {
      transitions: {
        create: () => "none",
        duration: {
          complex: 0,
          enteringScreen: 0,
          leavingScreen: 0,
          short: 0,
          shorter: 0,
          shortest: 0,
          standard: 0,
        },
      },
    }),
  });
