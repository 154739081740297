import { isIE } from "react-device-detect";
import { UnsupportedBrowser } from "./unsupported-browser";
import React, { Fragment } from "react";

export const UnsupportedBrowserWrapper: React.FC = ({ children }) => {
  if (isIE) {
    return <UnsupportedBrowser />;
  }

  return <Fragment>{children}</Fragment>;
};
