import { InlineButton } from "../buttons/inline-button";
import React from "react";

type Props = { color?: any; path: string; show: boolean };

export const ConditionalInlineLink: React.FC<Props> = ({ children, color, path, show }) => {
  if (!show) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <InlineButton color={color} to={path}>
      {children}
    </InlineButton>
  );
};
