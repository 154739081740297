import { FormControl, FormControlLabel, Radio, RadioGroup, Theme, useTheme } from "@material-ui/core";
import { useField } from "formik";
import React from "react";
import styled from "styled-components";

type Props = {
  ariaLabel?: string;
  name: string;
  options: { value: any; label: string }[];
  parser?: (value: string) => any;
};

const StyledFormControlLabel = styled(FormControlLabel)<{ theme: Theme }>`
  border-bottom: 1px dashed ${(props) => props.theme.palette.grey[400]};
  justify-content: space-between;
  margin-left: 0;

  span.MuiFormControlLabel-label {
    font-family: ${(props) => props.theme.typography.fontFamily};
    font-size: 1rem;
  }

  span.MuiIconButton-label {
    margin-left: 0;
    color: ${(props) => props.theme.palette.common.black};
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const FormikRadioGroup: React.FC<Props> = ({ ariaLabel = "", name, options = [], parser }) => {
  const theme = useTheme();
  const [field, meta, helpers] = useField(name); // eslint-disable-line @typescript-eslint/no-unused-vars
  const { value } = field;
  const { setValue } = helpers;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parser ? parser(event.target.value) : event.target.value;
    setValue(value);
  };

  return (
    <FormControl component="fieldset" style={{ width: "100%" }}>
      <RadioGroup aria-label={ariaLabel} name={name} value={value} onChange={handleChange}>
        {options.map(({ label, value }) => (
          <StyledFormControlLabel
            control={<Radio />}
            key={value}
            label={label}
            labelPlacement="start"
            theme={theme}
            value={value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
