import { Logo, PageTitle, Paragraph } from "../shared";
import { useTranslation, Trans } from "react-i18next";
import React, { Fragment } from "react";

export const UnsupportedBrowser: React.FC = () => {
  const { t } = useTranslation(["access"]);

  return (
    <Fragment>
      <Logo />

      <PageTitle>{t("unsupportedBrowser.title", "Browser nicht unerstützt")}</PageTitle>

      <Trans ns="access" i18nKey="unsupportedBrowser.copy">
        <Paragraph>
          Leider wird dein Browser nicht unterstützt, da Microsoft dessen Weiterentwicklung eingestellt
          hat.
        </Paragraph>

        <Paragraph>
          Um unsere Spiele zu benutzen, installiere bitte einen aktuellen, sicheren Browser wie z. B.
          Firefox, Google Chrome, Apple Safari oder Microsoft Edge.
        </Paragraph>
      </Trans>
    </Fragment>
  );
};
