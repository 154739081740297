import { Box } from "@material-ui/core";
import {
  Logo,
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { Trans, useTranslation } from "react-i18next";
import { useLogout } from "../authentication/use-logout";
import React from "react";

export const MatchEndedNotice: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["access"]);
  const logOut = useLogout(false);

  return (
    <PagePadding>
      <WidthWrapper>
        <Box sx={{ mb: 6, justifyContent: "center", display: "flex" }}>
          <Logo />
        </Box>

        <PageTitle>{t("matchEnded.title", "Vielen Dank")}</PageTitle>

        <Trans ns="access" i18nKey="matchEnded.copy">
          <Paragraph>
            Dein Spiel ist zu Ende. Vielen Dank fürs Mitspielen – wir hoffen, dich bald wiederzusehen.
          </Paragraph>

          <Paragraph>
            Wir haben deine Daten gelöscht und speichern lediglich, dass dein Zugangscode verwendet
            wurde.
          </Paragraph>
        </Trans>

        <Box sx={{ mt: 2 }}>
          <PrimaryButton onClick={logOut}>
            {t("matchEnded.enterNewCodeButtonLabel", "Einen anderen Zugangscode eingeben")}
          </PrimaryButton>
        </Box>
      </WidthWrapper>
    </PagePadding>
  );
};
