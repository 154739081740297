import {
  ExternalLink,
  H2,
  H3,
  PagePadding,
  PageTitle,
  Paragraph,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { Trans, useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core";
import React from "react";

export const PrivacyPolicy: React.FC = () => {
  useHasRenderedClass();
  const theme = useTheme();
  const { t } = useTranslation(["legal", "common"]);

  return (
    <PagePadding>
      <WidthWrapper>
        <PageTitle>
          {t("privacyPolicy.title", "Datenschutzerklärung Culinario Mortale® DIGITAL")}
        </PageTitle>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.copy1">
            Wir freuen uns über dein Interesse an unserem Unternehmen und unseren Produkten. Der Schutz
            deiner personenbezogenen Daten ist uns ein wichtiges Anliegen. Deshalb möchten wir dich mit
            dieser Datenschutzerklärung über die Art, den Zweck, die Verwendung und den Schutz
            personenbezogener Daten durch die Bischoff, Kauffmann & Otani GbR beim Betrieb unseres
            digitalen Spieleangebots auf der Internetseite unter „
            <ExternalLink
              theme={theme}
              href={t("common:domain.root", "https://digital.culinario-mortale.de")}
            >
              https://digital.culinario-mortale.de
            </ExternalLink>
            “ (im Folgenden „Culinario Mortale DIGITAL“ genannt) informieren.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.copy2">
            Solltest du unseren Webshop unter{" "}
            <ExternalLink
              theme={theme}
              href={t("common:domain.website", "https://www.culinario-mortale.de")}
            >
              https://www.culinario-mortale.de
            </ExternalLink>{" "}
            nutzen , findest du die zugehörige Datenschutzerklärung unter{" "}
            <ExternalLink
              theme={theme}
              href={t(
                "common:domain.websitePrivacyPolicy",
                "https://www.culinario-mortale.de/datenschutz"
              )}
            >
              https://www.culinario-mortale.de/datenschutz
            </ExternalLink>
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.copy3">
            Die Verarbeitung deiner personenbezogenen Daten in Culinario Mortale DIGITAL erfolgt stets im
            Einklang mit der DSGVO und in Übereinstimmung mit den für uns geltenden nationalen
            Datenschutzbestimmungen. Die Datenverarbeitung erfolgt entweder auf einer gesetzlichen
            Grundlage oder aber wir holen deine Einwilligung ein.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.copy4">
            Wir haben zum Schutz deiner Daten und deren Vertraulichkeit, Verfügbarkeit und Integrität
            angemessene technische und organisatorische Maßnahmen ergriffen und die von uns beauftragten
            Dienstleister daraufhin überprüft und vertraglich verpflichtet, ein vergleichbar angemessenes
            Schutzniveau sicherzustellen.
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="privacyPolicy.terms.title">
            1. Begriffsbestimmungen
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.terms.copy">
            Unsere Datenschutzerklärung verwendet die Begriffe, wie sie in der
            Datenschutz-Grundverordnung definiert sind (Artikel 4 DSGVO).
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="privacyPolicy.responsibility.title">
            2. Verantwortung
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.responsibility.copy">
            Verantwortlich für die Erhebung, Verarbeitung und Nutzung deiner personenbezogenen Daten im
            Sinne der Datenschutz-Grundverordnung ist die
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.responsibility.copy2">
            Bischoff, Kauffmann & Otani GbR,
            <br />
            Württembergallee 29,
            <br />
            14052 Berlin,
            <br />
            Deutschland.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.responsibility.copy3">
            Die Bischoff, Kauffmann & Otani GbR wird durch die Geschäftsführer Florian Norbert Bischoff,
            Jens Kauffmann und Takeshi Otani vertreten und ist wie folgt zu erreichen:
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.responsibility.copy4">
            Tel.: +49 (0) 30 25 29 21 51
            <br />
            E-Mail: mail@culinario-mortale.de
            <br />
            Website: www.culinario-mortale.de
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="privacyPolicy.dataProtectionOfficer.title">
            3. Datenschutzbeauftragter
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.dataProtectionOfficer.copy">
            Die Bischoff, Kauffmann & Otani GbR hat keinen Datenschutzbeauftragten bestellt und ist dazu
            gesetzlich auch nicht verpflichtet. Da Datenschutz für uns ein wichtiges Anliegen und damit
            Chefsache ist, kannst du dich in Sachen Datenschutz gerne an die Geschäftsführer wenden, die
            dein Anliegen mit hoher Priorität und kompetent bearbeiten.
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.title">
            4. Datenverarbeitungen
          </Trans>
        </H2>
        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.copy">
            Beim Betrieb von Culinario Mortale DIGITAL werden deine personenbezogenen Daten von uns
            verarbeitet, um dir die Durchführung eines digitalen Culinario Mortale Krimidinners zu
            ermöglichen, Hilfestellung und Support zu leisten und, falls von dir gewünscht, zu sonstigen
            Zwecken mit dir in Kontakt zu treten. Die Verarbeitung personenbezogener Daten findet dabei
            auch statt, um unser Angebot überhaupt verfügbar zu machen und unser Angebot sicher und
            effizient zu betreiben. Deine personenbezogene Daten werden von uns ausschließlich für diese
            Zwecke verarbeitet.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.copy2">
            Wir speichern personenbezogene Daten nur so lange, bis der Zweck Ihrer Speicherung erfüllt
            ist oder dies durch gesetzliche Aufbewahrungspflichten vorgeschrieben ist. Ist der Zweck der
            Speicherung erfüllt bzw. entfallen und sind alle gesetzlichen Aufbewahrungsfristen
            ausgelaufen, werden die gespeicherten Daten von uns gelöscht.
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.availability.title">
            4.1 Verfügbarkeit des Angebots
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.availability.copy">
            Um dir Culinario Mortale DIGITAL verfügbar zu machen, grundlegenden Funktionen und den
            einwandfreien Betrieb zu ermöglichen, ist es technisch erforderlich, personenbezogene Daten
            von dir zu verarbeiten. So werden z.B. bei der Verbindung zwischen Endgerät und den Servern,
            auf dem unsere Texte, Medien (z. B. Bilder) und Schriftarten gehostet werden, automatisch die
            IP-Adresse des genutzten Endgerätes, die Kennung des genutzten Endgerätes, das verwendete
            Betriebssystem und der Browser verarbeitet, um einen sicheren Verbindungsaufbau zu
            gewährleisten.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.availability.copy2">
            Um Culinario Mortale DIGITAL zuverlässig und sicher zu betreiben verwenden wir ein
            Content-Delivery-Network (CDN). Hierbei handelt es sich um ein Netzwerk aus Servern, die
            Inhalte und Medien an mehreren Orten auf der ganzen Welt zwischenspeichern. Bei Nutzung
            unserer Angebote wird eine Verbindung zwischen deinem Endgerät und den Servern unseres CDN
            aufgebaut.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.availability.copy3">
            Um die Spielmaterialien in Culinario Mortale DIGITAL korrekt darzustellen, verwenden wir zur
            Anzeige von Schriften den Dienst Adobe Fonts. Zu diesem Zweck wird bei der Nutzung unserer
            Angebote eine Verbindung zwischen dem von dir genutzten Endgerät und den Servern von Adobe
            Fonts hergestellt. Dabei werden automatisch die IP-Adresse des genutzten Endgerätes, die
            Kennung des genutzten Endgerätes, das verwendete Betriebssystem und der Browser übertragen
            und verarbeitet.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.availability.copy4">
            Diese Daten werden, je nachdem, von welchem Standort aus du auf unsere Angebote zugreifst,
            auch an Server in sichere Drittländer gesendet. Sichere Drittländer sind solche, denen die
            Europäische Kommission ein angemessenes Datenschutzniveau bestätigt hat und deren nationale
            Gesetzgebung einen Schutz von personenbezogenen Daten garantiert, der mit dem des EU-Rechts
            vergleichbar ist.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.availability.copy5">
            Dabei handelt es sich zwar um Gerätedaten, mit diesen Daten kann aber möglicherweise der
            Bezug zu dir hergestellt werden.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.availability.copy6">
            Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 b) DSGVO (Vertragserfüllung).
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.title">
            4.2 Nutzung des Angebotes als Gastgeber
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.copy">
            Bei der Nutzung von Culinario Mortale DIGITAL als Gastgeber kannst du dich mit deinem
            individuellen Zugangscode unter{" "}
            <ExternalLink
              theme={theme}
              href={t("common:domain.root", "https://digital.culinario-mortale.de")}
            >
              https://digital.culinario-mortale.de
            </ExternalLink>{" "}
            anmelden.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.copy2">
            Ein Zugangscode ist in jedem Culinario Mortale Krimidinner Spiel enthalten, dass du auf{" "}
            <ExternalLink
              theme={theme}
              href={t("common:domain.website", "https://www.culinario-mortale.de")}
            >
              www.culinario-mortale.de
            </ExternalLink>{" "}
            oder im Handel erwerben kannst. Der Zugangscode ist zufallsgeneriert und erlaubt keinen
            Rückschluss oder Bezug auf deine Person.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.copy3">
            Bei der Erstellung eines Krimidinners bitten wir dich folgende Daten anzugeben:
          </Trans>
        </Paragraph>

        <ul>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.copy4">
            <li style={{ fontStyle: "normal" }}>deinen Zugangscode</li>
            <li style={{ fontStyle: "normal" }}>deinen Vorname</li>
            <li style={{ fontStyle: "normal" }}>
              die Anzahl der Personen, die am Krimidinner teilnehmen
            </li>
            <li style={{ fontStyle: "normal" }}>die Vornamen der Teilnehmer</li>
          </Trans>
        </ul>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.copy5">
            Wir speichern und verarbeiten diese Daten auf unseren Systemen, um dir und deinen Gästen die
            benötigten Texte und Materialien für das Krimidinner bereitzustellen.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.copy6">
            Sobald du die letzte Runde (Runde 3) des Krimidinners abrufst und für deine Gäste freigibst,
            sehen wir das Krimidinner als beendet an. Wir löschen die o. g. Daten innerhalb von 48
            Stunden nach Ende deines Krimidinners und speichern lediglich, dass dein Zugangscode
            verwendet wurde, sodass damit keine weitere Spielpartie erstellt werden kann.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.copy7">
            Solltest du dein Krimidinner innerhalb von 30 Tagen nicht beenden, löschen wir dann alle o.
            g. Daten.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.host.copy8">
            Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 b) DSGVO (Vertragserfüllung).
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.guest.title">
            4.3 Nutzung des Angebotes als Gast
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.guest.copy">
            Als Gast kannst du unter Nutzung des Links, den du vom Gastgeber erhalten hast, die für dich
            bestimmten Spielinformationen (Spielregeln, Rollenbeschreibung, Hinweisrunden) aufrufen und
            an einer vom Gastgeber erstellten Spielpartie teilnehmen.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.guest.copy2">
            Der Link, den du vom Gastgeber erhalten hast, enthält einen individuellen Zugangscode
            (Token). Dieser ermöglicht es uns, für die Dauer des Spiels deine Nutzung von Culinario
            Mortale DIGITAL einer vom Gastgeber erstellten Spielpartie zuzuordnen. Auf diese Weise können
            wir dich beispielsweise über den Beginn einer neuen Spielrunde informieren.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.guest.copy3">
            Wir löschen deinen individuellen Link zur Spielpartie innerhalb von 48 Stunden nach Ende des
            Krimidinners. Sollte das Krimidinner innerhalb von 30 Tagen nicht beendet werden, wird dein
            Zugangslink ebenfalls gelöscht.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.guest.copy4">
            Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 b) DSGVO (Vertragserfüllung).
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.support.title">
            4.4 Kundenbetreuung und Support
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.support.copy">
            Für die Kundenbetreuung kannst du uns per E-Mail kontaktieren. Eine entsprechende
            E-Mail-Adresse weisen wir zu diesem Zweck an entsprechenden Stellen auf den Seiten unseres
            Angebots aus. Die personenbezogenen Daten, die du uns bei einer Anfrage per E-Mail mitteilst
            (z. B. Name, E-Mail-Adresse, Telefonnummer) werden ausschließlich zur Korrespondenz mit dir
            und nur für den Zweck verarbeitet, zu dem du uns die Daten zur Verfügung gestellt hast.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.processing.support.copy2">
            Die Rechtsgrundlagen für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 b) DSGVO
            (Vertragserfüllung).
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.title">
            5. Deine Rechte
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.copy">
            Die folgend aufgeführten Rechte kannst du uns gegenüber geltend machen. Bitte sende uns dafür
            eine E-Mail an mail@culinario-mortale.de. Bitte beachte, dass wir zur Geltendmachung deiner
            Rechte deine Identität überprüfen und feststellen müssen.
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.object.title">
            Widerrufsrecht
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.object.copy">
            Du hast das Recht, deine Einwilligung zur Datenverarbeitung jederzeit zu widerrufen. Dieses
            Recht gilt mit Wirkung für die Zukunft. Die bis zur Rechtskraft des Widerrufs erhobenen Daten
            bleiben hiervon unberührt.
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.portability.title">
            Recht auf Datenübertragbarkeit
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.portability.copy">
            Du hast das Recht, eine Übertragung deiner Daten von uns auf eine andere Stelle zu
            beantragen.
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.access.title">
            Recht auf Auskunft
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.access.copy">
            Du hast das Recht, Auskunft über deine Daten zu verlangen.
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.rectification.title">
            Recht auf Berichtigung, Löschung oder Sperrung
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.rectification.copy">
            Du hast das Recht, deine Daten berichtigen, löschen oder sperren zu lassen. Letzteres kommt
            zur Anwendung, wenn die gesetzliche Lage eine Löschung nicht zulässt.
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.restriction.title">
            Recht auf Einschränkung der Verarbeitung
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.restriction.copy">
            Du hast das Recht, von uns die Einschränkung der Verarbeitung zu verlangen.
          </Trans>
        </Paragraph>

        <H3>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.complain.title">
            Beschwerderecht
          </Trans>
        </H3>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.complain.copy">
            Du hast das Recht, dich zu beschweren, insofern du einen Grund zur Beanstandung haben
            solltest.
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.copy2">
            Du hast darüber hinaus das Recht, dich mit deinem Anliegen an die zuständige Aufsichtsbehörde
            zu richten. Das ist die:
          </Trans>
        </Paragraph>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.rights.copy3">
            Berliner Beauftragte für Datenschutz und Informationsfreiheit
            <br />
            Friedrichstr. 219
            <br />
            10969 Berlin
            <br />
            Tel.: +49 30 13889-0
            <br />
            Fax: +49 30 2155050
            <br />
            E-Mail: mailbox@datenschutz-berlin.de
          </Trans>
        </Paragraph>

        <H2>
          <Trans ns="legal" i18nKey="privacyPolicy.changes.title">
            6. Änderung der Datenschutzerklärung
          </Trans>
        </H2>

        <Paragraph>
          <Trans ns="legal" i18nKey="privacyPolicy.changes.copy">
            Wir als Verantwortliche behalten es uns vor, die Datenschutzerklärung jederzeit an geänderte
            Verarbeitungen und die geltenden Datenschutzbestimmungen anzupassen. Derzeitiger Stand ist
            Mai 2020.
          </Trans>
        </Paragraph>
      </WidthWrapper>
    </PagePadding>
  );
};
