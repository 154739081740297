import { Box } from "@material-ui/core";
import { characterReadsPrologueSelector, ERound, matchData, prologueReadBySelector } from "../game-data";
import {
  getRoundNumber,
  getStatusForRound,
  PagePadding,
  PageTitle,
  Paragraph,
  PrimaryButton,
  TechnicalHint,
  useHasRenderedClass,
  WidthWrapper,
} from "../shared";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { WS } from "../websocket";
import React, { Fragment } from "react";

type Props = { round: ERound };

export const UnlockRound: React.FC<Props> = ({ round }) => {
  useHasRenderedClass();
  const { t } = useTranslation(["rounds"]);
  const playerReadsPrologue = useRecoilValue(characterReadsPrologueSelector);
  const prologueReadBy = useRecoilValue(prologueReadBySelector);
  const roundNumber = getRoundNumber(round);
  const setMatchData = useSetRecoilState(matchData);

  const prologueCharacterName = prologueReadBy?.name;
  const prologuePlayerName = prologueReadBy?.playerName;

  const unlock = () => {
    const status = getStatusForRound(round);
    setMatchData((currVal) => ({ ...currVal, status }));
    WS.send(JSON.stringify({ action: "openRound", data: { round } }));
  };

  return (
    <Fragment>
      <PagePadding>
        <WidthWrapper>
          <PageTitle subtitle={t("closedRound.subtitle", "Am Spielabend")}>
            <Trans ns="rounds" i18nKey="closedRound.host.title">
              Runde {{ roundNumber }} öffnen
            </Trans>
          </PageTitle>

          <Paragraph>
            <Trans ns="rounds" i18nKey="closedRound.host.copy">
              Damit alle Spieler Runde {{ roundNumber }} lesen können, musst du diese als Gastgeber
              freischalten. Dies sollte erst am Spielabend geschehen.
            </Trans>
          </Paragraph>

          {round === ERound.FIRST && (
            <Paragraph>
              {playerReadsPrologue &&
                t(
                  "closedRound.host.copy2",
                  "Bitte stelle zuvor sicher, dass du den Prolog vorgelesen hat."
                )}
              {!playerReadsPrologue && (
                <Trans ns="rounds" i18nKey="closedRound.host.copy3">
                  Bitte stelle zuvor sicher, dass {{ prologueCharacterName }} ({{ prologuePlayerName }})
                  den Prolog vorgelesen hat. Der Spieler findet diesen in seinem Menü.
                </Trans>
              )}
            </Paragraph>
          )}

          <Paragraph>
            {t(
              "closedRound.host.copy4",
              "Anschließend können alle Spieler (dich mit eingeschlossen) ihre geheimen Hinweise lesen."
            )}
          </Paragraph>

          <Box sx={{ mt: 5, mb: 2 }}>
            <PrimaryButton data-test-id="host-unlock-round-btn" onClick={unlock}>
              <Trans ns="rounds" i18nKey="closedRound.host.button.label">
                Runde {{ roundNumber }} freischalten
              </Trans>
            </PrimaryButton>
          </Box>

          {round === ERound.THIRD && (
            <TechnicalHint>
              {t(
                "closedRound.host.hint",
                "Nachdem du Runde 3 geöffnet hast, kannst du das Spiel nicht mehr löschen oder neu starten. Das Spiel wird automatisch nach 24 Stunden beendet und alle deine Daten werden gelöscht."
              )}
            </TechnicalHint>
          )}
        </WidthWrapper>
      </PagePadding>
    </Fragment>
  );
};
