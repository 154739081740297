import { atom, DefaultValue } from "recoil";
import { IRoundsState } from "./rounds.declarations";

export const hintsEffect = () => ({ setSelf, onSet }: any) => {
  const matchId = localStorage.getItem("cm-online-match-id");
  const key = matchId ? `cm-online-hints-${matchId}` : "";
  let savedValue: string | null = null;

  if (!!key) {
    savedValue = localStorage.getItem(key);
  }

  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet((newValue: any) => {
    if (!!key && newValue instanceof DefaultValue) {
      localStorage.removeItem(key);
    } else if (!!key) {
      localStorage.setItem(key, JSON.stringify(newValue));
    } else {
      // do nothing
    }
  });
};

export const hintsState = atom<Partial<IRoundsState>>({
  key: "hintsState",
  default: {
    FIRST: {
      isHintRead: false,
    },
    SECOND: {
      isHintRead: false,
    },
    THIRD: {
      isHintRead: false,
    },
    hostSolutionWarningRead: false,
  },
  effects_UNSTABLE: [hintsEffect()],
});
