import { authStatusState, EAuthStatus } from "../authentication";
import { createTheme } from "../theme";
import { EMatchStatus, gameData, matchStatusSelector } from "../game-data";
import { Theme, ThemeProvider } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import React from "react";

export const StylesWrapper: React.FC = ({ children }) => {
  const status = useRecoilValue(authStatusState);
  const matchStatus = useRecoilValue(matchStatusSelector);
  const { gameId = "" } = useRecoilValue(gameData);

  let theme: Theme = createTheme("#222126", "#666666");

  if (status === EAuthStatus.SIGNED_IN && matchStatus !== EMatchStatus.ENDED) {
    if (gameId.startsWith("CM01")) {
      theme = createTheme("#0087c8", "#f0f7f9");
    }

    if (gameId.startsWith("CM02")) {
      theme = createTheme("#dc323c", "#f9eeef");
    }

    if (gameId.startsWith("CM03")) {
      theme = createTheme("#91502d", "#f5ede5");
    }

    if (gameId.startsWith("CM04")) {
      theme = createTheme("#78b432", "#f2fae9");
    }

    if (gameId.startsWith("CM05")) {
      theme = createTheme("#82a5be", "#edf3f7");
    }

    if (gameId.startsWith("CM06")) {
      theme = createTheme("#cda56e", "#f9f0da");
    }

    if (gameId.startsWith("CM07")) {
      theme = createTheme("#ce857e", "#f7ebea");
    }

    if (gameId.startsWith("CM08")) {
      theme = createTheme("#ad8e64", "#f7ecd4");
    }

    if (gameId.startsWith("CM08")) {
      theme = createTheme("#ad8e64", "#f7ecd4");
    }

    if (gameId.startsWith("CM09")) {
      theme = createTheme("#016F6C", "#E6F0F0");
    }
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
