import { authStatusState, credentialsState } from "./auth.state";
import { configState } from "../config";
import { EAuthStatus } from "./auth-status.enum";
import { ECredentialsType } from "./credentials-type.enum";
import { EnterSupportCode } from "./enter-support-code";
import { LoadingSpinner } from "../shared";
import { useRecoilValue } from "recoil";
import React, { Fragment, useEffect } from "react";

export const AuthWrapper: React.FC = ({ children }) => {
  const { localStorageKey } = useRecoilValue(configState);
  const { type, value } = useRecoilValue(credentialsState);
  const status = useRecoilValue(authStatusState);

  const key = type === ECredentialsType.ACCESS_CODE ? "accessCode" : "token";
  const removalKey = type === ECredentialsType.ACCESS_CODE ? "token" : "accessCode";

  useEffect(() => {
    if (status === EAuthStatus.SIGNED_IN) {
      localStorage.setItem(`${localStorageKey}-${key}`, value);
      localStorage.removeItem(`${localStorageKey}-${removalKey}`);
    }
  }, [key, localStorageKey, removalKey, status, value]);

  if (status === EAuthStatus.SIGNED_IN) {
    return <Fragment>{children}</Fragment>;
  }

  if (status === EAuthStatus.SIGNED_OUT && value) {
    return <LoadingSpinner />;
  }

  return <EnterSupportCode />;
};
