import { AddOutlined } from "@material-ui/icons";
import { CreateMatch } from "./create-match";
import { EMatchStatus } from "../game-data";
import { getRelativeRoutePath } from "../shared";
import { IRoute } from "../navigation";
import { ReactComponent as ViewMatchIcon } from "../assets/icon-view-match.svg";
import { SvgIcon } from "@material-ui/core";
import { ViewMatch } from "./view-match";
import React from "react";

export const createMatchRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => isHost,
  component: <CreateMatch />,
  icon: <AddOutlined />,
  id: "create-match",
  name: t("routes:createMatch.label", "Spiel erstellen"),
  fullPath: t("routes:createMatch.path", "/vorbereitung/spiel-erstellen"),
  relativePath: getRelativeRoutePath(t("routes:createMatch.path", "/vorbereitung/spiel-erstellen")),
  hostOnly: true,
  isVisible: (isHost, { status }) => isHost && (!status || (!!status && status < EMatchStatus.CREATED)),
});

export const viewMatchRoute = (t: Function): IRoute => ({
  canAccess: (isHost, { status }) => isHost,
  component: <ViewMatch />,
  icon: <SvgIcon component={ViewMatchIcon} viewBox="0 0 512 512" />,
  id: "view-match",
  name: t("routes:vieMatch.label", "Dein Spiel"),
  fullPath: t("routes:vieMatch.path", "/vorbereitung/dein-spiel"),
  relativePath: getRelativeRoutePath(t("routes:vieMatch.path", "/vorbereitung/dein-spiel")),
  hostOnly: true,
  isVisible: (isHost, { status }) => isHost && !!status && status >= EMatchStatus.CREATION_PENDING,
});
