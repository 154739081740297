import { createMatchRoute, viewMatchRoute } from "../match-admin";
import { IRoute, Redirect, SubNav } from "../navigation";
import { Route, Routes } from "react-router";
import { useTranslation } from "react-i18next";
import { welcomeRoute, gameRulesRoute, structureOfTheGameRoute } from "./routes";
import React, { Fragment } from "react";

export const RulesEntry: React.FC = () => {
  const { t } = useTranslation(["routes"]);
  const createMatch = createMatchRoute(t);
  const routes: IRoute[] = [
    welcomeRoute(t),
    gameRulesRoute(t),
    structureOfTheGameRoute(t),
    createMatchRoute(t),
    viewMatchRoute(t),
  ];

  return (
    <Fragment>
      <SubNav additionalRoutes={[createMatch.fullPath + "/*"]} routes={routes} />

      <Routes>
        <Route path="/" element={<Redirect to={welcomeRoute(t).fullPath} />} />
        {routes.map((route) => (
          <Route key={route.fullPath} path={route.relativePath} element={route.component} />
        ))}

        <Route path={`${createMatchRoute(t).relativePath}/*`} element={createMatchRoute(t).component} />
      </Routes>
    </Fragment>
  );
};
