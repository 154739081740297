import { Properties } from "csstype";
import { Theme, useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const ContainerElement = styled.li<{ theme: Theme }>`
  border-bottom: 1px dashed ${(props) => props.theme.palette.grey[400]};

  &:last-child {
    border-bottom: none;
  }
`;

type Props = { style?: Properties };

export const CharacterCardContainer: React.FC<Props> = ({ children, style = {} }) => {
  const theme = useTheme();

  return (
    <ContainerElement style={style} theme={theme}>
      {children}
    </ContainerElement>
  );
};
