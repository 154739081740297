import { useMediaQuery, useScrollTrigger, useTheme } from "@material-ui/core";
import React, { Fragment } from "react";

type Props = { children: React.ReactElement };

export const HideOnScroll: React.FC<Props> = ({ children }) => {
  const trigger = useScrollTrigger();
  const theme = useTheme();
  const isAtLeastTablet = useMediaQuery(theme.breakpoints.up("sm"));

  if (isAtLeastTablet) {
    return <Fragment>{children}</Fragment>;
  }

  return React.cloneElement(children, {
    style: {
      transform: trigger ? "translateY(-56px)" : "translateY(0px)",
      transition: theme.transitions.create("transform"),
    },
  });
};
