import { Properties } from "csstype";
import { Theme, useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

export const ActionsElement = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: ${(props) => props.theme.spacing(1.5)};
  padding-bottom: ${(props) => props.theme.spacing(0.5)};
`;

type Props = { style?: Properties };

export const Actions: React.FC<Props> = ({ children, style = {} }) => {
  const theme = useTheme();

  return (
    <ActionsElement style={style} theme={theme}>
      {children}
    </ActionsElement>
  );
};
