import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = { to: string };

export const Redirect: React.FC<Props> = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => navigate(to));
  return null;
};
