import { backgroundRoute } from "../your-role";
import {
  characterReadsPrologueSelector,
  EMatchStatus,
  matchStatusSelector,
  prologueReadBySelector,
} from "../game-data";
import { ConditionalInlineLink, GameHint, InlineButton } from "../shared";
import { credentialsType } from "../authentication";
import { ECredentialsType } from "../authentication/credentials-type.enum";
import { gameRulesRoute, structureOfTheGameRoute } from "./routes";
import { prologueRoute } from "../rounds";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import React from "react";

type Props = { label?: string; matchedPath: string; pathname: string };

const LinkOrText: React.FC<Props> = ({ children, label, matchedPath, pathname }) => {
  const { t } = useTranslation(["rules"]);

  if (matchedPath === pathname) {
    return (
      <React.Fragment>
        {children || label} {t("commonHint.currenttext", "(dieser Text)")}
      </React.Fragment>
    );
  }

  return <InlineButton to={pathname}>{children || label}</InlineButton>;
};

export const CommonRulesText: React.FC = () => {
  const { t } = useTranslation(["rules", "common"]);
  const { pathname } = useLocation();

  const type = useRecoilValue(credentialsType);
  const isHost = type === ECredentialsType.ACCESS_CODE;
  const matchStatus = useRecoilValue(matchStatusSelector);
  const prologueReadBy = useRecoilValue(prologueReadBySelector);
  const characterReadsPrologue = useRecoilValue(characterReadsPrologueSelector);

  let prologueHint: string;

  if (matchStatus < EMatchStatus.CREATED) {
    prologueHint = prologueReadBy?.name ?? "";
  } else if (characterReadsPrologue) {
    prologueHint = t("common:you", "du");
  } else {
    prologueHint = `${prologueReadBy?.name} (${prologueReadBy?.playerName})`;
  }

  const hostNotice =
    !characterReadsPrologue && isHost
      ? t("commonHint.hostNotice", "Der Spieler findet diesen in seinem Menü.")
      : "";

  const playerPrologueNotice = characterReadsPrologue
    ? t("commonHint.playerPrologueNotice", "Diesen findest du in deinem Menü.")
    : "";

  return (
    <GameHint>
      <Trans ns="rules" i18nKey="commonHint.body">
        Bitte lest am Spielabend nochmal gemeinsam die Abschnitte{" "}
        <LinkOrText matchedPath={pathname} pathname={gameRulesRoute(t).fullPath ?? "/"}>
          Spielregeln
        </LinkOrText>
        ,{" "}
        <LinkOrText matchedPath={pathname} pathname={structureOfTheGameRoute(t).fullPath ?? "/"}>
          Spielablauf
        </LinkOrText>{" "}
        und{" "}
        <ConditionalInlineLink
          show={matchStatus >= EMatchStatus.CREATED && pathname !== backgroundRoute(t).fullPath}
          path={backgroundRoute(t).fullPath ?? "/"}
        >
          Vorgeschichte
        </ConditionalInlineLink>
        . Alle Spieler sollen sich mit ein bis zwei Sätzen kurz vorstellen. Anschließend liest{" "}
        {{ prologueHint }} den{" "}
        <ConditionalInlineLink show={characterReadsPrologue} path={prologueRoute(t).fullPath ?? "/"}>
          Prolog
        </ConditionalInlineLink>{" "}
        vor. {{ hostNotice }} {{ playerPrologueNotice }}
      </Trans>
    </GameHint>
  );
};
