import { Add, Close, Map, Timelapse } from "@material-ui/icons";
import { animated, useSpring } from "react-spring";
import { Box, ClickAwayListener, Fab, Theme, useMediaQuery, useTheme, Zoom } from "@material-ui/core";
import { canUseAnimation } from "../theme";
import { FloorPlan } from "./floor-plan";
import { MarginalContext } from "./marginal-context";
import { Overlay, TextButton, Tooltip, useFabOffset } from "../shared";
import { useTranslation } from "react-i18next";
import React, { CSSProperties, Fragment, useState } from "react";
import styled from "styled-components";

type FabMenuInnerProps = { theme: Theme; open: boolean };

const FabMenu = styled.div<FabMenuInnerProps>`
  align-items: center;
  background: ${(props) => props.theme.palette.primary.main};
  border-radius: 28px;
  bottom: 32px;
  color: white;
  display: flex;
  height: 56px;
  justify-content: space-around;
  max-width: ${(props) => (props.open ? "calc(100% - 32px)" : "56px")};
  overflow: hidden;
  position: fixed;
  right: 16px;
  transition: ${(props) =>
    props.theme.transitions.create("max-width", { duration: "shortest", easing: "ease-in-out" })}
  white-space: nowrap;
  width: 100%;
  z-index: 0;
`;

const FabMenuInner = styled.div<{ theme: Theme }>`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(10)};
  width: 100%;
`;

const FabButton = styled(TextButton)<{ theme: Theme }>`
  border-radius: 20px;
  color: white;
  font-size: 1rem;
  padding: ${(props) => `${props.theme.spacing(1)} ${props.theme.spacing(2)}`};
`;

type Props = { hideFab?: boolean; isPageBottom: boolean };

export const MarginalsContainer: React.FC<Props> = ({
  children,
  hideFab = false,
  isPageBottom = false,
}) => {
  const { t } = useTranslation(["rounds", "common"]);
  const theme = useTheme();
  const isAtLeastTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const fabOffset = useFabOffset();

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [margOpen, setMargOpen] = useState(false);
  const [showFloorPlan, setShowFloorPlan] = useState(false);

  const closeSpeedDial = () => setSpeedDialOpen(false);
  const toggleSpeedDial = () => setSpeedDialOpen(!speedDialOpen);
  const toggleMarginal = () => setMargOpen(!margOpen);

  const showMarginal = () => {
    setMargOpen(true);
    closeSpeedDial();
  };

  const hideMarginal = () => {
    setMargOpen(false);
    closeSpeedDial();
  };

  const openFloorPlan = () => {
    setShowFloorPlan(true);
    closeSpeedDial();
  };

  const closeFloorPlan = () => {
    setShowFloorPlan(false);
    closeSpeedDial();
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const offset = parseInt(
    window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--marginal-width")
      .replace("px", "")
  );

  const animation = useSpring({
    to: {
      transform: `translateX(${margOpen ? offset : 0}px)`,
    },
    immediate: !canUseAnimation,
  });

  const fabStyle: CSSProperties = {
    bottom: theme.spacing(4),
    color: "white",
    position: "fixed",
    right: fabOffset,
    zIndex: 1102,
    boxShadow: theme.shadows[3],
  };

  const controls = (
    <Fragment>
      {isAtLeastTablet && (
        <div style={{ position: "relative" }}>
          <Zoom
            in={!margOpen && !showFloorPlan && !hideFab && !isPageBottom}
            style={{ transitionDelay: canUseAnimation ? "300ms" : "0ms" }}
            timeout={transitionDuration}
            unmountOnExit={false}
          >
            <div>
              <Tooltip placement="left" title={t("fab.labels.floorplan") ?? ""}>
                <Fab
                  aria-label={t("fab.labels.floorplan")}
                  color="primary"
                  data-test-id="fab-floorplan"
                  onClick={openFloorPlan}
                  style={fabStyle}
                >
                  <Map />
                </Fab>
              </Tooltip>
            </div>
          </Zoom>
        </div>
      )}

      <ClickAwayListener onClickAway={closeSpeedDial}>
        <div style={{ position: "relative" }}>
          <Zoom
            exit={margOpen}
            in={!isAtLeastTablet && !margOpen && !isPageBottom && !showFloorPlan && !hideFab}
            mountOnEnter
            style={{ transitionDelay: canUseAnimation ? "300ms" : "0ms" }}
            timeout={transitionDuration}
            unmountOnExit
          >
            <div>
              <FabMenu data-test-id="fab" open={speedDialOpen} theme={theme}>
                <FabMenuInner theme={theme}>
                  <Box sx={{ mr: 1 }}>
                    <FabButton
                      data-test-id="fab-inner-button-floorplan"
                      color="inherit"
                      onClick={openFloorPlan}
                      startIcon={<Map />}
                      theme={theme}
                    >
                      {t("fab.labels.floorplan")}
                    </FabButton>
                  </Box>

                  <FabButton
                    color="inherit"
                    data-test-id="fab-inner-button-overview"
                    onClick={showMarginal}
                    startIcon={<Timelapse />}
                    theme={theme}
                  >
                    {t("fab.labels.overview")}
                  </FabButton>
                </FabMenuInner>
              </FabMenu>

              <Fab
                aria-label="Show context actions"
                data-test-id="toggle-fab-button"
                color="primary"
                onClick={toggleSpeedDial}
                style={fabStyle}
              >
                {!speedDialOpen && <Add />}
                {speedDialOpen && <Close />}
              </Fab>
            </div>
          </Zoom>
        </div>
      </ClickAwayListener>

      <FloorPlan isOpen={showFloorPlan} onDismiss={closeFloorPlan} />
    </Fragment>
  );

  if (isAtLeastTablet) {
    return (
      <Fragment>
        <MarginalContext.Provider value={{ open: false, sliding: false, toggle: () => {} }}>
          {children}
        </MarginalContext.Provider>
        {controls}
      </Fragment>
    );
  }

  return (
    <div className="marginalRoot" style={{ overflowX: "hidden" }}>
      <animated.div className="marginalContainer" style={animation}>
        <Overlay isVisible={margOpen} onClick={hideMarginal} />
        <MarginalContext.Provider
          value={{
            open: margOpen,
            sliding: true,
            toggle: toggleMarginal,
          }}
        >
          {children}
        </MarginalContext.Provider>
      </animated.div>
      {controls}
    </div>
  );
};
