import { Avatar as MuiAvatar, Theme, useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Avatar = styled(MuiAvatar)<{ theme: Theme }>`
  height: 50vw;
  margin-bottom: ${(props) => props.theme.spacing(4)};
  margin-top: ${(props) => props.theme.spacing(4)};
  max-height: 360px;
  max-width: 360px;
  width: 50vw;
`;

const Container = styled.div<{ theme: Theme }>`
  align-items: center;
  background: ${(props) => props.theme.palette.primary.light};
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;

  @media screen and (min-width: 768px) {
    padding: 1.5rem 0;
  }
`;

type Props = { illustration: string; name: string };

export const FullWidthAvatar: React.FC<Props> = ({ illustration, name }) => {
  const theme = useTheme();

  return (
    <Container theme={theme}>
      <Avatar data-test-id="avatar" theme={theme} src={illustration} alt={name} />
    </Container>
  );
};
