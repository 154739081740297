import { configState } from "./config.state";
import { createConfig } from "./create-config";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import React, { Fragment, useEffect } from "react";

export const ConfigWrapper: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation(["common"]);
  const language = i18n.language;
  const setConfigState = useSetRecoilState(configState);

  useEffect(() => {
    const config = createConfig(t);
    setConfigState(config);
  }, [language, setConfigState, t]);

  return <Fragment>{children}</Fragment>;
};
