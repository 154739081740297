import { ECharacterGender } from "../game-data";
import { GameHint } from "../shared";
import { Trans, useTranslation } from "react-i18next";
import React from "react";

type Props = { gender: ECharacterGender; isPerpetrator: boolean };

export const RoleHint: React.FC<Props> = ({ gender, isPerpetrator }) => {
  const { t } = useTranslation(["yourRole", "common"]);

  const getContent = () => {
    const maleLabel = t("common:perpetratorMale", "der Täter");
    const femaleLabel = t("common:perpetratorFemale", "die Täterin");
    const perpetrator = gender === ECharacterGender.FEMALE ? femaleLabel : maleLabel;

    if (isPerpetrator) {
      return (
        <Trans ns="yourRole" i18nKey="roleHint.perpetrator">
          Diese Informationen sind nur für dich. Du bist {{ perpetrator }}. Du darfst lügen. Versuche,
          dich so normal wie möglich zu verhalten und nah an der Wahrheit zu bleiben, um keinen Verdacht
          zu erregen.
        </Trans>
      );
    }

    return (
      <Trans ns="yourRole" i18nKey="roleHint.notPerpetrator">
        Diese Informationen sind nur für dich. Du bist nicht {{ perpetrator }}. Du darfst nicht lügen.
      </Trans>
    );
  };

  return <GameHint>{getContent()}</GameHint>;
};
