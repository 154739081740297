import { useEffect, useState } from "react";

const state = {
  whenOnline: "online",
  whenOffline: "offline",
};

export const useIsOnline = ({ whenOnline, whenOffline } = state) => {
  const [value, setValue] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setValue(window.navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return function cleanUp() {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  const isOnline = value === true;
  const isOffline = value === false;
  const status = isOnline ? whenOnline : whenOffline;

  return { status, isOnline, isOffline };
};
