import { EIncident } from "../game-data";
import { IncidentContent } from "./incident-content";
import { IncidentHint } from "./incident-hint";
import { PagePadding, useHasRenderedClass, WidthWrapper } from "../shared";
import React from "react";

type Props = { incident: EIncident };

export const Incident: React.FC<Props> = ({ incident }) => {
  useHasRenderedClass();

  return (
    <PagePadding>
      <WidthWrapper>
        <IncidentContent incident={incident} />
        <IncidentHint incident={incident} />
      </WidthWrapper>
    </PagePadding>
  );
};
