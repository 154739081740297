import { EIncident, EMatchStatus, matchStatusSelector } from "../game-data";
import { Incident } from "./incident";
import { Redirect } from "../navigation";
import { useHasRenderedClass } from "../shared";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React from "react";

export const IncidentB: React.FC = () => {
  useHasRenderedClass();
  const { t } = useTranslation(["rounds", "routes"]);
  const matchStatus = useRecoilValue(matchStatusSelector);

  if (!matchStatus || matchStatus < EMatchStatus.THIRD_ROUND_OPENED) {
    return <Redirect to={t("routes:secondRound.path", "/spiel/runde-2")} />;
  }

  return <Incident incident={EIncident.B} />;
};
