import { getLngFromDomain } from "./shared";
import { initReactI18next } from "react-i18next";
import accessDe from "./translations/de/access.json";
import accessEnGb from "./translations/en-GB/access.json";
import accessEnUs from "./translations/en-US/access.json";
import accessFr from "./translations/fr/access.json";
import accessJa from "./translations/ja/access.json";
import Backend from "i18next-http-backend";
import commonDe from "./translations/de/common.json";
import commonEnGb from "./translations/en-GB/common.json";
import commonEnUs from "./translations/en-US/common.json";
import commonFr from "./translations/fr/common.json";
import commonJa from "./translations/ja/common.json";
import i18n from "i18next";
import incidentsDe from "./translations/de/incidents.json";
import incidentsEnGb from "./translations/en-GB/incidents.json";
import incidentsEnUs from "./translations/en-US/incidents.json";
import incidentsFr from "./translations/fr/incidents.json";
import incidentsJa from "./translations/ja/incidents.json";
import legalDe from "./translations/de/legal.json";
import legalEnGb from "./translations/en-GB/legal.json";
import legalEnUs from "./translations/en-US/legal.json";
import legalFr from "./translations/fr/legal.json";
import legalJa from "./translations/ja/legal.json";
import matchAdminDe from "./translations/de/matchAdmin.json";
import matchAdminEnGb from "./translations/en-GB/matchAdmin.json";
import matchAdminEnUs from "./translations/en-US/matchAdmin.json";
import matchAdminFr from "./translations/fr/matchAdmin.json";
import matchAdminJa from "./translations/ja/matchAdmin.json";
import roundsDe from "./translations/de/rounds.json";
import roundsEnGb from "./translations/en-GB/rounds.json";
import roundsEnUs from "./translations/en-US/rounds.json";
import roundsFr from "./translations/fr/rounds.json";
import roundsJa from "./translations/ja/rounds.json";
import routesDe from "./translations/de/routes.json";
import routesEnGb from "./translations/en-GB/routes.json";
import routesEnUs from "./translations/en-US/routes.json";
import routesFr from "./translations/fr/routes.json";
import routesJa from "./translations/ja/routes.json";
import rulesDe from "./translations/de/rules.json";
import rulesEnGb from "./translations/en-GB/rules.json";
import rulesEnUs from "./translations/en-US/rules.json";
import rulesFr from "./translations/fr/rules.json";
import rulesJa from "./translations/ja/rules.json";
import yourRoleDe from "./translations/de/yourRole.json";
import yourRoleEnGb from "./translations/en-GB/yourRole.json";
import yourRoleEnUs from "./translations/en-US/yourRole.json";
import yourRoleFr from "./translations/fr/yourRole.json";
import yourRoleJa from "./translations/ja/yourRole.json";

const gb = {
  access: accessEnGb,
  common: commonEnGb,
  incidents: incidentsEnGb,
  legal: legalEnGb,
  matchAdmin: matchAdminEnGb,
  rounds: roundsEnGb,
  routes: routesEnGb,
  rules: rulesEnGb,
  yourRole: yourRoleEnGb,
};

const us = {
  access: accessEnUs,
  common: commonEnUs,
  incidents: incidentsEnUs,
  legal: legalEnUs,
  matchAdmin: matchAdminEnUs,
  rounds: roundsEnUs,
  routes: routesEnUs,
  rules: rulesEnUs,
  yourRole: yourRoleEnUs,
};

const french = {
  access: accessFr,
  common: commonFr,
  incidents: incidentsFr,
  legal: legalFr,
  matchAdmin: matchAdminFr,
  rounds: roundsFr,
  routes: routesFr,
  rules: rulesFr,
  yourRole: yourRoleFr,
};

const japanese = {
  access: accessJa,
  common: commonJa,
  incidents: incidentsJa,
  legal: legalJa,
  matchAdmin: matchAdminJa,
  rounds: roundsJa,
  routes: routesJa,
  rules: rulesJa,
  yourRole: yourRoleJa,
};

const german = {
  access: accessDe,
  common: commonDe,
  incidents: incidentsDe,
  legal: legalDe,
  matchAdmin: matchAdminDe,
  rounds: roundsDe,
  routes: routesDe,
  rules: rulesDe,
  yourRole: yourRoleDe,
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV !== "production" ? true : false,
    lng: getLngFromDomain(),
    resources: {
      fr: french,
      "fr-FR": french,
      gb: gb,
      en: gb,
      "en-GB": gb,
      us: us,
      "en-US": us,
      "de-DE": german,
      de: german,
      ja: japanese,
      jp: japanese,
      "ja-JP": japanese,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "ruby", "rt"],
    },
  });

export default i18n;
