import {
  accusationRoute,
  firstRoundRoute,
  prologueRoute,
  secondRoundRoute,
  solutionRoute,
  thirdRoundRoute,
} from "../rounds";
import { AppBar, Box, Container, IconButton, Toolbar, useTheme } from "@material-ui/core";
import { backgroundRoute, circleOfSuspectsRoute, yourRoleRoute } from "../your-role";
import { ConditionalNavLink } from "./conditional-nav-link";
import { createMatchRoute, viewMatchRoute } from "../match-admin";
import { drawerOpen } from "./navigation.state";
import { ElevationScroll } from "./elevation-scroll";
import { gameRulesRoute, structureOfTheGameRoute, welcomeRoute } from "../rules";
import { HideOnScroll } from "./hide-on-scroll";
import { incidentARoute, incidentBRoute } from "../incidents";
import { Logo } from "./logo";
import { Menu } from "@material-ui/icons";
import { ReactComponent as FullLogo } from "../assets/logo-horizontal-full.svg";
import { ReactComponent as SignOutIcon } from "../assets/icon-sign-out.svg";
import { SvgIcon } from "@material-ui/core";
import { useLogout } from "../authentication";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";

const List = styled.ul`
  align-items: center;
  display: flex;
  list-style-type: none;
  margin: 0;
`;

export const Header: React.FC = () => {
  const { t } = useTranslation(["common", "routes"]);
  const theme = useTheme();
  const logOut = useLogout();
  const setDrawerStatus = useSetRecoilState(drawerOpen);
  const incidentRoutes = [incidentARoute(t), incidentBRoute(t)];
  const gameRoutes = [
    welcomeRoute(t),
    gameRulesRoute(t),
    structureOfTheGameRoute(t),
    createMatchRoute(t),
    viewMatchRoute(t),
  ];
  const roleRoutes = [yourRoleRoute(t), circleOfSuspectsRoute(t), backgroundRoute(t)];
  const roundsRoutes = [
    prologueRoute(t),
    firstRoundRoute(t),
    secondRoundRoute(t),
    thirdRoundRoute(t),
    accusationRoute(t),
    solutionRoute(t),
  ];

  const openDrawer = () => setDrawerStatus(true);

  return (
    <ElevationScroll>
      <HideOnScroll>
        <AppBar position="sticky" style={{ transition: theme.transitions.create("transform") }}>
          <Toolbar style={{ color: "white" }}>
            <Container
              maxWidth="lg"
              style={{ alignItems: "center", justifyContent: "space-between" }}
              sx={{ display: { xs: "none", lg: "flex" } }}
            >
              <FullLogo fill="white" width="260px" style={{ marginBottom: "2px" }} />
              <List aria-label="main navigation" role="navigation" slot="end">
                <ConditionalNavLink
                  id="main-nav-game"
                  label={t("menu.categories.game", "Das Spiel")}
                  routes={gameRoutes}
                />

                <ConditionalNavLink
                  id="main-nav-your-role"
                  label={t("menu.categories.role", "Über deine Rolle")}
                  routes={roleRoutes}
                />

                <ConditionalNavLink
                  id="main-nav-clue-rounds"
                  label={t("menu.categories.rounds", "Hinweisrunden")}
                  routes={roundsRoutes}
                />

                <ConditionalNavLink
                  id="main-nav-incidents"
                  label={t("menu.categories.incidents", "Ereignisse")}
                  routes={incidentRoutes}
                />

                <li>
                  <IconButton
                    aria-label={t("signOut.label", "Abmelden")}
                    color="inherit"
                    data-test-id={`header-sign-out-btn`}
                    edge="end"
                    onClick={logOut}
                    title={t("signOut.label", "Abmelden")}
                  >
                    <SvgIcon component={SignOutIcon} viewBox="0 0 512 512" />
                  </IconButton>
                </li>
              </List>
            </Container>

            <Box
              sx={{
                alignItems: "center",
                display: { lg: "none", xs: "flex" },
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <IconButton
                aria-label={t("mainMenuLabel", "Main menu")}
                color="inherit"
                data-test-id="mobile-menu-btn"
                edge="start"
                onClick={openDrawer}
              >
                <Menu />
              </IconButton>

              <Logo />

              <IconButton
                aria-label={t("signOut.label", "Abmelden")}
                color="inherit"
                data-test-id={`header-sign-out-btn`}
                edge="end"
                onClick={logOut}
                title={t("signOut.label", "Abmelden")}
              >
                <SvgIcon component={SignOutIcon} viewBox="0 0 512 512" />
              </IconButton>
            </Box>
          </Toolbar>
          <div id="cm-sticky-sub-nav" />
        </AppBar>
      </HideOnScroll>
    </ElevationScroll>
  );
};
