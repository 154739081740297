import "./map.css";
import { Theme, useTheme } from "@material-ui/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import React from "react";
import styled from "styled-components";

type ImageProps = { theme: Theme; url: string };

const Image = styled.div<ImageProps>`
  background-color: ${(props) => props.theme.palette.primary.main};
  background-image: ${(props) => `url(${props.url})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
`;

type Props = { url: string };

export const Map: React.FC<Props> = ({ url }) => {
  const theme = useTheme();

  return (
    <div style={{ background: theme.palette.primary.main, width: "100%", height: "100%" }}>
      <TransformWrapper initialScale={1} maxScale={4}>
        <TransformComponent>
          <Image theme={theme} url={url} />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};
