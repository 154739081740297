import { useMediaQuery, useScrollTrigger, useTheme } from "@material-ui/core";
import React from "react";

type Props = {
  children: React.ReactElement;
};

export const ElevationScroll: React.FC<Props> = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  if (isDesktop) {
    return React.cloneElement(children, {
      elevation: 4,
    });
  }

  return React.cloneElement(children, {
    elevation: trigger ? 5 : 0,
  });
};
