export enum EMatchResponseTopic {
  ACCUSATION_OPENED = "ACCUSATION_OPENED",
  ASSIGNABLE_CHARACTERS = "ASSIGNABLE_CHARACTERS",
  GAME_BOOKLET_DATA = "GAME_BOOKLET_DATA",
  GAME_DATA = "GAME_DATA",
  MATCH_DATA = "MATCH_DATA",
  MATCH_DELETED = "MATCH_DELETED",
  ROUND_OPENED = "ROUND_OPENED",
  SOLUTION_SHARED = "SOLUTION_SHARED",
}

export enum EIncident {
  A = "A",
  B = "B",
}

export enum ERound {
  FIRST = "FIRST",
  SECOND = "SECOND",
  THIRD = "THIRD",
}

export enum ECharacterGender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum EMatchStatus {
  NONE,
  CREATION_PENDING,
  CREATED,
  FIRST_ROUND_OPENED,
  SECOND_ROUND_OPENED,
  THIRD_ROUND_OPENED,
  ACCUSATION_OPENED,
  SOLUTION_SHARED,
  ENDED,
}

export interface IBaseCharacter {
  age: number;
  gender: ECharacterGender;
  name: string;
}

export interface ISuspect extends IBaseCharacter {
  description: string;
}

export interface IGameCharacter extends ISuspect {
  illustration: string;
  includeForMaxPlayers: number;
  readsPrologue: boolean;
}

export interface IVictim extends IBaseCharacter {
  description: string;
}

export interface IAssignedCharacter extends ISuspect {
  link?: string;
  name: string;
  playedByHost: boolean;
  playerName: string;
  readsPrologue: boolean;
}

export interface IMatch {
  assignedCharacters: IAssignedCharacter[];
  createdAt: Date;
  firstRoundOpenedAt?: Date;
  gameId: string;
  hostEmail: string;
  hostName: string;
  incidentARead: boolean;
  incidentBRead: boolean;
  language: string;
  numberOfPlayers: number;
  prologueRead: boolean;
  secondRoundOpenedAt?: Date;
  status: EMatchStatus;
  thirdRoundOpenedAt?: Date;
}

export interface IGameRoundMeta {
  start: string;
  end: string;
}

export interface IGame {
  background: string;
  characters: IGameCharacter[];
  description: string;
  floorplan: string;
  gameId: string;
  illustration: string;
  incidentA: string;
  incidentB: string;
  language: string;
  maxPlayers: number;
  minPlayers: number;
  prologue: string;
  rounds: Record<ERound, IGameRoundMeta>;
  solution: string;
  title: string;
}

export interface ISection {
  content: string;
  title: string;
}

export interface IRound extends ISection {}

export interface IGameBooklet {
  age: number;
  circleOfSuspects: ISuspect[];
  description: string;
  gameId: string;
  gender: ECharacterGender;
  illustration: string;
  isPerpetrator: boolean;
  name: string;
  readsPrologue: boolean;
  rounds: Partial<Record<ERound, IRound>>;
  task: string;
  victim: IVictim;
}
