import { Box, useTheme } from "@material-ui/core";
import { characterNameSelector, isHostSelector, matchData } from "../game-data";
import { IRoute } from "./route.interface";
import { matchRoutes, useLocation, useNavigate } from "react-router";
import { Tab, Tabs } from "./tabs";
import { usePortal } from "../shared";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useEffect } from "react";
import ReactDOM from "react-dom";

type Props = { routes?: IRoute[]; additionalRoutes?: string[] };

export const SubNav: React.FC<Props> = ({ additionalRoutes = [], routes = [] }) => {
  const { t } = useTranslation(["common"]);
  const { pathname } = useLocation();
  const characterName = useRecoilValue(characterNameSelector);
  const isHost = useRecoilValue(isHostSelector);
  const match = useRecoilValue(matchData);
  const navigate = useNavigate();
  const target = usePortal("cm-sticky-sub-nav");
  const theme = useTheme();

  useEffect(() => {
    window.dispatchEvent(new CustomEvent("resize"));
  }, []);

  const handleChange = (e: ChangeEvent<{}>, path: string) => navigate(path);
  const filter = (r: IRoute) =>
    !r.hideInSubNav && (!!!r.isVisible || r?.isVisible(isHost, match, characterName));
  const isDisabled = ({ canAccess }: IRoute) =>
    canAccess ? !canAccess(isHost, match, characterName) : false;

  const matchedRoute = matchRoutes(
    [
      ...routes.map((r) => ({ path: r.fullPath, caseSensitive: false, element: r.component })),
      ...additionalRoutes.map((path) => ({ caseSensitive: false, element: null, path })),
    ],
    pathname
  );

  return ReactDOM.createPortal(
    <Box style={{ background: "white", color: "black" }} sx={{ py: { sm: 0.5, lg: 0.5 } }}>
      <Tabs
        aria-label={t("subNavigationLabel", "Unternavigation")}
        onChange={handleChange}
        scrollButtons={false}
        TabIndicatorProps={{ children: <div /> }}
        textColor="primary"
        theme={theme}
        value={matchedRoute?.[0].pathname}
        variant="scrollable"
      >
        {routes.filter(filter).map((r, index) => (
          <Tab
            aria-label={r.name}
            data-test-id={`sub-nav-${r.id}`}
            disabled={isDisabled(r)}
            id={`nav-tab-${index}`}
            key={`sub-nav-tab-${r.fullPath}`}
            label={r.name}
            theme={theme}
            value={r.fullPath}
          />
        ))}
      </Tabs>
    </Box>,
    target
  );
};
