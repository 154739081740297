import { Theme } from "@material-ui/core";
import styled from "styled-components";

type Props = { color?: string; theme: Theme };

export const ExternalLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})<Props>`
  &&& {
    text-decoration-skip-ink: auto;
    text-decoration-skip: ink;
    text-decoration-thickness: 1;
    text-decoration-width: 1;
    text-underline-position: from-font;
    background: none;
    color: ${(props) => props.color ?? "inherit"}
    font-family: inherit;
    text-decoration: underline;
    transition: ${(props) => props.theme.transitions.create("color", { duration: "short" })};
    &:hover {
      color: ${(props) => props.color ?? props.theme.palette.primary.dark};
    }
  }
`;
