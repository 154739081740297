import { makeStyles } from "@material-ui/styles";
import { Theme, Tooltip as MuiTooltip, TooltipProps } from "@material-ui/core";
import React from "react";

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: "white",
    fontStyle: "normal",
  },
}));

export const Tooltip: React.FC<TooltipProps> = ({ children, placement, title }) => {
  const classes = useStylesBootstrap();

  return (
    <MuiTooltip arrow classes={classes} placement={placement} title={title}>
      {children}
    </MuiTooltip>
  );
};
