import {
  Actions,
  CharacterCardContainer,
  CharacterCardHeader,
  CharacterContent,
  TextButton,
} from "../shared";
import { Box } from "@material-ui/core";
import { ContentCopyOutlined, MailOutline, SendOutlined, WhatsApp } from "@material-ui/icons";
import { IAssignedCharacter, IGameCharacter } from "../game-data";
import { isMobile } from "react-device-detect";
import { SharingList } from "./sharing-list";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { yourRoleRoute } from "../your-role";
import copy from "copy-to-clipboard";
import React, { Fragment, useState } from "react";

export interface ICombinedCharacter
  extends Omit<IGameCharacter, "includeForMaxPlayers">,
    IAssignedCharacter {}

export interface ISharingAction {
  handler?: () => void;
  href?: string;
  icon: React.ReactElement;
  text: string;
}

type Props = { character: ICombinedCharacter };

export const MatchCharacterCard: React.FC<Props> = ({ character }) => {
  const { age, description, illustration, link, name, playedByHost, playerName } = character;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation(["matchAdmin", "common"]);
  const navigate = useNavigate();

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const sharingActions: ISharingAction[] = [];

  const openHostRole = () => navigate(yourRoleRoute(t).fullPath);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
    setMenuOpen(true);
    closeSnackbar();
  };

  const closeMenu = () => {
    setMenuAnchor(null);
    setMenuOpen(false);
  };

  const getSubHeadline = (): string => {
    if (playedByHost) {
      return t("characterCard.subtitle.yourRole", "Deine Rolle");
    }

    return playerName ?? "";
  };

  const subHeadline = getSubHeadline();
  const sub = t("characterCard.sharing.copy", "Einladung zu meinem Culinario Mortale Spiel für ") + name;

  const shareViaEmail = () => {
    closeMenu();
    window.location.href = `mailto:?subject=${encodeURIComponent(sub)}&body=${encodeURIComponent(
      link ?? ""
    )}`;
  };

  const shareViaSms = () => {
    const msg = `. Link: `;
    closeMenu();
    window.location.href = `sms:?&body=${encodeURIComponent(sub)}${encodeURIComponent(
      msg
    )}${encodeURIComponent(link ?? "")}`;
  };

  const shareViaWhatsApp = () => {
    const msg = `. Link: `;
    closeMenu();
    window.location.href = `whatsapp://send?text=${encodeURIComponent(sub)}${encodeURIComponent(
      msg
    )}${encodeURIComponent(link ?? "")}`;
  };

  const copyToClipboard = async () => {
    if (link) {
      copy(link, { format: "text/plain" });
      enqueueSnackbar(
        t("characterCard.sharing.clipboard.successNotice", "Link in die Zwischenablage kopiert"),
        { variant: "info" }
      );
      closeMenu();
    }
  };

  if (isMobile) {
    sharingActions.push({
      handler: shareViaWhatsApp,
      icon: <WhatsApp />,
      text: t("characterCard.sharing.whatsApp.label", "WhatsApp"),
    });

    sharingActions.push({
      handler: shareViaSms,
      icon: <SendOutlined />,
      text: t("characterCard.sharing.sms.label", "SMS"),
    });
  }

  sharingActions.push({
    handler: shareViaEmail,
    icon: <MailOutline />,
    text: t("characterCard.sharing.email.label", "E-Mail"),
  });

  sharingActions.push({
    handler: copyToClipboard,
    icon: <ContentCopyOutlined />,
    text: t("characterCard.sharing.clipboard.label", "Link kopieren"),
  });

  return (
    <CharacterCardContainer>
      <CharacterCardHeader age={age} illustration={illustration} name={name} subHeadline={subHeadline} />
      <CharacterContent>{description}</CharacterContent>
      <Box sx={{ mt: 2 }}>
        <Actions>
          {playedByHost && (
            <TextButton
              data-test-id="goto-host-role-btn"
              onClick={openHostRole}
              size="small"
              type="button"
            >
              {t("characterCard.openHostRole", "Deine Rolle öffnen")}
            </TextButton>
          )}

          {!playedByHost && (
            <Fragment>
              <TextButton
                data-test-id={`share-role-btn-${name}`}
                onClick={openMenu}
                size="small"
                type="button"
              >
                {t("characterCard.sharing.label", "Zugangslink teilen")}
              </TextButton>

              <SharingList
                anchor={menuAnchor}
                name={name}
                onClose={closeMenu}
                open={menuOpen}
                sharingActions={sharingActions}
              />
            </Fragment>
          )}
        </Actions>
      </Box>
    </CharacterCardContainer>
  );
};
