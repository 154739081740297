import { Box } from "@material-ui/core";
import { Title } from "./title";
import React, { CSSProperties, Fragment } from "react";

type Props = {
  narrow?: boolean;
  style?: CSSProperties;
  subtitle?: string;
};

export const PageTitle: React.FC<Props> = ({ children, narrow = false, style, subtitle }) => {
  return (
    <Fragment>
      <Box
        sx={{
          mt: 4,
          mb: 4,
          maxWidth: narrow ? "var(--max-round-text-width)" : "var(--max-text-width)",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Title subtitle={subtitle}>{children}</Title>
      </Box>
    </Fragment>
  );
};
